import React from "react";
// import './PublicNavBar.css'
// import Button from 'react-bootstrap/Button';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Container from 'react-bootstrap/Container';

// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");
// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');
}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

export const sessionTest = () => {
    console.log(ReactSession.get("username"));
    console.log(userRolesForPageItems);

}

function logout() {
    localStorage.clear();
    window.location.href = '/';
}


const LogoutComponent = () => {
    //function logout() {
    //     localStorage.clear();
    //     window.location.href = '/';
    // }
    return ( 
        <div> 
            <button onClick={()=> logout()}>LOGOUT</button>
        </div>
    )

}

// function logout() {
//     localStorage.clear();
//     window.location.href = '/';
// }

export default LogoutComponent
