import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import About from "./About";
import Login from "./Login";
import RegisterWhoAreYou from "./RegisterWhoAreYou";
import StudentSignUpA from "./StudentSignUpA";
import StudentSignUpAS2 from "./StudentSignUpAS2";
import StudentSignUpAS3 from "./StudentSignUpAS3";
import StudentSignUpAS4 from "./StudentSignUpAS4";
import StudentSignUpAS5 from "./StudentSignUpAS5";
import ConfirmEmail from "./ConfirmEmail";

import TutorSignUpA from "./TutorSignUpA";
import TutorSignUpAS2 from "./TutorSignUpAS2";
import TutorSignUpAS3 from "./TutorSignUpAS3";
import TutorSignUpAS4 from "./TutorSignUpAS4";
import TutorSignUpAS5 from "./TutorSignUpAS5";
import TutorSignUpAS6 from "./TutorSignUpAS6";
import VerifyEmail from "./VerifyEmail";
import AccountSettings from "./AccountSettings";


import StudentSignUpB from "./StudentSignUpB";
import DndEx from "./DndEx";
import LandingPage from "./LandingPage";
import TutorOnboardingMain from "./TutorOnboarding";

import PageNotFound from "./PageNotFound";


// import Home from "./Home";

// import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import { createRoot } from 'react-dom/client';



// const rootElement = document.getElementById("root");
// render(
// <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<App />} />
//       <Route path="About" element={<About />} />

//     </Routes>
//   </BrowserRouter>,
// rootElement
// );reportWebVitals();

// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
// <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<App />} />
//       <Route path="About" element={<About />} />
//       <Route path="Login" element={<Login />} />
//       <Route path="RegisterWhoAreYou" element={<RegisterWhoAreYou />} />
//       <Route path="StudentSignUpA" element={<StudentSignUpA />} />
//       <Route path="StudentSignUpB" element={<StudentSignUpB />} />
//       <Route path="DndEx" element={<DndEx />} />
//       <Route path="LandingPage" element={<LandingPage />} />
//       <Route path="TutorOnboarding" element={<TutorOnboardingMain />} />

//     </Routes>
//   </BrowserRouter>
// )

function indexroute() {
  return(
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="About" element={<About />} />
      <Route path="Login" element={<Login />} />
      <Route path="RegisterWhoAreYou" element={<RegisterWhoAreYou />} />
      <Route path="StudentSignUpA" element={<StudentSignUpA />} />
      <Route path="StudentSignUpAS2" element={<StudentSignUpAS2 />} />
      <Route path="StudentSignUpAS3" element={<StudentSignUpAS3 />} />
      <Route path="StudentSignUpAS4" element={<StudentSignUpAS4 />} />
      <Route path="StudentSignUpAS5" element={<StudentSignUpAS5 />} />
      <Route path="ConfirmEmail" element={<ConfirmEmail />} />
      <Route path="TutorSignUpA" element={<TutorSignUpA />} />
      <Route path="TutorSignUpAS2" element={<TutorSignUpAS2 />} />
      <Route path="TutorSignUpAS3" element={<TutorSignUpAS3 />} />
      <Route path="TutorSignUpAS4" element={<TutorSignUpAS4 />} />
      <Route path="TutorSignUpAS5" element={<TutorSignUpAS5 />} />
      <Route path="TutorSignUpAS6" element={<TutorSignUpAS6 />} />
      <Route path="VerifyEmail" element={<VerifyEmail />} />
      <Route path="AccountSettings" element={<AccountSettings />} />

      <Route path="StudentSignUpB" element={<StudentSignUpB />} />
      <Route path="DndEx" element={<DndEx />} />
      <Route path="LandingPage" element={<LandingPage />} />
      <Route path="TutorOnboarding" element={<TutorOnboardingMain />} />

      <Route path="*" element={<PageNotFound />} />


    </Routes>
  </BrowserRouter>
  )
}

export default indexroute;
