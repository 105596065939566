import React from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import Indexroute from './indexroute';
  

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
// ReactDOM.render(
<React.StrictMode>
    <Indexroute />
</React.StrictMode>
// document.getElementById('root')
);