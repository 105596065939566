import React from "react";
import { useState } from "react";

import Axios from 'axios'
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import './components/Login.css';
import LoggedInTutor from './components/LandingPage_TutorComponent.js';
// import Footer from './components/Footer.jsx';
import PortalNavBar from './components/PortalNavBar';
import SessionComponent from './SessionComponent';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>
ReactSession.setStoreType("localStorage");
// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');

}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

export const sessionTest = () => {
    console.log(ReactSession.get("username"));
    console.log(ReactSession.get("roles"));
    console.log(userRolesForPageItems);

}

function Login() {

    ReactSession.setStoreType("localStorage");
// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');
}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

    // const navigate = useNavigate();

   return(

        <div className="d-flex" Style="background:#CDD6E8">
               <PortalNavBar/>
               <div className="flex-row flex-wrap">
            {(() => {
                if (userRolesForPageItems.includes('Parent') || userRolesForPageItems.includes('Student') ) {
                return (
                    // <div>someCase</div>
                    // <LoggedInAdmin/>
                    <LoggedInParent/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('Tutor')) {
                return (
                    // <div>someCase</div>
                    // <LoggedInAdmin/>
                    <LoggedInTutor/>
                )
                }  
            })()}
             {(() => {
                if (userRolesForPageItems.includes('Manager')) {
                return (
                    // <div>someCase</div>
                    <LoggedInManager/>
                    // <LoggedInTutor/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('SuperAdmin')) {
                return (
                    // <div>someCase</div>
                    <LoggedInAdmin/>
                    // <LoggedInTutor/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('SuperDuperAdmin')) {
                return (
                    // <div>otherCase</div>
                    <LoggedInSuperDuperAdmin/>
                )
                } 
                // else {
                // return (
                //     <div>catch all
                //         <Button variant="primary" onClick={sessionTest}>SessionTest</Button>
                //     </div>
                                        

                // )
                // }
            })()}
            </div>  
        </div>
        
   )
}
function LoggedInParent() {
    return ( <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for a Parent                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

function LoggedInManager() {
    return ( <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for a Manager                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

function LoggedInAdmin() {
    return ( <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for an Admin                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

function LoggedInSuperDuperAdmin() {
    return (
        <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for an Super Duper Admin                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

export default Login; 
