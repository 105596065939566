import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';


import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

// import StudentSignUpB from "./StudentSignUpB";
// import StudentSignUpAS4 from "./StudentSignUpAS4";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter,useNavigate, } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            FirstName: "" ,
            LastName:"",
            PrimaryPhoneNumber:"",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"black",
            textMatchColorFirstNameStudent:"black",
            textMatchColorLastNameStudent:"black",
            textMatchColorPP:"black",
            emailValid:"",
            tcChecked:"",
            ppChecked:"",
            redirectTime:"",
            
            TutorEnglishCheckbox:"",
            TutorSpanishCheckbox:"",
            TutorHindiCheckbox:"",
            TutorFrenchCheckbox:"",
            TutorOtherCheckbox:"",
            CurrentlySelectedCourse:"",
            CurrentlySelectedCourseID:0,
            CoursesListA:[{id:1, title:'Ford'}, {id:2, title:'BMW'}, {id:3, title:'Audi'},{id:4, title:'Toyota'}],
            CoursesListB:[],
            redirectBack:"",
            AddButtonStatus:true,
            RemoveButtonStatus:true,
            boarderCssLanguage:"",
            textMatchColorLanguage:"",

            TutorElementarySchoolCheckbox:"",
            TutorMiddleSchoolCheckbox:"",
            TutorHighSchoolCheckbox:"",
            TutorOtherTextEntry:"",

            LanguageCheckStatus:"",
            GradeCheckStatus:"",

            StudentLanguage:"",
        };
    };

    // async componentDidMount() {
    //     // window.addEventListener('load', this.getCourseList);
    //     console.log("Component Mount occured");
    //     if(ReactSession.get("email") == "" || ReactSession.get("email")==undefined)
    //     {
    //         console.log("Email Check Failed");
    //         this.setState({ redirectToBeginning: "true" })
    //     }
    //     this.getCourseList();

    //     if (this.state.TutorEnglishCheckbox === undefined || this.state.TutorEnglishCheckbox === null || this.state.TutorEnglishCheckbox === "")
    //     {
    //         console.log("Setting TutorEnglishCheckbox");
    //         this.setState({TutorEnglishCheckbox: ReactSession.get("TutorEnglishCheckbox") })        
    //     }
    //     if (this.state.TutorSpanishCheckbox === undefined || this.state.TutorSpanishCheckbox === null || this.state.TutorSpanishCheckbox === "")
    //     {
    //         console.log("Setting TutorSpanishCheckbox");
    //         this.setState({TutorSpanishCheckbox: ReactSession.get("TutorSpanishCheckbox") })        
    //     }
    //     if (this.state.TutorHindiCheckbox === undefined || this.state.TutorHindiCheckbox === null || this.state.TutorHindiCheckbox === "")
    //     {
    //         console.log("Setting TutorHindiCheckbox");
    //         this.setState({TutorHindiCheckbox: ReactSession.get("TutorHindiCheckbox") })        
    //     }
    //     if (this.state.TutorOtherCheckbox === undefined || this.state.TutorOtherCheckbox === null || this.state.TutorOtherCheckbox === "")
    //     {
    //         console.log("Setting TutorHindiCheckbox");
    //         this.setState({TutorOtherCheckbox: ReactSession.get("TutorOtherCheckbox") })        
    //     }

    //     if (this.state.TutorElementarySchoolCheckbox === undefined || this.state.TutorElementarySchoolCheckbox === null || this.state.TutorElementarySchoolCheckbox === "")
    //     {
    //         console.log("Setting TutorElementarySchoolCheckbox");
    //         this.setState({TutorElementarySchoolCheckbox: ReactSession.get("TutorElementarySchoolCheckbox") })        
    //     }
    //     if (this.state.TutorMiddleSchoolCheckbox === undefined || this.state.TutorMiddleSchoolCheckbox === null || this.state.TutorMiddleSchoolCheckbox === "")
    //     {
    //         console.log("Setting TutorMiddleSchoolCheckbox");
    //         this.setState({TutorMiddleSchoolCheckbox: ReactSession.get("TutorMiddleSchoolCheckbox") })        
    //     }
    //     if (this.state.TutorHighSchoolCheckbox === undefined || this.state.TutorHighSchoolCheckbox === null || this.state.TutorHighSchoolCheckbox === "")
    //     {
    //         console.log("Setting TutorHighSchoolCheckbox");
    //         this.setState({TutorHighSchoolCheckbox: ReactSession.get("TutorHighSchoolCheckbox") })        
    //     }
    // }

    async componentDidMount() {
        // window.addEventListener('load', this.getCourseList);
        this.getCourseList();

        if(this.state.EnglishCheckbox==false && this.state.SpanishCheckbox==false  && this.state.HindiCheckbox==false && this.state.FrenchCheckbox==false && this.state.OtherCheckbox==false)
        {
            // console.log("no languages make it red")
            // this.changeTextColorRedLanguage();

            if(
                (ReactSession.get("EnglishCheckbox") == undefined || ReactSession.get("EnglishCheckbox") == null || ReactSession.get("EnglishCheckbox") =="" || ReactSession.get("EnglishCheckbox") ==" " )&&
                (ReactSession.get("SpanishCheckbox") == undefined || ReactSession.get("SpanishCheckbox") == null || ReactSession.get("SpanishCheckbox") =="" || ReactSession.get("SpanishCheckbox") ==" " )&&
                (ReactSession.get("HindiCheckbox") == undefined || ReactSession.get("HindiCheckbox") == null || ReactSession.get("HindiCheckbox") =="" || ReactSession.get("HindiCheckbox") ==" " )&&
                (ReactSession.get("FrenchCheckbox") == undefined || ReactSession.get("FrenchCheckbox") == null || ReactSession.get("FrenchCheckbox") =="" || ReactSession.get("FrenchCheckbox") ==" " )&&
                (ReactSession.get("OtherCheckbox") == undefined || ReactSession.get("OtherCheckbox") == null || ReactSession.get("OtherCheckbox") =="" || ReactSession.get("OtherCheckbox") ==" ")
            ){
                // console.log("student age check triggered - red");
                // this.changeTextColorRedLanguage();
            }
            else{
                // console.log("student age check triggered - green1");
                this.setState({ EnglishCheckbox: ReactSession.get("EnglishCheckbox") })
                this.setState({ SpanishCheckbox: ReactSession.get("SpanishCheckbox") })
                this.setState({ HindiCheckbox: ReactSession.get("HindiCheckbox") })
                this.setState({ FrenchCheckbox: ReactSession.get("FrenchCheckbox") })
                this.setState({ OtherCheckbox: ReactSession.get("OtherCheckbox") })

                var langarraytemp2 = ["English","Spanish","Hindi","French","Other"]
                var langarraytemp = [ReactSession.get("EnglishCheckbox"), ReactSession.get("SpanishCheckbox"), ReactSession.get("HindiCheckbox") , ReactSession.get("FrenchCheckbox"), ReactSession.get("OtherCheckbox")];

                const asyncLangFunc = async (passedValue, passedCheck) => {
                    //console.log("Async checkLanguageFunc Intiated with value:"+passedValue);
                        if(passedCheck === true){
                            //English is selected to be part of langauges
                            //If languages include selected language do nothing
                            if(this.state.StudentLanguage.includes(passedValue)){
            
                            }
                            //If languages doen't include selected language add selected language
                            else{
                                //If languages already have other languages add to the end with comma
                                if(this.state.StudentLanguage.length > 0){
                                var newStateTemp = this.state.StudentLanguage+","+passedValue ;
                                this.setState({ StudentLanguage: newStateTemp })
                                }
                                //If no other languages set as only language
                                else{
                                this.setState({ StudentLanguage: passedValue })
                                }
                            }
                            return("Passed");
            
                        }
                        else{
                            //
                            //console.log("resetting language state to nothing")
                            if(this.state.StudentLanguage.includes(","+passedValue)){
                                var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
                                this.setState({ StudentLanguage: middlemanStudentLang })
                            }
                            else{
                                var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
                                this.setState({ StudentLanguage: middlemanStudentLang })
                            }
                            return("Failed");
                        }
                    // }
                }
                

                const fetchData = async () => {
                    for (let i = 0; i < langarraytemp2.length; i++) {
                        // console.log("passing:"+ langarraytemp2[i] + " and " + langarraytemp[i])
                        if(langarraytemp[i] == true){
                            let channel = await asyncLangFunc(langarraytemp2[i],langarraytemp[i]);
                            // console.log("channel required: " + channel);
                        }
                    }
                }
                fetchData();
            }

        }

        // if (this.state.FirstNameStudent == undefined || this.state.FirstNameStudent == null || this.state.FirstNameStudent == ""){
        //     if(ReactSession.get("FirstNameStudent") !== undefined && ReactSession.get("FirstNameStudent") !== null && ReactSession.get("FirstNameStudent") !==""){
        //         this.setState({ FirstNameStudent: ReactSession.get("FirstNameStudent") })
        //     }
        // }
        // if (this.state.LastNameStudent == undefined || this.state.LastNameStudent == null || this.state.LastNameStudent == ""){
        //     if(ReactSession.get("LastNameStudent") !== undefined && ReactSession.get("LastNameStudent") !== null && ReactSession.get("LastNameStudent") !==""){
        //         this.setState({ LastNameStudent: ReactSession.get("LastNameStudent") })
        //     }
        // }
    }

    getCourseList = async () => {
        let res = await  Axios.get('http://localhost:3001/CoursesList') ;
        console.log(res.data)
        this.setState({ CoursesListA: res.data });
    }
    // getCourseList () {
    //     let res = Axios.get('http://localhost:3001/CoursesList') ;
    //     console.log(res.data[0])
    //     // this.setState({ CoursesListA: res.data[0] });
    // }


    checkValidation=(event)=>{

        console.log("CheckValidation Invoked");

        const confirmPasswordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        const passwordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        console.log('3rd regex '+ confirmPasswordRegex +' & 4th regex '+ passwordRegex) ;
        this.setState({ textMatchColor: "black" })

        // if(passwordRegex.match(confirmPasswordRegex)){
            if( this.state.password === this.state.confirmPassword){
            console.log("Match");
            
            this.setState({ labelMatch: "true" })
            // this.setState({ textMatchColor: "black" })

        }
        else{
            console.log("Nope");

            this.setState({ labelMatch: "false" })
            // this.setState({ textMatchColor: "black" })

        }

    };
    checkEmailValidation=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })
        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
          }
          if (!isValidEmail(this.state.email)) {
            this.setState({ emailValid: "false" })
          } else {
            this.setState({ emailValid: "true" })
          }

          console.log("CheckEmailValidation Invoked "+this.state.emailValid);

    }

    checkFirstName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })

    }
    checkLastName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })
    }

    checkLanguage(languageCheck){
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        // this.setState({ textMatchColorEmail: "black" })
        console.log("Langauge Check is"+languageCheck)
    }

    // backButtonClickCheck=(event)=>{

    // }

    getListOfCoursesA=(event)=>{
        //const cars = ['Ford', 'BMW', 'Audi']; 
        const cars = this.state.CoursesListA;
        return cars
    }

    getListOfCoursesB=(event)=>{
        //const cars = ['Ford', 'BMW', 'Audi']; 
        const cars = this.state.CoursesListB;
        return cars
    }

    // checkLanguageFunc(passedValue, passedCheck){

    //     console.log("checkLanguageFunc Intiated with value:"+passedValue);
    //     console.log("TutorEnglishCheckbox State: " + this.state.TutorEnglishCheckbox)
    //     console.log("TutorSpanishCheckbox State: " + this.state.TutorSpanishCheckbox)
    //     console.log("TutorHindiCheckbox State: " + this.state.TutorHindiCheckbox)

    //     // if(passedValue === "English"){
    //         if(passedCheck === true){
    //             //English is selected to be part of langauges
    //             //If languages include selected language do nothing
    //             if(this.state.StudentLanguage.includes(passedValue)){

    //             }
    //             //If languages doen't include selected language add selected language
    //             else{
    //                 //If languages already have other languages add to the end with comma
    //                 if(this.state.StudentLanguage.length > 0){
    //                 var newStateTemp = this.state.StudentLanguage+","+passedValue ;
    //                 this.setState({ StudentLanguage: newStateTemp })
    //                 }
    //                 //If no other languages set as only language
    //                 else{
    //                 this.setState({ StudentLanguage: passedValue })
    //                 }
    //             }

    //         }
    //         else{
    //             //
    //             console.log("resetting language state to nothing")
    //             if(this.state.StudentLanguage.includes(","+passedValue)){
    //                 var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
    //                 this.setState({ StudentLanguage: middlemanStudentLang })
    //             }
    //             else{
    //                 var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
    //                 this.setState({ StudentLanguage: middlemanStudentLang })
    //             }
                
    //         }
    //     // }
    // }
    checkLanguageFunc(passedValue, passedCheck){

        // if(passedValue === "English"){
            if(passedCheck === true){
                //English is selected to be part of langauges
                //If languages include selected language do nothing
                if(this.state.StudentLanguage.includes(passedValue)){

                }
                //If languages doen't include selected language add selected language
                else{
                    //If languages already have other languages add to the end with comma
                    if(this.state.StudentLanguage.length > 0){
                    var newStateTemp = this.state.StudentLanguage+","+passedValue ;
                    this.setState({ StudentLanguage: newStateTemp })
                    }
                    //If no other languages set as only language
                    else{
                    this.setState({ StudentLanguage: passedValue })
                    }
                }

            }
            else{
                //
                //console.log("resetting language state to nothing")
                if(this.state.StudentLanguage.includes(","+passedValue)){
                    var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
                    this.setState({ StudentLanguage: middlemanStudentLang })
                }
                else{
                    var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
                    this.setState({ StudentLanguage: middlemanStudentLang })
                }
                
            }
        // }
    }

    addButtonClickCheck=(event)=>{
  
        //Log the currently selected item for addition
        console.log("Add button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse);    

        // {this.state.CoursesListA.map(item => {
        //     return console.log("Add button clicked. New CouresListA: " + item.title);
        //   }) } 
          
        let isKeyPresent = this.state.CoursesListA.some(el => {
            // console.log(el.title);
            if(el.title.includes(this.state.CurrentlySelectedCourse))
              return true;
          })

        console.log("Add button clicked. Is item in CouresListA?"+isKeyPresent);    

        if(isKeyPresent){
            //Can add

            //Filter out the currently Selected item and put it in new array
            const newList = this.state.CoursesListA.filter((item) => item.title !== this.state.CurrentlySelectedCourse);
            //Console Log new filter list
            const newList2 = newList.sort((a, b) => a.id > b.id ? 1 : -1)
            // const newList2 = newList.sort((a, b) => a.id > b.id )

            {newList2.map(item => {
                return console.log("Add button clicked. Filtered Sorted list is: " + item.title);
            }) } 
            //Update CourseListA
            this.setState({CoursesListA:newList2})
            //Console log course list A
            {this.state.CoursesListA.map(item => {
                return console.log("Add button clicked. New CouresListA: " + item.title);
            }) } 

            //Update Course List B
            var newArray = this.state.CoursesListB.slice();    
            newArray.push({id: this.state.CurrentlySelectedCourseID, title: this.state.CurrentlySelectedCourse});   
            // var newArray2 = newArray.sort((a, b) => a.id > b.id ? 1 : -1)
            // {newArray2.map(item => {
            //     return console.log("Add button clicked. Sorted NewArray2 " + item.title);
            // }) }  
            this.setState({CoursesListB:newArray})
            //Console Log Course List B
            {this.state.CoursesListB.map(item => {
                return console.log("Add button clicked. New CouresListB: " + item.title);
            }) }  
        }
        else{
            //Cannot Add
        }


        


    }
    removeButtonClickCheck=(event)=>{
  
        //Log the currently selected item for addition
        console.log("Add button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse+" with id "+ this.state.CurrentlySelectedCourseID);    

        let isKeyPresent = this.state.CoursesListB.some(el => {
            // console.log(el.title);
            if(el.title.includes(this.state.CurrentlySelectedCourse))
              return true;
          })

        console.log("Remove button clicked. Is item in CouresListB?"+isKeyPresent);    

        if(isKeyPresent){
            //Can add

            //Filter out the currently Selected item and put it in new array
            const newList = this.state.CoursesListB.filter((item) => item.title !== this.state.CurrentlySelectedCourse);
            //Console Log new filter list
            {newList.map(item => {
                return console.log("Remove button clicked. Filtered list is: " + item.title);
            }) } 

            //Update CourseListB
            this.setState({CoursesListB:newList})
            //Console log course list B
            {this.state.CoursesListB.map(item => {
                return console.log("Remove button clicked. New CouresListB: " + item.title);
            }) } 

            //Update Course List B
            var newArray = this.state.CoursesListA.slice();    
            newArray.push({id: this.state.CurrentlySelectedCourseID, title: this.state.CurrentlySelectedCourse});   
            var newArray2 = newArray.sort((a, b) => a.id > b.id ? 1 : -1)
            // var newArray2 = newArray.sort((a, b) => a.id - b.id )

            {newArray2.map(item => {
                return console.log("Remove button clicked. Sorted NewArray2 " + item.title + " id " + item.id);
            }) }  
            this.setState({CoursesListA:newArray2})
            //Console Log Course List B
            {this.state.CoursesListA.map(item => {
                return console.log("Remove button clicked. New CouresListA: " + item.title);
            }) }  
        }
        else{
            //Cannot Remove
        }


        


    }

    upArrowButtonClickCheck=(event)=>{
        const IndexOfSelectedObject = this.state.CoursesListB.findIndex(obj => obj.title === this.state.CurrentlySelectedCourse);

        //Log the currently selected item for addition
        console.log("Up button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse+" has index "+IndexOfSelectedObject);   
        
        const arr = this.state.CoursesListB;

        const fromIndex = IndexOfSelectedObject; // 👉️ 0
        var toIndex = 0;
        if (fromIndex > 0){
            toIndex = IndexOfSelectedObject - 1;
        }

        const element = arr.splice(fromIndex, 1)[0];
        console.log(element); // ['css']

        arr.splice(toIndex, 0, element);

        console.log(arr); // 👉️ ['js', 'ts', 'css']
        this.setState({CoursesListB:arr})

    }

    downArrowButtonClickCheck=(event)=>{
        const IndexOfSelectedObject = this.state.CoursesListB.findIndex(obj => obj.title === this.state.CurrentlySelectedCourse);

        //Log the currently selected item for addition
        console.log("Down button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse+" has index "+IndexOfSelectedObject);   
        
        const arr = this.state.CoursesListB;

        const fromIndex = IndexOfSelectedObject; // 👉️ 0
        var toIndex = 0;
        if (fromIndex < arr.length){
            toIndex = IndexOfSelectedObject + 1;
        }

        const element = arr.splice(fromIndex, 1)[0];
        console.log(element); // ['css']

        arr.splice(toIndex, 0, element);

        console.log(arr); // 👉️ ['js', 'ts', 'css']
        this.setState({CoursesListB:arr})

    }


    nextButtonClickCheck=(event)=>{

        console.log("@@@@@@@@@@@@@@@@@@@")


        //Set email is a requirement. Would have already redirected if no email in session
        if (this.state.email === undefined || this.state.email === null || this.state.email === "")
        {
            console.log("Setting Email");
            this.setState({email: ReactSession.get("email") })        
        }
        

        //Passing Values
        // console.log("Passing Values:")
        // {console.log("State email: " + this.state.email)}
        // {console.log("English Checkbox: "+this.state.TutorEnglishCheckbox)}
        // {console.log("Spanish Checkbox: "+this.state.TutorSpanishCheckbox)}
        // {console.log("Hindi Checkbox: "+this.state.TutorHindiCheckbox)}
        // {console.log("Other Checkbox: "+this.state.TutorOtherCheckbox)}
        // {console.log("Other Text Entry: "+this.state.TutorOtherTextEntry)}
        // {console.log("Elementary School Checkbox: "+this.state.TutorElementarySchoolCheckbox)}
        // {console.log("Middle School Checkbox: "+this.state.TutorMiddleSchoolCheckbox)}
        // {console.log("High School Checkbox: "+this.state.TutorHighSchoolCheckbox)}
        // {console.log("Tutor School Code Checkbox: "+this.state.TutorSchoolCode)}

        // {console.log("LanguageCheckStatus: "+this.state.LanguageCheckStatus)}
        // {console.log("GradeCheckStatus: "+this.state.GradeCheckStatus)}

        var LanguageArry= []; 
        if (this.state.TutorEnglishCheckbox !== undefined && this.state.TutorEnglishCheckbox !== null && this.state.TutorEnglishCheckbox !== "" && this.state.TutorEnglishCheckbox !== "false" && this.state.TutorEnglishCheckbox !== false)
                {
                    LanguageArry.push("English");
                }
        if (this.state.TutorSpanishCheckbox !== undefined && this.state.TutorSpanishCheckbox !== null && this.state.TutorSpanishCheckbox !== "" && this.state.TutorSpanishCheckbox !== "false" && this.state.TutorSpanishCheckbox !== false)
            {
                LanguageArry.push("Spanish");
            }
        if (this.state.TutorHindiCheckbox !== undefined && this.state.TutorHindiCheckbox !== null && this.state.TutorHindiCheckbox !== "" && this.state.TutorHindiCheckbox !== "false" && this.state.TutorHindiCheckbox !== false)
            {
                LanguageArry.push("Hindi");
            }
        if (
            (this.state.TutorOtherCheckbox !== undefined && this.state.TutorOtherCheckbox !== null && this.state.TutorOtherCheckbox !== "" && this.state.TutorOtherCheckbox !== "false" && this.state.TutorOtherCheckbox !== false)
            &&
            (this.state.TutorOtherTextEntry !== undefined && this.state.TutorOtherTextEntry !== null && this.state.TutorOtherTextEntry !== "")
            ){
                LanguageArry.push(this.state.TutorOtherTextEntry);
            }
            
        var GradeArry=[];
        if (this.state.TutorElementarySchoolCheckbox !== undefined && this.state.TutorElementarySchoolCheckbox !== null && this.state.TutorElementarySchoolCheckbox !== "" && this.state.TutorElementarySchoolCheckbox !== "false" && this.state.TutorElementarySchoolCheckbox !== false)
        {
            GradeArry.push("5");
        }
        if(this.state.TutorMiddleSchoolCheckbox !== undefined && this.state.TutorMiddleSchoolCheckbox !== null && this.state.TutorMiddleSchoolCheckbox !== "" && this.state.TutorMiddleSchoolCheckbox !== "false" && this.state.TutorMiddleSchoolCheckbox !== false)
        {
            GradeArry.push("6");

        }                
        if (this.state.TutorHighSchoolCheckbox !== undefined && this.state.TutorHighSchoolCheckbox !== null && this.state.TutorHighSchoolCheckbox !== "" && this.state.TutorHighSchoolCheckbox !== "false" && this.state.TutorHighSchoolCheckbox !== false)    
        {
            GradeArry.push("9");

        }

        if (GradeArry.length > 0 ){
                this.changeTextColorGreenGradeLevel();
        }
        else{
            this.changeTextColorRedGradeLevel()
        }




        // //Student Selected Courses (B)
        // if (this.state.CoursesListB.length < 1){
        //     //If no courses selected 
        //     //Make Red
        // }
        // else{
        //     //If at least 1 course selected then 
        //     //Make Green
        // }

        //Student Language
            //Check if Language length isn't 0 
            if(this.state.TutorEnglishCheckbox==false && this.state.TutorSpanishCheckbox==false  && this.state.TutorHindiCheckbox==false && this.state.TutorFrenchCheckbox==false && this.state.TutorOtherCheckbox==false)
            {
                // console.log("no languages make it red")
                // this.changeTextColorRedLanguage();
    
                if(
                    (ReactSession.get("TutorEnglishCheckbox") == undefined || ReactSession.get("TutorEnglishCheckbox") == null || ReactSession.get("TutorEnglishCheckbox") =="" || ReactSession.get("TutorEnglishCheckbox") ==" " )&&
                    (ReactSession.get("TutorSpanishCheckbox") == undefined || ReactSession.get("TutorSpanishCheckbox") == null || ReactSession.get("TutorSpanishCheckbox") =="" || ReactSession.get("TutorSpanishCheckbox") ==" " )&&
                    (ReactSession.get("TutorHindiCheckbox") == undefined || ReactSession.get("TutorHindiCheckbox") == null || ReactSession.get("TutorHindiCheckbox") =="" || ReactSession.get("TutorHindiCheckbox") ==" " )&&
                    (ReactSession.get("TutorFrenchCheckbox") == undefined || ReactSession.get("TutorFrenchCheckbox") == null || ReactSession.get("TutorFrenchCheckbox") =="" || ReactSession.get("TutorFrenchCheckbox") ==" " )&&
                    (ReactSession.get("TutorOtherCheckbox") == undefined || ReactSession.get("TutorOtherCheckbox") == null || ReactSession.get("TutorOtherCheckbox") =="" || ReactSession.get("TutorOtherCheckbox") ==" ")
                ){
                    // console.log("student age check triggered - red");
                    this.changeTextColorRedLanguage();
                }
                else{
                    // console.log("student age check triggered - green1");
                    this.setState({ TutorEnglishCheckbox: ReactSession.get("TutorEnglishCheckbox") })
                    this.setState({ TutorSpanishCheckbox: ReactSession.get("TutorSpanishCheckbox") })
                    this.setState({ TutorHindiCheckbox: ReactSession.get("TutorHindiCheckbox") })
                    this.setState({ TutorFrenchCheckbox: ReactSession.get("TutorFrenchCheckbox") })
                    this.setState({ TutorOtherCheckbox: ReactSession.get("TutorOtherCheckbox") })
    
                    var langarraytemp2 = ["English","Spanish","Hindi","French","Other"]
                    var langarraytemp = [ReactSession.get("TutorEnglishCheckbox"), ReactSession.get("TutorSpanishCheckbox"), ReactSession.get("TutorHindiCheckbox") , ReactSession.get("TutorFrenchCheckbox"), ReactSession.get("TutorOtherCheckbox")];
    
                    const asyncLangFunc = async (passedValue, passedCheck) => {
                        //console.log("Async checkLanguageFunc Intiated with value:"+passedValue);
                            if(passedCheck === true){
                                //English is selected to be part of langauges
                                //If languages include selected language do nothing
                                if(this.state.StudentLanguage.includes(passedValue)){
                
                                }
                                //If languages doen't include selected language add selected language
                                else{
                                    //If languages already have other languages add to the end with comma
                                    if(this.state.StudentLanguage.length > 0){
                                    var newStateTemp = this.state.StudentLanguage+","+passedValue ;
                                    this.setState({ StudentLanguage: newStateTemp })
                                    }
                                    //If no other languages set as only language
                                    else{
                                    this.setState({ StudentLanguage: passedValue })
                                    }
                                }
                                return("Passed");
                
                            }
                            else{
                                //
                                //console.log("resetting language state to nothing")
                                if(this.state.StudentLanguage.includes(","+passedValue)){
                                    var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
                                    this.setState({ StudentLanguage: middlemanStudentLang })
                                }
                                else{
                                    var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
                                    this.setState({ StudentLanguage: middlemanStudentLang })
                                }
                                return("Failed");
                            }
                        // }
                    }
                    
    
                    const fetchData = async () => {
                        for (let i = 0; i < langarraytemp2.length; i++) {
                            // console.log("passing:"+ langarraytemp2[i] + " and " + langarraytemp[i])
                            if(langarraytemp[i] == true){
                                let channel = await asyncLangFunc(langarraytemp2[i],langarraytemp[i]);
                                // console.log("channel required: " + channel);
                            }
                        }
                    }
                    fetchData();
    
                    this.changeTextColorGreenLanguage();
                }
    
            }
            else{
                //console.log("no languages make it green")
                this.changeTextColorGreenLanguage();
            }
    
            //Student Selected Courses (B)
            if (this.state.CoursesListB.length < 1){
                //If no courses selected 
                //Make Red
                this.changeTextColorRedCourses();
            }
            else{
                //If at least 1 course selected then 
                //Make Green
                this.changeTextColorGreenCourses();
    
            }


        //Cannot have Language Check and School Check Seperate due to state update delays
        if( 
            (
                (this.state.TutorEnglishCheckbox !== undefined && this.state.TutorEnglishCheckbox !== null && this.state.TutorEnglishCheckbox !== "" && this.state.TutorEnglishCheckbox !== "false" && this.state.TutorEnglishCheckbox !== false)
                ||
                (this.state.TutorSpanishCheckbox !== undefined && this.state.TutorSpanishCheckbox !== null && this.state.TutorSpanishCheckbox !== "" && this.state.TutorSpanishCheckbox !== "false" && this.state.TutorSpanishCheckbox !== false)
                ||
                (this.state.TutorHindiCheckbox !== undefined && this.state.TutorHindiCheckbox !== null && this.state.TutorHindiCheckbox !== "" && this.state.TutorHindiCheckbox !== "false" && this.state.TutorHindiCheckbox !== false)
                ||
                (
                    (this.state.TutorOtherCheckbox !== undefined && this.state.TutorOtherCheckbox !== null && this.state.TutorOtherCheckbox !== "" && this.state.TutorOtherCheckbox !== "false" && this.state.TutorOtherCheckbox !== false)
                    &&
                    (this.state.TutorOtherTextEntry !== undefined && this.state.TutorOtherTextEntry !== null && this.state.TutorOtherTextEntry !== "")
                )
            )
            &&
            (
                (this.state.TutorElementarySchoolCheckbox !== undefined && this.state.TutorElementarySchoolCheckbox !== null && this.state.TutorElementarySchoolCheckbox !== "" && this.state.TutorElementarySchoolCheckbox !== "false" && this.state.TutorElementarySchoolCheckbox !== false)
                ||
                (this.state.TutorMiddleSchoolCheckbox !== undefined && this.state.TutorMiddleSchoolCheckbox !== null && this.state.TutorMiddleSchoolCheckbox !== "" && this.state.TutorMiddleSchoolCheckbox !== "false" && this.state.TutorMiddleSchoolCheckbox !== false)
                ||
                (this.state.TutorHighSchoolCheckbox !== undefined && this.state.TutorHighSchoolCheckbox !== null && this.state.TutorHighSchoolCheckbox !== "" && this.state.TutorHighSchoolCheckbox !== "false" && this.state.TutorHighSchoolCheckbox !== false)    
            )
            &&
            (this.state.CoursesListB.length > 0)
            ){
            console.log("##################################################");

            console.log("passed nextButtonCheck");
            {console.log("Language Array: "+LanguageArry)}

            const stringData = this.state.CoursesListB.reduce((result, item) => {
                return `${result}${item.title},`
              }, "")
            //   console.log("CourseArrayToString: "+stringData.slice(0, -1));
            const courseData2 = stringData.slice(0, -1); 

            // this.setState({ email: ReactSession.get("email") })
            // // this.changeTextColorGreenEmail();    
            // // this.changeTextColorGreenPassword();    
            // // this.changeTextColorGreenTC();    
            // // this.changeTextColorGreenPP();    

            const getCourseDataImproved = async () => {
                    let res = await Axios.post("http://localhost:3001/createBcheckTutor", {
                                email: ReactSession.get("email"),
                                UUID: ReactSession.get("UUID"),
                            }).then(res => {
                                console.log(res);
                                console.log(res.data);

                                this.setState({ emailCheck: res.data }, () => {
                                    console.log("Email Check: "+this.state.emailCheck);
                                    //this.displayQueryData1(); 
                
                                    if(this.state.emailCheck > 0){
                                        console.log("updated needed")
                                        const updateUser = () => {
                                            console.log("post attempted")
                                            console.log("email"+ this.state.email)
                                            console.log(" GradeLevel"+ GradeArry.toString())
                                            console.log("TutorLanguage"+ LanguageArry.toString())
                                            console.log("CoursesListB"+ courseData2)
                                            Axios.post("http://localhost:3001/updateTutorC", {
                                                email: this.state.email, 
                                                UUID: ReactSession.get("UUID"),
                                                GradeLevel: GradeArry.toString(),
                                                TutorLanguage: LanguageArry.toString(),
                                                CoursesListB: courseData2,
                                            }).then(()=>{
                                                console.log("success and redirect");
                                                this.setState({ redirectTime: "true" }, function(){
                                                    console.log(this.state.redirectTime)});
                                            });
                                        };
                                        updateUser();
                                    }
                                    else{
                                        console.log("create needed but previous step needed")
                                        //Perform intended data parse here / redirect
                                        // const addUser = () => {
                                        //     Axios.post("http://localhost:3001/createB", {
                                        //         email: this.state.email, 
                                        //         Student: this.state.FirstNameStudent,
                                        //         LastNameStudent: this.state.LastNameStudent,
                                        //         territory: this.state.Residency,
                                        //         primaryphone: this.state.PrimaryPhoneNumber,
                                        //         StudentAge: this.state.StudentAge,
                                        //     }).then(()=>{
                                        //         console.log("success and redirect");
                                        //         this.setState({ redirectTime: "true" }, function(){
                                        //             console.log(this.state.redirectTime)});
                                        //     });
                                        // };
                                        // addUser();
                                        //End data parse here / redirect
                                    }
                                    console.log("waited for data value")
                                  }); 
                              });
                    // console.log("res"+res)
                
                // console.log(this.stateArray)
            }
                
            
            getCourseDataImproved();

        }
        else{
            console.log("Failed Next Button Check")
        }
    };

    backButtonClickCheck=(event)=>{
        console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            console.log(this.state.redirectBack)});
    }

    changeTextColorRedFirstNameStudent=(event)=>{
        this.setState({ textMatchColorFirstNameStudent: "red" })
    }
    changeTextColorGreenFirstNameStudent=(event)=>{
        this.setState({ textMatchColorFirstNameStudent: "green" })
    }

    changeTextColorRedGradeLevel=(event)=>{
        this.setState({ textMatchColorGradeLevel: "red" })
    }
    changeTextColorGreenGradeLevel=(event)=>{
        this.setState({ textMatchColorGradeLevel: "green" })
    }

    changeTextColorRedLanguage=(event)=>{
        this.setState({ textMatchColorLanguage: "red" })
        this.setState({ boarderCssLanguage: "LanguageBorder" })

    }
    changeTextColorGreenLanguage=(event)=>{
            this.setState({ textMatchColorLanguage: "green" })
            this.setState({ boarderCssLanguage: "LanguageBorder" })
            
        }
    
    changeTextColorRedCourses=(event)=>{
        this.setState({ textMatchColorCourses: "red" })
    }
    changeTextColorGreenCourses=(event)=>{
        this.setState({ textMatchColorCourses: "green" })
    }

    ChangeActiveSelectedColor(passedValue){
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            
            return "activelySelectedItem"
            
        }
        else{
            return "notActivelySelectedItem"
        }
    }
    
    ChangeSelectedColor(passedValue){
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            return "blue"
        }
        else{
            return "black"
        }
    }
    ChangeButtonsSelectedA(passedValue){
        //console.log("button selected triggered");
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            //console.log("button selected triggered now changign");
            this.setState({AddButtonStatus: "true"});
        }
    }

    ChangeButtonsSelectedB(passedValue){
        //console.log("button selected triggered");
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            //console.log("button selected triggered now changign");
            this.setState({AddButtonStatus: "false"});
        }
    }

    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectBackVar = this.state.redirectBack;

        return (
            
            
            
           

            
            <div className="centered">
                {/* {this.getCourseList()} */}
                {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                
                {console.log("English Checkbox: "+this.state.TutorEnglishCheckbox)}
                {console.log("Spanish Checkbox: "+this.state.TutorSpanishCheckbox)}
                {console.log("Hindi Checkbox: "+this.state.TutorHindiCheckbox)}
                {console.log("Other Checkbox: "+this.state.TutorOtherCheckbox)}
                {console.log("Other Text Entry: "+this.state.TutorOtherTextEntry)}

                {console.log("Elementary School Checkbox: "+this.state.TutorElementarySchoolCheckbox)}
                {console.log("Middle School Checkbox: "+this.state.TutorMiddleSchoolCheckbox)}
                {console.log("High School Checkbox: "+this.state.TutorHighSchoolCheckbox)}

                {console.log("Tutor School Code Checkbox: "+this.state.TutorSchoolCode)}

                {console.log("CoursesListA length: "+this.state.CoursesListA.length)}
                {this.state.CoursesListA.map(item => {
          return console.log("State CoursesListA " + item.title);
        }) }
                {console.log("CoursesListB length: "+this.state.CoursesListB.length)}

                {this.state.CoursesListB.map(item => {
          return console.log("State CoursesListB " + item.title);
        }) }

                {console.log("----------------------------- ")}

                {console.log("Session email " + ReactSession.get("email"))}
                
                {console.log("Session English Checkbox: "+ReactSession.get("TutorEnglishCheckbox"))}
                {console.log("Session Spanish Checkbox: "+ReactSession.get("TutorSpanishCheckbox"))}
                {console.log("Session Hindi Checkbox: "+ReactSession.get("TutorHindiCheckbox"))}
                {console.log("Session Other Checkbox: "+ReactSession.get("TutorOtherCheckbox"))}
                {console.log("Session Other Text Entry: "+ReactSession.get("TutorOtherTextEntry"))}
                
                {console.log("Session TutorElementarySchoolCheckbox: "+ReactSession.get("TutorElementarySchoolCheckbox"))}
                {console.log("Session TutorMiddleSchoolCheckbox: "+ReactSession.get("TutorMiddleSchoolCheckbox"))}
                {console.log("Session TutorHighSchoolCheckbox: "+ReactSession.get("TutorHighSchoolCheckbox"))}

                {console.log("Session TutorSchoolCode: "+ReactSession.get("TutorSchoolCode"))}

                {console.log("email " + ReactSession.get("email"))}
                {redirectTimeVar ? (
                    // console.log("Redirect Time is 1 " + this.state.redirectTime),
                    // <Router>
                    <Routes>
             
                        {/* <Route path="/" element={<StudentSignUpB />} /> */}
                        <Route path="/" element={<Navigate to="/TutorSignUpAS4" replace={true} />} />

                   
                    </Routes>
                    // console.log("Redirect Time is 1 " + this.state.redirectTime)

                ) : (
                    // console.log("Redirect Time is 2 " + this.state.redirectTime)
                    console.log("")

                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpAS2" replace={true} />} />
                    </Routes>
                ) : (
                    // console.log("Redirect Time is 2 " + this.state.redirectTime)
                    console.log("")

                )}

                {/* <span> test </span> */}
                <Card>

                    <Card.Body style = {{width:800}}>
                    <h2>
                        Tutor Information       
                        </h2>
                        <br></br>
                        {/* <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color:this.state.textMatchColorEmail}}>Spoken Language</Form.Label>
                            <br></br>
                            <input type="checkbox" value="English" name="Language" 
                            defaultChecked={ReactSession.get("TutorEnglishCheckbox")!== undefined ? (ReactSession.get("TutorEnglishCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({TutorEnglishCheckbox: event.target.checked}, ReactSession.set("TutorEnglishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            // onChange={(event) => this.setState({TutorEnglishCheckbox: event.target.checked}),function(){ , this.checkLanguageFunc()} )}

                            onChange={(event) => this.setState({TutorEnglishCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorEnglishCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            }
                            )}

                            /> English &nbsp;

                            <input type="checkbox" value="Spanish" name="Language" 
                            defaultChecked={ReactSession.get("TutorSpanishCheckbox")!== undefined ? (ReactSession.get("TutorSpanishCheckbox")):(this.state.value)}
                           // onChange={(event) => this.setState({TutorSpanishCheckbox: event.target.checked}, ReactSession.set("TutorSpanishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorSpanishCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorSpanishCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Spanish &nbsp; 

                            <input type="checkbox" value="Hindi" name="Language" 
                            defaultChecked={ReactSession.get("TutorHindiCheckbox")!== undefined ? (ReactSession.get("TutorHindiCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({TutorHindiCheckbox: event.target.checked}, ReactSession.set("TutorHindiCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorHindiCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorHindiCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Hindi &nbsp; 

                
                            <input type="checkbox" value="Other" name="Language" 
                            defaultChecked={ReactSession.get("TutorOtherCheckbox")!== undefined ? (ReactSession.get("TutorOtherCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({TutorOtherCheckbox: event.target.checked}, ReactSession.set("TutorOtherCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorOtherCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorOtherCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Other: &nbsp; 
                            <input type="text" name="Language" disabled={!this.state.TutorOtherCheckbox}
                            defaultValue={ReactSession.get("TutorOtherTextEntry")!== undefined ? (ReactSession.get("TutorOtherTextEntry")):(this.state.value)}
                            onChange={(event) => this.setState({TutorOtherTextEntry: event.target.value},function(){
                                ReactSession.set("TutorOtherTextEntry", event.target.value);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
     
                            />


                        </Form.Group>
                        
                        </Col>
                        </Row> */}

                        <div 
                        // className="LanguageBorder"
                        className={this.state.boarderCssLanguage}
                        style={{ borderColor:  this.state.textMatchColorLanguage}}
                        
                        >
                        <Row >
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Spoken Language(s)</Form.Label>
                            <br></br>
                            <input type="checkbox" value="English" name="Language" 
                            defaultChecked={ReactSession.get("TutorEnglishCheckbox")!== undefined ? (ReactSession.get("TutorEnglishCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({EnglishCheckbox: event.target.checked}, ReactSession.set("EnglishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            // onChange={(event) => this.setState({EnglishCheckbox: event.target.checked}),function(){ , this.checkLanguageFunc()} )}

                            onChange={(event) => this.setState({TutorEnglishCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorEnglishCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            }
                            )}

                            /> English &nbsp;

                            <input type="checkbox" value="Spanish" name="Language" 
                            defaultChecked={ReactSession.get("TutorSpanishCheckbox")!== undefined ? (ReactSession.get("TutorSpanishCheckbox")):(this.state.value)}
                           // onChange={(event) => this.setState({SpanishCheckbox: event.target.checked}, ReactSession.set("SpanishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorSpanishCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorSpanishCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Spanish &nbsp; 

                            <input type="checkbox" value="Hindi" name="Language" 
                            defaultChecked={ReactSession.get("TutorHindiCheckbox")!== undefined ? (ReactSession.get("TutorHindiCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({HindiCheckbox: event.target.checked}, ReactSession.set("HindiCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorHindiCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorHindiCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Hindi &nbsp; 

                            <input type="checkbox" value="French" name="Language" 
                            defaultChecked={ReactSession.get("TutorFrenchCheckbox")!== undefined ? (ReactSession.get("TutorFrenchCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({HindiCheckbox: event.target.checked}, ReactSession.set("HindiCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorFrenchCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorFrenchCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> French &nbsp; 

                
                            <input type="checkbox" value="Other" name="Language" 
                            // onChange={(event) => this.setState({OtherCheckbox: event.target.checked}, ReactSession.set("OtherCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorOtherCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorOtherCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Other: &nbsp; 
                            <input type="text" defaultValue="Other" name="Language" disabled={!this.state.TutorOtherCheckbox} className="formControlCustom"
                            onChange={(event) => this.setState({TutorOtherTextEntry: event.target.value})}
     
                            />


                        </Form.Group>
                        
                        </Col>
                        </Row>
                        </div>

                        <div 
                        // className="LanguageBorder"
                        className={this.state.boarderCssLanguage}
                        style={{ borderColor:  this.state.textMatchColorGradeLevel}}
                        >
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color:this.state.textMatchColorEmail}}>Tutorable Grades</Form.Label>
                            <br></br>
                            <input type="checkbox" value="Elementary" name="SchoolLevel" 
                            defaultChecked={ReactSession.get("TutorElementarySchoolCheckbox")!== undefined ? (ReactSession.get("TutorElementarySchoolCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({TutorEnglishCheckbox: event.target.checked}, ReactSession.set("TutorEnglishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            // onChange={(event) => this.setState({TutorEnglishCheckbox: event.target.checked}),function(){ , this.checkLanguageFunc()} )}

                            onChange={(event) => this.setState({TutorElementarySchoolCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorElementarySchoolCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            }
                            )}

                            /> Elementary School (K-5) &nbsp;

                            <input type="checkbox" value="Middle" name="SchoolLevel" 
                            defaultChecked={ReactSession.get("TutorMiddleSchoolCheckbox")!== undefined ? (ReactSession.get("TutorMiddleSchoolCheckbox")):(this.state.value)}
                           // onChange={(event) => this.setState({TutorSpanishCheckbox: event.target.checked}, ReactSession.set("TutorSpanishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorMiddleSchoolCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorMiddleSchoolCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Middle Schoool (6-8) &nbsp; 

                            <input type="checkbox" value="High" name="SchoolLevel" 
                            defaultChecked={ReactSession.get("TutorHighSchoolCheckbox")!== undefined ? (ReactSession.get("TutorHighSchoolCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({TutorHindiCheckbox: event.target.checked}, ReactSession.set("TutorHindiCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({TutorHighSchoolCheckbox: event.target.checked},function(){
                                ReactSession.set("TutorHighSchoolCheckbox", event.target.checked);
                                // this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> High School (9-12) &nbsp;

                        </Form.Group>
                        
                        </Col>
                        </Row>
                        </div>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formSchoolCode">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorEmail}}
                            >School Code
                            </Form.Label>
                            <Form.Label 
                            style={{fontStyle: "italic"}}
                            > (optional)</Form.Label>
                            <Form.Control type="text" 
                            //defaultValue={ReactSession.get("StudentAge")!== undefined ? (ReactSession.get("StudentAge")):(this.state.value)}
                            
                            onChange={(event) => this.setState({TutorSchoolCode: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                // this.checkSecondaryPhoneNumber();
                                ReactSession.set("TutorSchoolCode", this.state.TutorSchoolCode);
                            }
                            )}
                            />
                        </Form.Group>
                        </Col>
                        </Row>
                         <hr>
                        </hr>
                        <span>Select and sort courses the student needs tutoring in from most required to least:</span>
                        
                        {/* <Row>
                            <Col>
                            <span>Availble Courses: </span><br></br>
                                { this.getListOfCoursesA().map((car=> <button 
                                style={{display:"block" , color: this.ChangeSelectedColor(car.title) }}
                                onLoad={() => this.getCourseList()}
                                onClick={(event) => this.setState({CurrentlySelectedCourse: car.title}, this.setState({CurrentlySelectedCourseID: car.id}),function(){
                                    this.ChangeSelectedColor(car.title)
                                    
                                }
                                )}> {car.title} {car.id} </button>))} 
                                <br></br>
                                <Button variant="primary" value="Aff" onClick={(event) => this.addButtonClickCheck()
                                } > 
                                Add →
                                </Button>
                            </Col>
                            <Col>
                            <span>Selected Courses: </span><br></br>

                                { this.getListOfCoursesB().map((car=> (<button 
                                style={{display:"block" , color: this.ChangeSelectedColor(car.title) }}
                                onClick={(event) => this.setState({CurrentlySelectedCourse: car.title},this.setState({CurrentlySelectedCourseID: car.id}),function(){
                                    this.ChangeSelectedColor(car.title)
                                }
                                )} > {car.title}{car.id} </button> )))} 

                                <br></br>
                                

                                <Button variant="primary" value="Aff" onClick={(event) => this.removeButtonClickCheck()
                                } > 
                                ← Remove 
                                </Button>
                                &nbsp;
                                <Button variant="primary" value="Aff" onClick={(event) => this.upArrowButtonClickCheck()
                                } > 
                                ↑
                                </Button>
                                &nbsp;
                                <Button variant="primary" value="Aff" onClick={(event) => this.downArrowButtonClickCheck()
                                } > 
                                ↓
                                </Button>
                            </Col>
                        </Row> */}

                        <Row>
                            <Col>
                            <span>Availble Courses: </span><br></br> 
                            <div className='courseBoxList' >
                                { this.getListOfCoursesA().map((car=> <button 
                                // className= {"activelySelectedItem"}
                                id={car.title}
                                key={car.title}
                                className={this.ChangeActiveSelectedColor(car.title)}
                                Style={{display:"block" }}
                                onLoad={() => this.getCourseList()}
                                onClick={(event) => this.setState({CurrentlySelectedCourse: car.title}, 
                                    this.setState({CurrentlySelectedCourseID: car.id}),
                                    this.setState({AddButtonStatus: false}),
                                    this.setState({RemoveButtonStatus: true}),
                                    this.ChangeSelectedColor(car.title),
 
                                )}> {car.title} </button>))} 
                            </div>
                                <br></br>
                                <Button variant="primary" 
                                id="addbutton"
                                value="Aff" 
                                // disabled={this.state.AddButtonStatus ? true : false}
                                disabled={this.state.AddButtonStatus}
                                // onClick={(event) => this.addButtonClickCheck() } 
                                onClick={(event) => this.setState({AddButtonStatus: true},
                                this.setState({RemoveButtonStatus: false}),
                                    this.addButtonClickCheck()
                                )} 
                                > 
                                Add → 
                                {/* {this.state.AddButtonStatus ? "true" : "false"} */}
                                </Button>
                            </Col>
                            <Col>
                            <span>Selected Courses: </span><br></br>
                            <div className='courseBoxList'
                                Style={{borderColor:this.state.textMatchColorCourses}}
                            >

                                { this.getListOfCoursesB().map((car=> (<button 
                                className={this.ChangeActiveSelectedColor(car.title)}
                                Style={{display:"block" }}
                                id={car.title}
                                key={car.title}
                                onClick={(event) => this.setState({CurrentlySelectedCourse: car.title},
                                this.setState({CurrentlySelectedCourseID: car.id}),
                                this.setState({AddButtonStatus: true}),
                                this.setState({RemoveButtonStatus: false}),
                                this.ChangeSelectedColor(car.title),
                                
                                )} > {car.title} </button> )))} 
                            </div>

                                <br></br>
                                

                                <Button variant="primary" value="Aff" 
                                // disabled={!this.state.RemoveButtonStatus ? true : false}
                                // disabled={this.state.RemoveButtonStatus ? true : false}
                                disabled={this.state.RemoveButtonStatus}

                                // onClick={(event) => this.removeButtonClickCheck()} 
                                onClick={(event) => this.setState({AddButtonStatus: false},
                                this.setState({RemoveButtonStatus: true}),
                                    this.removeButtonClickCheck()
                                )} 

                                > 
                                ← Remove 
                                {/* {this.state.RemoveButtonStatus ? "true" : "false"} */}
                                </Button>
                                &nbsp;
                                <Button variant="primary" value="Aff"                                
                                disabled={this.state.RemoveButtonStatus}
                                onClick={(event) => this.upArrowButtonClickCheck()
                                } > 
                                ↑
                                </Button>
                                &nbsp;
                                <Button variant="primary" value="Aff" disabled={this.state.RemoveButtonStatus} onClick={(event) => this.downArrowButtonClickCheck()
                                } > 
                                ↓
                                </Button>
                            </Col>
                        </Row>

                        
                        
                       

                        {/* <hr></hr> */}
                        {/* {this.state.CurrentlySelectedCourse}
                        <hr></hr> */}
                        {/* {this.getCourseDataImproved()} */}
                        <br></br>
                        <br></br>
                        
                        <Row>
                            <Col>

                            <Button variant="primary" value="Next" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col style={{ textAlign: 'center'}}>
                            3/4
                            </Col>
                            <Col style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
    
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
