import React from "react";
import { useState } from "react";

import Axios from 'axios'
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import './Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>
// ReactSession.setStoreType("localStorage");

// const userRolesForPageItems = ReactSession.get("roles").split(',');

function LoggedInTutor() {
    return (
        <div className="">
            {/* <Card>
                <Card.Body> */}
                    This is the landing page for a Tutor                
                    <br></br>
                    <div Style="padding-top:1rem; padding-left:1rem">
                        {/* <Card>
                        <Card.Body> */}
                        <div>
                            Home
                        </div>
                        {/* </Card.Body>
                        </Card> */}
                        
                        <Card>
                        <Card.Body>
                        <div>
                            Welcome *username*
                        </div>
                        </Card.Body>
                        </Card>

                        <Card>
                        <Card.Body>
                        <div>
                            Tutoring Status: *status*
                        </div>
                        </Card.Body>
                        </Card>
                    
                    </div>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                {/* </Card.Body>
            </Card> */}
        </div>
    )
}


export default LoggedInTutor; 
