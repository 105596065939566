import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

import emailConfirmIcon from './MediaAssets/emailConfirmIcon.svg'

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
// import { Console } from 'console';

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");



function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={

            VID:"", 
            VIDSTATUS:"False",
            TutorID:"",
            email:"",
            StudentID:"",
        };
        // const { match } = this.props;
        
    };
    

    componentDidMount() {
        // console.log(this.props)
        const redirectLoginVar = this.state.redirectLogin;
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('VID');
        console.log(token)
        this.setState({VID: token });

        // const postResendEmail = async (tokens) => {
        //     let res = await Axios.post("http://localhost:3001/updateVerifyTutorCheck", 
        //     {
        //         TutorVerificationKey: tokens
        //     }).then(res => {
        //         // console.log(res);
        //         if(res.data[0] != undefined){
        //             // console.log(res.data);
        //             // console.log("Vstat is: "+res.data[0].TutorVerificationStatus)
        //             if(res.data[0].TutorVerificationStatus == 0 || res.data[0].TutorVerificationStatus == 1){
        //                 //Send Email
        //                 console.log("Verification Passed");
        //                 // console.log("VKEY IS "+ res.data[0].TutorVerificationKey)
        //                 // console.log("TutorID IS "+ res.data[0].TutorID)
        //                 this.setState({TutorID: res.data[0].TutorID });


        //                 //  SEND VERIFICATION EMAIL REQUIRED --------
        //                 const updateVkeyA = async (vkey) => {
        //                     // console.log("Updating Status Starting");
        //                     this.setState({VIDSTATUS: "updatingA" });

        //                     let res = await Axios.post("http://localhost:3001/updateVerifyTutorA", 
        //                     {
        //                         TutorVerificationKey: vkey,
        //                         TutorVerificationStatus: 2,
        //                     }).then(res => {
        //                         // console.log("Vkey Updated");
        //                         // console.log("UserID Got: "+this.state.TutorID);
        //                         this.setState({VIDSTATUS: "updatingB" });

        //                         const updateLoginA = async (TID) => {
        //                             // console.log("Updating Login Starting");
        
        //                             let res = await Axios.post("http://localhost:3001/updateLoginA", 
        //                             {
        //                                 UUID: TID,
        //                                 SignUpComplete: 100,
        //                                 AccountValidated: 1,
        //                             }).then(res => {
        //                                 // console.log("Login Updated A");
        //                                 this.setState({VIDSTATUS: "updatingC" });

        //                                 const checkLoginByID = async (TID) => {
        //                                     // console.log("Checking for UserID Starting");
                
        //                                     let res = await Axios.post("http://localhost:3001/checkLoginByID", 
        //                                     { 
        //                                         UUID: TID,
        //                                     }).then(res => {
        //                                         this.setState({email: res.data[0].email });

        //                                         const checkLoginRole = async (TID) => {
        //                                             console.log("Create Login Role Starting");
                        
        //                                             let res = await Axios.post("http://localhost:3001/createLoginRoleCheck", 
        //                                             { 
        //                                                 UserID: TID,
        //                                                 Roles: "Tutor",
                                     
        //                                             }).then(res => {
        //                                                 // console.log("Post role check data: " + res.data[0])
        //                                                 if(res.data[0] == undefined){
        //                                                     const updateLoginRole = async (TID,email) => {
        //                                                         // console.log("Create Login Role Starting");
                                    
        //                                                         let res = await Axios.post("http://localhost:3001/createLoginRole", 
        //                                                         { 
        //                                                             UserID: TID,
        //                                                             email: email,
        //                                                             Roles: "Tutor",
        //                                                         }).then(res => {
        //                                                             // console.log("Login Updated B");
        //                                                             this.setState({VIDSTATUS: "updated" });
                                                                    
        //                                                         });
        //                                                     }
        //                                                     updateLoginRole(this.state.TutorID,this.state.email);
        //                                                 }
        //                                                 else{
        //                                                     // console.log("Login Role Already Exists")
        //                                                 }
                                                        
        //                                             });
        //                                         }
        //                                         checkLoginRole(this.state.TutorID);
                                                
                                               
                                                    
                                                
                                                
        //                                     });
        //                                 }
        //                                 checkLoginByID(this.state.TutorID);

                                        

            
                                        
        //                             });
        //                         }
        //                         updateLoginA(this.state.TutorID);

        //                         // this.setState({VIDSTATUS: "updated" });
                                
        //                     });
        //                 }
        //                 updateVkeyA(res.data[0].TutorVerificationKey);
        //             }
        //             else if(res.data[0].TutorVerificationStatus == 2){
        //                 this.setState({VIDSTATUS: "preverified" });
        //             }
        //             else{
        //                 //Throw Error to not send email
        //                 // console.log("Verification Failed");
        //                 this.setState({VIDSTATUS: "failedA" });
        //             }
        //         }
        //         else{
                    
        //             const postResendEmail = async (tokens) => {
        //                 let res = await Axios.post("http://localhost:3001/updateVerifyStudentCheck", 
        //                 {
        //                     StudentVerificationKey: tokens
        //                 }).then(res => {
        //                     // console.log(res);
        //                     if(res.data[0] != undefined){
        //                         // console.log(res.data);
        //                         // console.log("Vstat is: "+res.data[0].Student_VFormData_VStat)
        //                         if(res.data[0].Student_VFormData_VStat == 0 || res.data[0].Student_VFormData_VStat == 1){
        //                             //Send Email
        //                             console.log("Verification Passed");
        //                             // console.log("VKEY IS "+ res.data[0].TutorVerificationKey)
        //                             // console.log("TutorID IS "+ res.data[0].TutorID)
                                    
        //                             console.log("StudentID IS "+ res.data[0].Student_VFormData_UserUUID)
        //                             const StudentIDtemp = res.data[0].Student_VFormData_UserUUID;
        //                             this.setState({StudentID: res.data[0].Student_VFormData_UserUUID });

            
            
        //                             //  SEND VERIFICATION EMAIL REQUIRED --------
        //                             const updateVkeyA = async (vkey) => {
        //                                 // console.log("Updating Status Starting");
        //                                 this.setState({VIDSTATUS: "updatingA" });
            
        //                                 let res = await Axios.post("http://localhost:3001/updateVerifyStudentA", 
        //                                 {
        //                                     StudentVerificationKey: vkey,
        //                                     StudentVerificationStatus: 2,
        //                                 }).then(res => {
        //                                     // console.log("Vkey Updated");
        //                                     // console.log("UserID Got: "+this.state.TutorID);
        //                                     this.setState({VIDSTATUS: "updatingB" });
            
        //                                     const updateLoginA = async (TID) => {
        //                                         // console.log("Updating Login Starting");
                    
        //                                         let res = await Axios.post("http://localhost:3001/updateLoginA", 
        //                                         {
        //                                             UUID: TID,
        //                                             SignUpComplete: 100,
        //                                             AccountValidated: 1,
        //                                         }).then(res => {
        //                                             // console.log("Login Updated A");
        //                                             this.setState({VIDSTATUS: "updatingC" });
            
        //                                             const checkLoginByID = async (TID) => {
        //                                                 // console.log("Checking for UserID Starting");
                            
        //                                                 let res = await Axios.post("http://localhost:3001/checkLoginByID", 
        //                                                 { 
        //                                                     UUID: TID,
        //                                                 }).then(res => {
        //                                                     this.setState({email: res.data[0].email });
            
        //                                                     const checkLoginRole = async (TID) => {
        //                                                         // console.log("Create Login Role Starting");
                                    
        //                                                         let res = await Axios.post("http://localhost:3001/createLoginRoleCheck", 
        //                                                         { 
        //                                                             UserID: TID,
        //                                                             Roles: "Student",
                                                 
        //                                                         }).then(res => {
        //                                                             // console.log("Post role check data: " + res.data[0])
        //                                                             if(res.data[0] == undefined){
        //                                                                 const updateLoginRole = async (TID,email) => {
        //                                                                     // console.log("Create Login Role Starting");
                                                
        //                                                                     let res = await Axios.post("http://localhost:3001/createLoginRole", 
        //                                                                     { 
        //                                                                         UserID: TID,
        //                                                                         email: email,
        //                                                                         Roles: "Student",
        //                                                                     }).then(res => {
        //                                                                         // console.log("Login Updated B");
        //                                                                         this.setState({VIDSTATUS: "updated" });
                                                                                
        //                                                                     });
        //                                                                 }
        //                                                                 updateLoginRole(this.state.StudentID,this.state.email);
        //                                                             }
        //                                                             else{
        //                                                                 // console.log("Login Role Already Exists")
        //                                                                 this.setState({VIDSTATUS: "preverified" });
        //                                                             }
                                                                    
        //                                                         });
        //                                                     }
        //                                                     checkLoginRole(StudentIDtemp);
                                                            
                                                           
                                                                
                                                            
                                                            
        //                                                 });
        //                                             }
        //                                             checkLoginByID(StudentIDtemp);
            
                                                    
            
                        
                                                    
        //                                         });
        //                                     }
        //                                     updateLoginA(StudentIDtemp);
            
        //                                     // this.setState({VIDSTATUS: "updated" });
                                            
        //                                 });
        //                             }
        //                             updateVkeyA(res.data[0].Student_VFormData_VKey);
        //                         }
        //                         else if(res.data[0].Student_VFormData_VStat == "2"){
        //                             this.setState({VIDSTATUS: "preverified" });
        //                         }
        //                         else{
        //                             //Throw Error to not send email
        //                             // console.log("Verification Failed");
        //                             this.setState({VIDSTATUS: "failedA" });
        //                         }
        //                     }
        //                     else{
        //                         // console.log("res is undefined");
        //                         // console.log("Verification Failed");
        //                         this.setState({VIDSTATUS: "failed" });
        //                     }
                            
            
        //                 })
        //             }
        //             postResendEmail(token);
                    
                    
            
        //         }

        //     })
        // }
        // postResendEmail(token);

        const postResendEmail = async (tokens) => {
            let res = await Axios.post("http://localhost:3001/updateVerifyUserCheck", 
            {
                UserVerificationKey: tokens
            }).then(res => {
                console.log("step1pass")
                if(res.data[0] != undefined){
                    console.log(res.data);
                    console.log("Vstat is: "+res.data[0].User_VFormData_VStat)
                    if(res.data[0].User_VFormData_VStat == 0 || res.data[0].User_VFormData_VStat == 1){
                        //Send Email
                        console.log("Verification Passed");
                        // console.log("VKEY IS "+ res.data[0].TutorVerificationKey)
                        // console.log("TutorID IS "+ res.data[0].TutorID)
                        this.setState({UserID: res.data[0].User_VFormData_UserUUID });


                        //  SEND VERIFICATION EMAIL REQUIRED --------
                        const updateVkeyA = async (vkey) => {
                            // console.log("Updating Status Starting");
                            this.setState({VIDSTATUS: "updatingA" });

                            let res = await Axios.post("http://localhost:3001/updateVerifyUserA", 
                            {
                                UserVerificationKey: vkey,
                                UserVerificationStatus: 2,
                            }).then(res => {
                                // console.log("Vkey Updated");
                                // console.log("UserID Got: "+this.state.TutorID);
                                this.setState({VIDSTATUS: "updatingB" });

                                const updateLoginA = async (TID) => {
                                    // console.log("Updating Login Starting");
        
                                    let res = await Axios.post("http://localhost:3001/updateLoginA", 
                                    {
                                        UUID: TID,
                                        SignUpComplete: 100,
                                        AccountValidated: 1,
                                    }).then(res => {
                                        // console.log("Login Updated A");
                                        this.setState({VIDSTATUS: "updatingC" });

                                        const checkLoginByID = async (TID) => {
                                            // console.log("Checking for UserID Starting");
                
                                            let res = await Axios.post("http://localhost:3001/checkLoginByID", 
                                            { 
                                                UUID: TID,
                                            }).then(res => {
                                                this.setState({email: res.data[0].email });

                                                console.log("Before Specific checkers");
                                                //Check Login For Tutor
                                                //If in Tutor G Form
                                                //tutorGFormCheck
                                                const tutorGFormChecker = async () => {
                                                    console.log("Checking for UserID Starting:"+this.state.UserID);
                        
                                                    let res = await Axios.post("http://localhost:3001/tutorGFormCheck", 
                                                    { 
                                                        UserID: this.state.UserID,
                                                    }).then(res => {
                                                        console.log("tutorGFormCheck complete");
                                                        console.log(res.data[0]);
                                                        if(res.data[0] != undefined){
                                                            if(res.data[0].TutorID_UUID.length > 0){
                                                                console.log("Tutor Found with that ID");
                                                                
                                                                const checkLoginRole = async (TID) => {
                                                                    console.log("Create Login Role Starting");
                                        
                                                                    let res = await Axios.post("http://localhost:3001/createLoginRoleCheck", 
                                                                    { 
                                                                        UserID: TID,
                                                                        Roles: "Tutor",
                                                    
                                                                    }).then(res => {
                                                                        // console.log("Post role check data: " + res.data[0])
                                                                        if(res.data[0] == undefined){
                                                                            const updateLoginRole = async (TID,email) => {
                                                                                // console.log("Create Login Role Starting");
                                                    
                                                                                let res = await Axios.post("http://localhost:3001/createLoginRole", 
                                                                                { 
                                                                                    UserID: TID,
                                                                                    email: email,
                                                                                    Roles: "Tutor",
                                                                                }).then(res => {
                                                                                    // console.log("Login Updated B");

                                                                                    console.log("Create Login Role Complete");

                                                                                    //Find which admin to assign 
                                                                                    // LastIdDistro
                                                                                    // ManagerDistributionSelect

                                                                                    const ManagerDistroChecker = async () => {
                                                                                        // console.log("Create Login Role Starting");
                                                            
                                                                                        let res = await Axios.post("http://localhost:3001/ManagerDistributionSelect", {}).then(res => {
                                                                                            if(res.data[0] == undefined){
                                                                                                console.log("No managers?")
                                                                                            }
                                                                                            else if (res.data[0].LastIdDistro > -1){
                                                                                                console.log("Managers Found with valid ID")
                                                                                                console.log("Last manager number was: "+ res.data[0].LastIdDistro)
                                                                                                this.setState({VIDSTATUS: "updated" });
                                                                                            }
                                                                                            else{
                                                                                                console.log("real problem encountered")
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    ManagerDistroChecker();
                                                                                    
                                                                                    //Add to Onboarding Page 

                                                                                    // this.setState({VIDSTATUS: "updated" });
                                                                                    
                                                                                });
                                                                            }
                                                                            updateLoginRole(this.state.UserID,this.state.email);
                                                                        }
                                                                        else{
                                                                            // console.log("Login Role Already Exists")
                                                                        }
                                                                        
                                                                    });
                                                                }
                                                                checkLoginRole(this.state.UserID);


                                                            }
                                                            else{
                                                                // console.log("result found but ID issue")
                                                                this.setState({VIDSTATUS: "ID issue" });
                                                            }
                                                        }
                                                        else{
                                                            //Check Login For Student
                                                            //If in Student G Form
                                                            //studentGFormCheck
                                                            
                                                            const studentGFormChecker = async () => {
                                                                // console.log("Checking for UserID Starting");
                                    
                                                                let res = await Axios.post("http://localhost:3001/studentGFormCheck", 
                                                                { 
                                                                    UserID: this.state.UserID,
                                                                }).then(res => {
                                                                    console.log("studentGFormCheck data")
                                                                    console.log(res.data[0]);
                                                                    if(res.data[0] != undefined){
                                                                        if(res.data[0].Parent_UUID.length > 0){
                                                                            console.log("Student Found with that ID");
                                                                            
                                                                            const checkLoginRole = async (TID) => {
                                                                                console.log("Create Login Role Starting");
                                                    
                                                                                let res = await Axios.post("http://localhost:3001/createLoginRoleCheck", 
                                                                                { 
                                                                                    UserID: TID,
                                                                                    Roles: "Parent",
                                                                
                                                                                }).then(res => {
                                                                                    // console.log("Post role check data: " + res.data[0])
                                                                                    if(res.data[0] == undefined){
                                                                                        const updateLoginRole = async (TID,email) => {
                                                                                            // console.log("Create Login Role Starting");
                                                                
                                                                                            let res = await Axios.post("http://localhost:3001/createLoginRole", 
                                                                                            { 
                                                                                                UserID: TID,
                                                                                                email: email,
                                                                                                Roles: "Parent",
                                                                                            }).then(res => {
                                                                                                // console.log("Login Updated B");

                                                                                                //Add to Matching Pool 

                                                                                                this.setState({VIDSTATUS: "updated" });
                                                                                                
                                                                                            });
                                                                                        }
                                                                                        updateLoginRole(this.state.UserID,this.state.email);
                                                                                    }
                                                                                    else{
                                                                                        // console.log("Login Role Already Exists")
                                                                                    }
                                                                                    
                                                                                });
                                                                            }
                                                                            checkLoginRole(this.state.UserID);
                
                
                                                                        }
                                                                        else{
                                                                            // console.log("result found but ID issue")
                                                                            this.setState({VIDSTATUS: "ID issue" });
                                                                        }
                                                                    }
                                                                    else{
                                                                        //Shouldn't be the case
                                                                        //Check Login For Other Role
                                                                        console.log("contact admin")
                                                                    }
                                                                })
                                                            }
                                                            studentGFormChecker();
                                                        }
                                                    })
                                                }
                                                tutorGFormChecker();
                                            });
                                        }
                                        checkLoginByID(this.state.UserID);

                                        

            
                                        
                                    });
                                }
                                updateLoginA(this.state.UserID);

                                // this.setState({VIDSTATUS: "updated" });
                                
                            });
                        }
                        updateVkeyA(res.data[0].User_VFormData_VKey);
                    }
                    else if(res.data[0].User_VFormData_VStat == 2){
                        this.setState({VIDSTATUS: "preverified" });
                    }
                    else{
                        //Throw Error to not send email
                        // console.log("Verification Failed");
                        this.setState({VIDSTATUS: "failedA" });
                    }
                }
                else{
                    // console.log("No token?")
                    this.setState({VIDSTATUS: "No Token" });

                }
            })
        }
        postResendEmail(token);
        

    }




    backButtonClickCheck=(event)=>{

    }

    resendButtonClickCheck=(event)=>{

        console.log("passed resendButtonClickCheck");
        
        //Actually Call Resend
        
        
    }

    loginButtonClickCheck=(event)=>{
        console.log("Login");
        this.setState({ redirectLogin: "true" }, function(){
            console.log(this.state.redirectLogin)});
    }
    
    render(){

        const redirectLoginVar = this.state.redirectLogin;
        const showVIDmessage = this.state.VIDSTATUS;

        // const urlParams = new URLSearchParams(window.location.search);
        // const token = urlParams.get('VID');
        // console.log(token)

        var showme = "";
        if(showVIDmessage == "updated"){
            showme = "<h2> Email Verfied! </h2> <img  src='https://cdn-icons-png.flaticon.com/512/190/190411.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5 style='text-align:center'> No other action is needed at this time.</h5> <h5 style='text-align:center'> Feel free to </h5> <br>"
        }
        else if(showVIDmessage == "updatingA"){
            showme = " <h2 > Loading.. </h2> "
        }
        else if(showVIDmessage == "updatingB"){
            showme = " <h2 > Loading. </h2> "
        }
        else if(showVIDmessage == "updatingC"){
            showme = " <h2 > Loading </h2> "
        }
        else if(showVIDmessage == "preverified"){
            showme = " <h2 > Email has already been verified... </h2> <img  src='https://icons.veryicon.com/png/o/miscellaneous/unicons/envelope-exclamation-1.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5> If you have questions or are not sure how this email is already verified please contact help@theeduproject.net .</h5> <h5 style='text-align:center'> OR </h5> <br>"
        }
        else if(showVIDmessage == "failed"){
            showme = "<h2> Invalid Verification Key... </h2> <img  src='https://cdn-icons-png.flaticon.com/512/678/678603.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5> If you have questions or are not sure why this key is invalid please contact help@theeduproject.net .</h5> <h5 style='text-align:center'> OR </h5> <br>" 
        }
        else if(showVIDmessage == "failedA"){
            showme = "<h2> Issue occured while verifying your account... </h2> <img  src='https://cdn-icons-png.flaticon.com/512/678/678603.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5> Please contact help@theeduproject.net and mention Error Code: VE407A.</h5> <h5 style='text-align:center'> OR </h5> <br>" 
        }
        else if(showVIDmessage == "failedB"){
            showme = "<h2> Issue occured while verifying your account... </h2> <img  src='https://cdn-icons-png.flaticon.com/512/678/678603.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5> Please contact help@theeduproject.net and mention Error Code: VE407B.</h5> <h5 style='text-align:center'> OR </h5> <br>" 
        }
        else if(showVIDmessage == "ID issue"){
            showme = "<h2> Issue occured while verifying your account... </h2> <img  src='https://cdn-icons-png.flaticon.com/512/678/678603.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5> Please contact help@theeduproject.net and mention Error Code: VE407ID.</h5> <h5 style='text-align:center'> OR </h5> <br>" 
        }
        else if(showVIDmessage == "No Token"){
            showme = "<h2> Issue occured while verifying your account... </h2> <img  src='https://cdn-icons-png.flaticon.com/512/678/678603.png' width='150px' style='display: block; margin-left: auto; margin-right: auto;' ></img> <h5> Please contact help@theeduproject.net and mention Error Code: VE407NT.</h5> <h5 style='text-align:center'> OR </h5> <br>" 
        }
        else{
            showme =" <h2>Loading... </h2>"
        }
        

        return (

            <div className="centered signupBackground" >
                {/* {this.getCourseList()} */}
                {/* {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State HDYHAU " + this.state.HDYHAU)}
                {console.log("State TUAY " + this.state.TUAY)}        


                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session HDYHAU " + ReactSession.get("HDYHAU"))}
                {console.log("Session TUAY " + ReactSession.get("TUAY"))} */}

                
                

                
                {redirectLoginVar ? (
                    <Routes>
                        <Route path="/*" element={<Navigate to="/Login" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}


            
                <span className="cardCustom" style = {{width:800}}>

                   <div className="content" dangerouslySetInnerHTML={{__html: showme}}></div>
                   <button className="loginButton loginButtonText" value="Next" onClick={(event) => this.loginButtonClickCheck()
                        } > 
                        Login
                    </button>
                </span>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
