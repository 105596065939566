import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

import emailConfirmIcon from './MediaAssets/emailConfirmIcon.svg'

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            FirstName: "" ,
            LastName:"",
            PrimaryPhoneNumber:"",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"black",
            textMatchColorFirstNameStudent:"black",
            textMatchColorLastNameStudent:"black",
            textMatchColorPP:"black",
            emailValid:"",
            tcChecked:"",
            ppChecked:"",
            redirectTime:"",
            Residency:"",
            FirstNameStudent: "" ,
            LastNameStudent: "" ,
            GradeLevel:0,
            StudentAge:0,
            StudentLanguage:"",
            EnglishCheckbox:false,
            SpanishCheckbox:false,
            HindiCheckbox:false,
            OtherCheckbox:false,
            CurrentlySelectedCourse:"",
            CurrentlySelectedCourseID:0,
            CoursesListA:[{id:1, title:'Ford'}, {id:2, title:'BMW'}, {id:3, title:'Audi'},{id:4, title:'Toyota'}],
            CoursesListB:[],
            HDYHAU:"",
            TUAY:"",
            redirectLogin:"",
            UUID:"",
        };
    };

    // checkFirstName=(event)=>{
    //     // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
    //     this.setState({ textMatchColorEmail: "black" })

    // }
    // checkLastName=(event)=>{
    //     // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
    //     this.setState({ textMatchColorEmail: "black" })
    // }

    backButtonClickCheck=(event)=>{

    }

    resendButtonClickCheck=(event)=>{

        console.log("passed resendButtonClickCheck");
        
        //Actually Call Resend
        const postResendEmail = async () => {
            let res = await Axios.post("http://localhost:3001/createTutorEcheck", 
            {
                TutorID: ReactSession.get("UUID")
            }).then(res => {
                console.log(res);
                console.log(res.data);
                if(res.data.length > 0){
                    //Send Email
                    console.log("Send Email");

                    //  SEND VERIFICATION EMAIL REQUIRED --------
                    const sendEmailKey = async (VKeyUUID) => {
                        console.log("Email Send starting");
                        let res = await Axios.post("http://localhost:3001/emailTestA", 
                        {
                            Key: VKeyUUID,
                        }).then(res => {
                            console.log("Email Sent");
                            this.setState({redirectTime: true });                    
                        });
                    }
                    sendEmailKey(res.data[0].TutorVerificationKey);
                }
                else{
                    //Throw Error to not send email
                    console.log("Throw Error");
                }

            })
        }
        postResendEmail();
        
    }

    loginButtonClickCheck=(event)=>{
        console.log("Login");
        this.setState({ redirectLogin: "true" }, function(){
            console.log(this.state.redirectLogin)});
    }
    
    render(){
    
        const redirectLoginVar = this.state.redirectLogin;
    
        return (

            <div className="centered signupBackground">
                {/* {this.getCourseList()} */}
                {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State HDYHAU " + this.state.HDYHAU)}
                {console.log("State TUAY " + this.state.TUAY)}        


                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session HDYHAU " + ReactSession.get("HDYHAU"))}
                {console.log("Session TUAY " + ReactSession.get("TUAY"))}
                
                {redirectLoginVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/Login" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}

            
                <span className="cardCustom">
                    <h2> Email Confirmation </h2>
                    {/* <br></br> */}
                    
                    <div className="padding48vert">
                        <span className="tw-flex tw-justify-center ">
                            <img  src={emailConfirmIcon} alt="SVG as an image"></img>
                        </span>
                        <br></br>
                        <span className="tw-text-base">
                            Thank you for registering with us!
                        </span>
                        <br></br><br></br>
                        <span className="tw-blue tw-text-base" >
                            We have sent a confirmation email to {ReactSession.get("email")}. Please verify to complete your registration.
                        </span>
                    </div>
                    
                    {/* <br></br><br></br> */}
                    <button className="loginButton loginButtonText" value="Next" onClick={(event) => this.loginButtonClickCheck()
                        } > 
                        Login
                    </button>
                    <button className="resendEmailButton" value="Next" onClick={(event) => this.resendButtonClickCheck()
                    } > 
                    Resend Email
                    </button>
                </span>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
