import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";


import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            HDYHAU:"",
            redirectBack:"",
            emailCheck:"",
            redirectTime:"",
        };
    };

    async componentDidMount() {
        // if((ReactSession.get("HDYHAU") !== undefined) && (ReactSession.get("HDYHAU") !== null) && (ReactSession.get("HDYHAU") !== "")){
        //     this.setState({HDYHAU: ReactSession.get("HDYHAU") });
        // } 
        // if((ReactSession.get("TUAY") !== undefined) && (ReactSession.get("TUAY") !== null) && (ReactSession.get("TUAY") !== "")){
        //     this.setState({TUAY: ReactSession.get("TUAY") });
        // }
    }

    nextButtonClickCheck=(event)=>{

        //Passing Values
        console.log("Passing Values:")
        console.log("HDYHAU" + this.state.HDYHAU)
        console.log("TUAY" + this.state.TUAY)   
        var emailUUID = uuid();
        var VKeyUUID= uuid();
        console.log(emailUUID);          
        if( 
            (this.state.HDYHAU !== undefined && this.state.HDYHAU !== null && this.state.HDYHAU !== "")
        ){
            console.log("passed nextButtonCheck at least one item has an entry");
            const getCourseDataImproved = async () => {
                let res = await Axios.post("http://localhost:3001/updateEcheckTutor", 
                {
                    TutorID: ReactSession.get("UUID")
                }).then(res => {
                    console.log(res);
                    console.log(res.data);

                    this.setState({ emailCheck: res.data }, () => {
                        console.log("Email Check: "+this.state.emailCheck);
                        //this.displayQueryData1(); 
    
                        if(this.state.emailCheck > 0){
                            console.log("updated needed")
                            //Create and insert UUID for email verification
                            const updateTutorE = () => {
                                console.log("post attempted1")
                                console.log("HDYHAU"+ this.state.HDYHAU)
                                Axios.post("http://localhost:3001/updateTutorE", {
                                    TutorID: ReactSession.get("UUID"),
                                    HDYHAU: this.state.HDYHAU,
                                }).then(()=>{
                                    console.log("success and redirect");
                                    
                                    const createTutorEcheckFunc = async () => {
                                        let res = await Axios.post("http://localhost:3001/createTutorEcheck", 
                                        {
                                            TutorID: ReactSession.get("UUID")
                                        }).then(res => {
                                            console.log(res);
                                            console.log(res.data);

                                            //If tutorVformData already exists then update
                                            if(res.data.length > 0){
                                                console.log("update needed for vform");
                                                //Don't update but resend data
                                                if(res.data[0].TutorVerificationStatus < 2)
                                                {
                                                    console.log("resend email verification");
                                                    sendEmailKey(res.data[0].TutorVerificationKey);
                                                }
                                                else{
                                                    console.log("email already verified");
                                                }
                                            }
                                            else{
                                                //else tutorVformData create
                                                console.log("create needed for vform");
                                                const createTutorEfunc = async () => {
                                                    let res = await Axios.post("http://localhost:3001/createTutorE", 
                                                    {
                                                        TutorID: ReactSession.get("UUID"),
                                                        TutorVerificationStatus: "0",
                                                        TutorVerificationKey: VKeyUUID,

                                                    }).then(res => {
                                                        console.log(res);
                                                        console.log(res.data);
                                                        console.log("calling sendEmail");
                                                        sendEmailKey(VKeyUUID);
                                                    });
                                                }
                                                createTutorEfunc();
                                            }                        
                                            
                                        });
                                    }
                                    createTutorEcheckFunc();

                                    //  SEND VERIFICATION EMAIL REQUIRED --------
                                    const sendEmailKey = async () => {
                                        console.log("Email Send starting");
                                        let res = await Axios.post("http://localhost:3001/emailTestA", 
                                        {
                                            email: ReactSession.get("email"),
                                            Key: VKeyUUID,
                                        }).then(res => {
                                            console.log("Email Sent");
                                            this.setState({redirectTime: true });                    
                                        });
                                    }
                                    // this.setState({ redirectTime: true });
                                });
                            };
                            updateTutorE();
                           
                        }
                        else{
                            console.log("create needed but previous step needed")
                            
                        }
                        console.log("waited for data value")
                    }); 
                });
            }
            getCourseDataImproved();
        }
        else{
            console.log("else hit, redirect without posting as nothing was written");
            // this.setState({ redirectTime: true });
            const createTutorEcheckFunc = async () => {
                let res = await Axios.post("http://localhost:3001/createTutorEcheck", 
                {
                    TutorID: ReactSession.get("UUID")
                }).then(res => {
                    console.log(res);
                    console.log(res.data);

                    //If tutorVformData already exists then update
                    if(res.data.length > 0){
                        console.log("update needed for vform");
                        //Don't update but resend data
                        if(res.data[0].TutorVerificationStatus < 2)
                        {
                            console.log("resend email verification");
                            sendEmailKey(res.data[0].TutorVerificationKey);
                        }
                        else{
                            console.log("email already verified");
                        }
                    }
                    else{
                        //else tutorVformData create
                        console.log("create needed for vform");
                        const createTutorEfunc = async () => {
                            let res = await Axios.post("http://localhost:3001/createTutorE", 
                            {
                                TutorID: ReactSession.get("UUID"),
                                TutorVerificationStatus: "0",
                                TutorVerificationKey: VKeyUUID,

                            }).then(res => {
                                console.log(res);
                                console.log(res.data);
                                console.log("calling sendEmail");
                                sendEmailKey(VKeyUUID);
                            });
                        }
                        createTutorEfunc();
                    }                        
                    
                });
            }
            createTutorEcheckFunc();

            //  SEND VERIFICATION EMAIL REQUIRED --------
            const sendEmailKey = async () => {
                console.log("Email Send starting");
                let res = await Axios.post("http://localhost:3001/emailTestA", 
                {
                    email: ReactSession.get("email"),
                    Key: VKeyUUID,
                }).then(res => {
                    console.log("Email Sent");
                    this.setState({redirectTime: true });                    
                });
            }
        }
    };

    backButtonClickCheck=(event)=>{
        console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            console.log(this.state.redirectBack)});
    }
    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectBackVar = this.state.redirectBack;

        return (

            <div className="centered">
                {/* {this.getCourseList()} */}
                {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State HDYHAU " + this.state.HDYHAU)}
                {console.log("State TUAY " + this.state.TUAY)}        

                {console.log("Session UUID " + ReactSession.get("UUID"))}
                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session HDYHAU " + ReactSession.get("HDYHAU"))}
                {console.log("Session TUAY " + ReactSession.get("TUAY"))}
                
                {redirectTimeVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpAS6" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpAS4" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}

                <Card>

                    <Card.Body 
                    style = {{width:800}}
                    >
                    <h2>
                        Tutor Information       
                        </h2>
                        <br></br>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formFirstNameStudent">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorFirstNameStudent}}
                            >
                                How did you hear about us?
                            </Form.Label>    
                            <Form.Label 
                            // style={{ color:this.state.textMatchColorFirstNameStudent}}
                            >
                                (Optional)
                            </Form.Label>   
                            <br></br>
                            <textarea 
                            className='form-control'
                            style={{ height:"160px"}} 
                            //style={{ borderColor:this.state.textMatchColorFirstNameStudent}} 
                            type="" placeholder="" 
                            defaultValue={ReactSession.get("HDYHAU")!== undefined ? (ReactSession.get("HDYHAU")):(this.state.value)}
                            onChange={(event) => this.setState({HDYHAU: event.target.value}
                                ,function(){
                                ReactSession.set("HDYHAU", this.state.HDYHAU);
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row>
                        
                        <Row>
                            <Col>

                            <Button variant="primary" value="Next" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col style={{ textAlign: 'center'}}>
                            4/4
                            </Col>
                            <Col style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
    
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
