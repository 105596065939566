import React , { Component } from 'react'; 
import { useState, useEffect } from "react";
import { ReactSession } from 'react-client-session';

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}


function TheHooks(){
    const [rerenderforce, setrerenderforce] = useState("");
    const [vkeyStatus, setvkeyStatus] = useState("");
    const [vkeyCheck, setvkeyCheck] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("id")

    useEffect(() => {
        const getEmailKey = async () => {
            let res = await Axios.post("http://localhost:3001/getEmailVerificationKeyCount_Vkey",
            {
                VKey: searchParams.get("id")
            }).then(res => {
                // console.log(res);
                console.log(res.data);
    
                setvkeyCheck(res.data[0]); 
                // const vkeygo = () => {
                    // console.log("Email Check: "+this.state.emailCheck);
                    //this.displayQueryData1(); 
    
                    if(res.data[0] > 0){ 
                        console.log("vkey found");
                        console.log("vkey value:"+res.data[1][0].Student_VFormData_VStat);
                        if(res.data[1][0].Student_VFormData_VStat == 0){
                            Axios.post("http://localhost:3001/updateEmailVerificationKeyCount_Vstat", {
                                Vstat: "1", 
                                VKey: searchParams.get("id"),
                            }).then(()=>{
                                setvkeyStatus("Account Verified"); 
                            });
                        }
                        else{
                            setvkeyStatus("Account already Verified ");
                        }
                    }
                    else{
                        console.log("key not found...");
                        setvkeyStatus("Account Could not be Verified. Invalid link / key."); 
    
                    }
                // };
                // vkeygo();
            }); 
           
        }
        getEmailKey();
    }, []);

    function getButtons() {
        console.log("vkeycheck"+vkeyCheck)
        if(vkeyCheck == "1"){
            console.log("vkeycheckpass")

            return( <a href="/Login" > <Button variant="primary" value="Login" >  Login </Button> </a>);
           }
           else if(vkeyCheck == "0"){
            console.log("vkeycheckpass")

            return( <a href="/" > <Button variant="primary" value="Home" >  Home </Button> </a>);
            // <Button variant="primary" value="Next" >  Return Home </Button> );
           }
           else{
               return ""
           }
    }
   
    return (
        <div>
            <div className="centered">

            <Card>

                <Card.Body 
                // style = {{width:800}}
                >
                <h2>
                    Email Confirmation       
                </h2>
                <br></br>
                <br></br>
                <span>
                    {vkeyStatus} 
                </span>
                <span> <br></br>
                {getButtons()}
                </span>
                

                </Card.Body>
            </Card>
                </div>
                </div>
      );
}



export default TheHooks; 
