import React from "react";
import { useState } from "react";

// import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

function RegisterWhoAreYou() {

    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    // const displayInfo = () => {
    //     console.log(email + password);
    // }

    // const [userList, setUserList] = useState([]);

    // const addUser = () => {
    //     Axios.post("http://localhost:3001/create", {
    //         email: email, 
    //         password: password,
    //     }).then(()=>{
    //         console.log("success");
    //     });
    // };

    // const getUsers = () => {
    //     Axios.get("http://localhost:3001/users").then((response) => {
    //         // console.log(response);
    //         setUserList(response.data);
    //     })
    // };

    return (
        
        <div className="centered" Style="background:#F1F2F4">
            {/* <span> test </span> */}
            <Card>

                
                {/* <Card.Img variant="top" src="https://img.freepik.com/free-photo/asian-woman-hand-writing-pencil-notebook-coffee-shop-with-vintage-toned_1627-1653.jpg" /> */}

                <Card.Body style = {{width:500}}>
                    <div Style="padding:2rem">
                        <h2>                    Register                    </h2>
                        <span> Tell us who you are.</span>
                        <br></br>
                        <br></br>
                        <div className="center">
                            <Button variant="outline-primary" href="StudentSignUpA"  Style = {"width:100%" }>Student / Parent</Button>
                        </div>      
                        <br></br>    
                        <div className="center">
                            <Button variant="outline-primary" href="TutorSignUpA" Style = {"width:100%" }>Tutor</Button>
                        </div>      
                        <br></br>
                        {/* <div className="center">
                            <Button variant="outline-primary" href="VolunteerSignUpA" Style = {"width:100%" }>Other Volunteer</Button>
                        </div>    */}
                        <div Style="width: 100%; height: 13px; border-bottom: 1px solid gray; text-align: center; margin-top:20px; margin-bottom:20px">
                        <span Style="font-size: 16px; background-color: white; padding: 0 10px; color:gray">
                            or
                        </span>
                        </div>
                        <div className="center">
                            <br></br>

                                <Button variant="primary" href="Login" Style = {"width:100%" }>Login</Button>

    
                            <br></br>
                            <br></br>
                        </div>

                        <div className="center">
                            <br></br>
                            <br></br>
                            <span>
                                <a href="/">Return to Homepage</a>
                            </span>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    



                </Card.Body>
            </Card>
        </div>

    );
}

export default RegisterWhoAreYou; 
