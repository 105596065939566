import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpAS3 from "./StudentSignUpAS3";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

function TheHooks(){
    const [dark, setDark] = React.useState(localStorage.getItem('dark-mode') === 'true');

    React.useEffect(() => {
      localStorage.setItem('dark-mode', dark);
    }, [dark]);
  
    const toggleDarkMode = () => {
      setDark(!dark);
    };
  
    return (
      <div className={`app-wrapper ${dark ? 'dark' : ''}`}>
       <button className="dark-mode-toggle" onClick={toggleDarkMode}>
         <img src="https://felixgerschau.com/logos/lightbulb.svg" height="100px" width="100px"/>
         <h2>Click me!</h2>
       </button>
      </div>
    );
}


class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            FirstName: "" ,
            LastName:"",
            PrimaryPhoneNumber:"",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"#ced4da",
            textMatchColorFirstName:"#ced4da",
            textMatchColorLastName:"#ced4da",
            textMatchColorPP:"#ced4da",
            textMatchColorTR:"#ced4da",
            textMatchColorSPN:"#ced4da",
            emailValid:"",
            ppNChecked:"",
            ppChecked:"",
            redirectTime:"",
            Residency:"",
            UUID:"",
            redirectBack:"",
            checkPrimaryPhoneNumber:"false",
            SecondaryPhoneNumber2:"",

        };
    };

    checkValidation=(event)=>{

        // console.log("CheckValidation Invoked");

        const confirmPasswordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        const passwordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        //console.log('3rd regex '+ confirmPasswordRegex +' & 4th regex '+ passwordRegex) ;
        this.setState({ textMatchColor: "black" })

        // if(passwordRegex.match(confirmPasswordRegex)){
            if( this.state.password === this.state.confirmPassword){
            //console.log("Match");
            
            this.setState({ labelMatch: "true" })
            // this.setState({ textMatchColor: "black" })

        }
        else{
            //console.log("Nope");

            this.setState({ labelMatch: "false" })
            // this.setState({ textMatchColor: "black" })

        }

    };
    checkEmailValidation=(event)=>{
        // //console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })
        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
          }
          if (!isValidEmail(this.state.email)) {
            this.setState({ emailValid: "false" })
          } else {
            this.setState({ emailValid: "true" })
          }

          //console.log("CheckEmailValidation Invoked "+this.state.emailValid);

    }

    checkFirstName=(event)=>{
        this.setState({ textMatchColorFirstName: "#ced4da" })

    }
    checkLastName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })
    }
    checkPrimaryPhoneNumber=(event)=>{

        //console.log("checkPrimaryPhoneNumber Invoked");

        const confirmPasswordRegex = new RegExp("^([0-9]{3})-([0-9]{3})-([0-9]{4})$");
        if(confirmPasswordRegex.test(this.state.PrimaryPhoneNumber)){
            //console.log("regexpass");
            this.setState({ checkPrimaryPhoneNumber: "true" })
            ReactSession.set("checkPrimaryPhoneNumber", "true") ;

            // this.setState({ ppNChecked: "black" })
        }
        else{
            // console.log("regexfail");
            this.setState({ checkPrimaryPhoneNumber: "false" })
            ReactSession.set("checkPrimaryPhoneNumber", "false") ;

        }

    };
    checkSecondaryPhoneNumber=(event)=>{

        //console.log("checkSecondaryPhoneNumber Invoked");

        const confirmPasswordRegex = new RegExp("^([0-9]{3})-([0-9]{3})-([0-9]{4})$");
        if(confirmPasswordRegex.test(this.state.SecondaryPhoneNumber)){
            //console.log("regexpass");
            this.setState({ checkSecondaryPhoneNumber: "true" })
            
        }
        else{
            //console.log("regexfail");
            this.setState({ checkSecondaryPhoneNumber: "false" })
        }

    };

    backButtonClickCheck=(event)=>{
        //console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            console.log(this.state.redirectBack)
        });
    }


    nextButtonClickCheck=(event)=>{

        //FirstName Check Color
        if (this.state.FirstName === undefined || this.state.FirstName === null || this.state.FirstName === ""){
            if(ReactSession.get("FirstName") === undefined || ReactSession.get("FirstName") === null || ReactSession.get("FirstName") ===""){
                this.changeTextColorRedFirstName();
            }
            else{
                this.setState({ FirstName: ReactSession.get("FirstName") })
                this.changeTextColorGreenFirstName();
            }
        }
        // if (this.state.FirstName !== undefined && this.state.FirstName !== null && this.state.FirstName !== ""){
        //     this.changeTextColorGreenFirstName();
        // }
        //LastName Check Color
        if (this.state.LastName === undefined || this.state.LastName === null || this.state.LastName === ""){
            if(ReactSession.get("LastName") === undefined || ReactSession.get("LastName") === null || ReactSession.get("LastName") ===""){
                this.changeTextColorRedLastName();
            }
            else{
                this.setState({ LastName: ReactSession.get("LastName") })
                this.changeTextColorGreenLastName();
            }
        }
        else{
            this.changeTextColorGreenLastName();
        }
        // if (this.state.LastName !== undefined && this.state.LastName !== null && this.state.LastName !== ""){
        //     this.changeTextColorGreenLastName();    
        // }

        if (this.state.PrimaryPhoneNumber === undefined || this.state.PrimaryPhoneNumber === null || this.state.PrimaryPhoneNumber === ""){

            if(ReactSession.get("PrimaryPhoneNumber") === undefined || ReactSession.get("PrimaryPhoneNumber") === null || ReactSession.get("PrimaryPhoneNumber") ===""){
                this.changeTextColorRedPPN();
            }
            else{
                //console.log("PPN Session Check");

                this.setState({PrimaryPhoneNumber: ReactSession.get("PrimaryPhoneNumber")}, () => {
                    // console.log("Phone number set state started")
                    const awaitCheckPPN = async () => {
                        await this.checkPrimaryPhoneNumber();
                            if (this.state.checkPrimaryPhoneNumber == "false"){
                                this.changeTextColorRedPPN();
                            }
                            if ( this.state.checkPrimaryPhoneNumber == "true"){
                                this.changeTextColorGreenPPN();    
                                proceedWithCheck();
                            } 
                            
                    };
                    awaitCheckPPN();
                    
                });
            }
        }
        else{
            if (this.state.checkPrimaryPhoneNumber == "false"){
                this.changeTextColorRedPPN();
            }
            if ( this.state.checkPrimaryPhoneNumber == "true"){
                this.changeTextColorGreenPPN();    
            }
        }

        if(this.state.checkSecondaryPhoneNumber == "true"){
            this.setState({SecondaryPhoneNumber2: this.state.SecondaryPhoneNumber});
            this.changeTextColorGreenSPN();    
        }
        else if ( this.state.checkSecondaryPhoneNumber == "false"){
            this.setState({SecondaryPhoneNumber2: ""});
            if (this.state.SecondaryPhoneNumber === undefined || this.state.SecondaryPhoneNumber === null || this.state.SecondaryPhoneNumber === ""){
            //Don't do anything if null
            }
            else{
                this.changeTextColorRedSPN();
            }
        }
        

        if (this.state.Residency === undefined || this.state.Residency === null || this.state.Residency === ""){
            //console.log("Residency Triggered")
            this.changeTextColorRedTR();
        }
        else{
            this.changeTextColorGreenTR();
        }
        
        const proceedWithCheck = () =>  {
            if( 
                (this.state.FirstName != undefined && this.state.FirstName != null && this.state.FirstName != "")
                && 
                (this.state.LastName != undefined && this.state.LastName != null && this.state.LastName != "")
                && 
                (
                    // (this.state.PrimaryPhoneNumber != undefined || this.state.PrimaryPhoneNumber != null || this.state.PrimaryPhoneNumber != " " || this.state.PrimaryPhoneNumber != "")
                    // && 
                    (this.state.checkPrimaryPhoneNumber == "true") || (ReactSession.get("checkPrimaryPhoneNumber") == "true")
                )
                && 
                (this.state.Residency != undefined || this.state.Residency != null || this.state.Residency != " ")
                ){
                //console.log("passed nextButtonCheck");
    
                this.setState({ email: ReactSession.get("email") })
                // this.changeTextColorGreenEmail();    
                // this.changeTextColorGreenPassword();    
                // this.changeTextColorGreenTC();    
                // this.changeTextColorGreenPP();    
                
    
                const getCourseDataImproved = async () => {
                    let res = await Axios.post("http://localhost:3001/createBcheck", {
                        email: ReactSession.get("email")
                    }).then(res => {
                        //console.log(res);
                        //console.log(res.data);
    
                        this.setState({ emailCheck: res.data }, () => {
                            //console.log("Email Check: "+this.state.emailCheck);
                            //this.displayQueryData1(); 
        
                            if(this.state.emailCheck > 0){
                                //console.log("updated needed")
                                const updateUser = () => {
                                    Axios.post("http://localhost:3001/updateB", {
                                        email: this.state.email, 
                                        firstname: this.state.FirstName,
                                        lastname: this.state.LastName,
                                        territory: this.state.Residency,
                                        primaryphone: this.state.PrimaryPhoneNumber,
                                        secondaryphone: this.state.SecondaryPhoneNumber2,
                                        UUID: ReactSession.get("UUID"),
                                    }).then(()=>{
                                        //console.log("success and redirect");
                                        // this.setState({ redirectTime: "true" }, function(){
                                        //     //console.log(this.state.redirectTime)
                                        // });
                                        updateStep();
                                    });
                                };
                                updateUser();
                            }
                            else{
                                //console.log("create needed")
                                //Perform intended data parse here / redirect
                                const addUser = () => {
                                    Axios.post("http://localhost:3001/createB", {
                                        email: this.state.email, 
                                        firstname: this.state.FirstName,
                                        lastname: this.state.LastName,
                                        territory: this.state.Residency,
                                        primaryphone: this.state.PrimaryPhoneNumber,
                                        secondaryphone: this.state.SecondaryPhoneNumber2,
                                        UUID: ReactSession.get("UUID"),
                                    }).then(()=>{
                                        //console.log("success and redirect");
                                        // this.setState({ redirectTime: "true" }, function(){
                                        //     //console.log(this.state.redirectTime)
                                        // });
                                        updateStep();
                                    });
                                };
                                addUser();
                                //End data parse here / redirect
                            }
                            //console.log("waited for data value")
                            const updateStep = () => {
                                Axios.post("http://localhost:3001/updateLoginStep", {
                                    email: this.state.email, 
                                    step:"2-4"
                                }).then(()=>{
                                    //console.log("success and redirect");
                                    this.setState({ redirectTime: "true" }, function(){
                                        //console.log(this.state.redirectTime)
                                    });
                                });
                            };
                        }); 
                    });
                        // console.log("res"+res)
                    
                    // console.log(this.stateArray)
                }
                getCourseDataImproved();
            }
        };
        proceedWithCheck();
        
    };

    changeTextColorRedFirstName=(event)=>{
        this.setState({ textMatchColorFirstName: "red" })
    }
    changeTextColorRedLastName=(event)=>{
        this.setState({ textMatchColorLastName: "red" })
    }
    changeTextColorRedPPN=(event)=>{
        this.setState({ textMatchColorPPN: "red" })
    }
    changeTextColorRedSPN=(event)=>{
        this.setState({ textMatchColorSPN: "red" })
    }
    changeTextColorRedTR=(event)=>{
        this.setState({ textMatchColorTR: "red" })
    }
    changeTextColorGreenFirstName=(event)=>{
        this.setState({ textMatchColorFirstName: "green" })
    }
    changeTextColorGreenLastName=(event)=>{
        this.setState({ textMatchColorLastName: "green" })
    }
    changeTextColorGreenPPN=(event)=>{
        this.setState({ textMatchColorPPN: "green" })
    }
    changeTextColorGreenSPN=(event)=>{
        this.setState({ textMatchColorSPN: "green" })
    }
    changeTextColorGreenTR=(event)=>{
        this.setState({ textMatchColorTR: "green" })
    }

    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectBackVar = this.state.redirectBack;

        return (
            
            
           

            
            <div className="centered">
                {/* {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State FirstName " + this.state.FirstName)}
                {console.log("State LastName " + this.state.LastName)}
                {console.log("State PrimaryPhoneNumber " + this.state.PrimaryPhoneNumber)}
                {console.log("State checkPrimaryPhoneNumber " + this.state.checkPrimaryPhoneNumber)}
                {console.log("State SecondaryPhoneNumber " + this.state.SecondaryPhoneNumber)}
                {console.log("State SecondaryPhoneNumber2 " + this.state.SecondaryPhoneNumber2)}
                {console.log("State Residency " + this.state.Residency)}

                {console.log("Session UUID " + ReactSession.get("UUID"))}
                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session FirstName " + ReactSession.get("FirstName"))}
                {console.log("Session LastName " + ReactSession.get("LastName"))}
                {console.log("Session PrimaryPhoneNumber " + ReactSession.get("PrimaryPhoneNumber"))}
                {console.log("Session SecondaryPhoneNumber " + ReactSession.get("SecondaryPhoneNumber"))}
                {console.log("Session Residency " + ReactSession.get("Residency"))}


                {console.log("email " + ReactSession.get("email"))} */}
                {redirectTimeVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/StudentSignUpAS3" replace={true} />} />
                    </Routes>

                ) : (
                    //console.log("")
                    <div></div>
                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/StudentSignUpA" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    //console.log("")
                    <div></div>
                )}

                {/* <span> test </span> */}
                <Card>

                    <Card.Body style = {{width:800}}>
                        <h2>
                            Parent / Guardian Information       
                        </h2>
                        <br></br>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            // style={{ color:this.state.textMatchColorFirstName}}
                            >First Name</Form.Label>
                            <Form.Control type="text" placeholder="" 
                            style={{ borderColor:this.state.textMatchColorFirstName}}
                            defaultValue={ReactSession.get("FirstName")!== undefined ? (ReactSession.get("FirstName")):(this.state.value)}
                            onChange={(event) => this.setState({FirstName: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkFirstName();
                                ReactSession.set("FirstName", this.state.FirstName);

                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            // style={{ color:this.state.textMatchColorLastName}}
                            >Last Name</Form.Label>
                            <Form.Control style={{ borderColor:this.state.textMatchColorLastName}} type="email" placeholder="" 
                                defaultValue={ReactSession.get("LastName")!== undefined ? (ReactSession.get("LastName")):(this.state.value)}
                             onChange={(event) => this.setState({LastName: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkLastName();
                                ReactSession.set("LastName", this.state.LastName);
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorPPN}}
                            >Primary Phone Number</Form.Label>
                            <Form.Control type="phone" pattern="([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})" maxLength={12} 
                            style={{ borderColor:this.state.textMatchColorPPN}}
                            defaultValue={ReactSession.get("PrimaryPhoneNumber")!= undefined ? (ReactSession.get("PrimaryPhoneNumber")):(this.state.value)}
                            onKeyPress={(event => {
                                if(!/([0-9]||[-])/.test(event.key)){
                                    event.preventDefault();
                                    this.setState({PrimaryPhoneNumber: event.target.value});
                                    ReactSession.set("PrimaryPhoneNumber", this.state.PrimaryPhoneNumber);

                                }
                            })}
                            
                            onChange={(event) => this.setState({PrimaryPhoneNumber: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkPrimaryPhoneNumber();
                                ReactSession.set("PrimaryPhoneNumber", this.state.PrimaryPhoneNumber);
                            }
                            )}
                            />
                            <span Style="font-style:italic; color:gray">
                                Format: 123-456-7890
                            </span>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorSPN}}
                            >Secondary Phone Number  <span Style="font-style:italic; color:gray">
                            (optional)
                        </span></Form.Label>

                            <Form.Control type="phone" pattern="([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})" maxLength={12} 
                            style={{ borderColor:this.state.textMatchColorSPN}}
                            defaultValue={ReactSession.get("SecondaryPhoneNumber")!= undefined ? (ReactSession.get("SecondaryPhoneNumber")):(this.state.value)}
                            onKeyPress={(event => {
                                if(!/([0-9]||[-])/.test(event.key)){
                                    event.preventDefault();
                                    this.setState({SecondaryPhoneNumber: event.target.value});
                                    ReactSession.set("SecondaryPhoneNumber", this.state.SecondaryPhoneNumber);

                                }
                            })}
                            
                            onChange={(event) => this.setState({SecondaryPhoneNumber: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkSecondaryPhoneNumber();
                                ReactSession.set("SecondaryPhoneNumber", this.state.SecondaryPhoneNumber);
                            }
                            )}
                            />
                            <span Style="font-style:italic; color:gray">
                                Format: 123-456-7890
                            </span>
                        </Form.Group>
                        </Col>
                        </Row>
                        <hr>
                        </hr>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorTR}}
                            >Select your State or Territory of Residence</Form.Label>
                            <Form.Select defaultValue="N/A" type="text" placeholder="Enter Residence" 
                            style={{ borderColor:this.state.textMatchColorTR}}
                            value={this.state.value} 
                            onChange={(event) => this.setState({Residency: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                // this.checkResidency()
                                ReactSession.set("Residency", this.state.Residency);
                            }
                            )}>
                                <option disabled value="N/A" >Choose a location of residency</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NY">New York</option>
                                <option value="CT">Connecticut</option>
                                

                            </Form.Select>
                        </Form.Group>
                        <Row>
                            <Col>

                            <Button variant="primary" value="Back" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col style={{ textAlign: 'center'}}>
                            2/4
                            </Col>
                            <Col style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
    
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
