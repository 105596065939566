import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";


import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            FirstName: "" ,
            LastName:"",
            PrimaryPhoneNumber:"",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"black",
            textMatchColorFirstNameStudent:"black",
            textMatchColorLastNameStudent:"black",
            textMatchColorPP:"black",
            emailValid:"",
            tcChecked:"",
            ppChecked:"",
            redirectTime:"",
            Residency:"",
            FirstNameStudent: "" ,
            LastNameStudent: "" ,
            GradeLevel:0,
            StudentAge:0,
            StudentLanguage:"",
            EnglishCheckbox:false,
            SpanishCheckbox:false,
            HindiCheckbox:false,
            OtherCheckbox:false,
            CurrentlySelectedCourse:"",
            CurrentlySelectedCourseID:0,
            CoursesListA:[{id:1, title:'Ford'}, {id:2, title:'BMW'}, {id:3, title:'Audi'},{id:4, title:'Toyota'}],
            CoursesListB:[],
            HDYHAU:"",
            TUAY:"",
            redirectBack:"",
            emailVerificationCheck:"",
        };
    };

    async componentDidMount() {
        if((ReactSession.get("HDYHAU") !== undefined) && (ReactSession.get("HDYHAU") !== null) && (ReactSession.get("HDYHAU") !== "")){
            this.setState({HDYHAU: ReactSession.get("HDYHAU") });
        } 
        if((ReactSession.get("TUAY") !== undefined) && (ReactSession.get("TUAY") !== null) && (ReactSession.get("TUAY") !== "")){
            this.setState({TUAY: ReactSession.get("TUAY") });
        }
    }

    nextButtonClickCheck=(event)=>{

        //Passing Values
        console.log("Passing Values:")
        console.log("HDYHAU" + this.state.HDYHAU)
        console.log("TUAY" + this.state.TUAY)   
        var emailUUID = uuid();
        console.log(emailUUID);          
        if( 
            (this.state.HDYHAU !== undefined && this.state.HDYHAU !== null && this.state.HDYHAU !== "")
            || 
            (this.state.TUAY !== undefined && this.state.TUAY !== null && this.state.TUAY !== "")
        ){
            console.log("passed nextButtonCheck at least one item has an entry");

            this.setState({ email: ReactSession.get("email") })

            const getCourseDataImproved = async () => {
                let res = await Axios.post("http://localhost:3001/createBcheck", 
                {
                    email: ReactSession.get("email")
                }).then(res => {
                    console.log(res);
                    console.log(res.data);

                    this.setState({ emailCheck: res.data }, () => {
                        console.log("Email Check: "+this.state.emailCheck);
                        //this.displayQueryData1(); 
    
                        if(this.state.emailCheck > 0){
                            console.log("updated needed")
                            //Create and insert UUID for email verification
                            const emailSender = () => {
                                console.log("post attempted1")
                                console.log("email"+ this.state.email)
                                console.log("HDYHAU"+ this.state.HDYHAU)
                                console.log("TUAY"+ this.state.TUAY)
                                Axios.post("http://localhost:3001/updateD", {
                                    email: this.state.email, 
                                    HDYHAU: this.state.HDYHAU,
                                    TUAY: this.state.TUAY,
                                }).then(()=>{
                                    console.log("success and redirect");
                                    // this.setState({ redirectTime: "true" }, function(){
                                    //     console.log(this.state.redirectTime)});
                                    getEmailKey(); 
                                });
                            };
                            emailSender();
                            // const updateUser = () => {
                            //     console.log("post attempted2")
                            //     console.log("email"+ this.state.email)
                            //     console.log("HDYHAU"+ this.state.HDYHAU)
                            //     console.log("TUAY"+ this.state.TUAY)
                            //     Axios.post("http://localhost:3001/updateD", {
                            //         email: this.state.email, 
                            //         HDYHAU: this.state.HDYHAU,
                            //         TUAY: this.state.TUAY,
                            //     }).then(()=>{
                            //         console.log("attemp2 complete");
                            //     });
                            // };
                            // updateUser();

                            const getEmailKey = async () => {
                                let res = await Axios.post("http://localhost:3001/getEmailVerificationKey",
                                {
                                    UUID: ReactSession.get("UUID")
                                }).then(res => {
                                    console.log(res);
                                    // console.log(res.data);
                                    
                                    this.setState({ emailVerificationCheck: res.data }, () => {
                                        // console.log("Email Check: "+this.state.emailCheck);
                                        //this.displayQueryData1(); 
                    
                                        if(this.state.emailVerificationCheck > 0){ 
                                            console.log("email found");
                                            console.log("email found and redirect");
                                            sendEmailKey(emailUUID);
                                                this.setState({ redirectTime: "true" }, function(){
                                                    console.log(this.state.redirectTime)});
                                        }
                                        else{
                                            const createEmailConfirmation = () => {
                                                console.log("post attempted3")
                                                console.log("emailVerificationCheck "+this.state.emailVerificationCheck)
                                                console.log("email "+ this.state.email)
                                                console.log("emailUUID "+ emailUUID)
                                                console.log("userUUID "+ ReactSession.get("UUID"))

                                                Axios.post("http://localhost:3001/createEmailConfirmation", {
                                                    UUID: ReactSession.get("UUID"),
                                                    email: this.state.email, 
                                                    VKey: emailUUID,
                                                }).then(()=>{
                                                    sendEmailKey(emailUUID);
                                                    console.log("success and redirect");
                                                    this.setState({ redirectTime: "true" }, function(){
                                                        console.log(this.state.redirectTime)});
                                                });
                                            };
                                            createEmailConfirmation();
                                        }
                                    });
                                }); 
                               
                            }
                        }
                        else{
                            console.log("create needed but previous step needed")
                            //Perform intended data parse here / redirect
                            // const addUser = () => {
                            //     Axios.post("http://localhost:3001/createB", {
                            //         email: this.state.email, 
                            //         Student: this.state.FirstNameStudent,
                            //         LastNameStudent: this.state.LastNameStudent,
                            //         territory: this.state.Residency,
                            //         primaryphone: this.state.PrimaryPhoneNumber,
                            //         StudentAge: this.state.StudentAge,
                            //     }).then(()=>{
                            //         console.log("success and redirect");
                            //         this.setState({ redirectTime: "true" }, function(){
                            //             console.log(this.state.redirectTime)});
                            //     });
                            // };
                            // addUser();
                            //End data parse here / redirect
                        }
                        const updateStep = () => {
                            Axios.post("http://localhost:3001/updateLoginStep", {
                                email: this.state.email, 
                                step:"4-4"
                            }).then(()=>{
                                //console.log("success and redirect");
                                this.setState({ redirectTime: "true" }, function(){
                                    //console.log(this.state.redirectTime)
                                });
                            });
                        };
                        const sendEmailKey = async (VKeyUUID) => {
                            console.log("Email Send starting");
                            console.log("passing values1: "+ ReactSession.get("email") + " & "+ VKeyUUID)
                            let res = await Axios.post("http://localhost:3001/emailTestA", 
                            {
                                email: ReactSession.get("email"), 
                                Key: VKeyUUID,
                            }).then(res => {
                                // console.log("Email Sent 1");
                                // this.setState({redirectTime: true });         
                                updateStep();
           
                            });
                        }
                        console.log("waited for data value")
                    }); 
                });
            }
            getCourseDataImproved();
        }
        else{
            console.log("else hit");
            const getEmailKey2 = async () => {
                let res = await Axios.post("http://localhost:3001/getEmailVerificationKey",
                {
                    email: ReactSession.get("email")
                }).then(res => {
                    console.log(res);
                    // console.log(res.data);

                    this.setState({ emailVerificationCheck: res.data }, () => {
                        // console.log("Email Check: "+this.state.emailCheck);
                        //this.displayQueryData1(); 
    
                        if(this.state.emailVerificationCheck > 0){ 
                            console.log("email found");
                            console.log("email found and redirect");
                                this.setState({ redirectTime: "true" }, function(){
                                    console.log(this.state.redirectTime)});
                        }
                        else{
                            const createEmailConfirmation2 = () => {
                                console.log("post attempted4")
                                console.log("emailVerificationCheck "+this.state.emailVerificationCheck)
                                console.log("email "+ ReactSession.get("email"))
                                console.log("emailUUID "+ emailUUID)
                                console.log("userUUID "+ ReactSession.get("UUID"))

                                Axios.post("http://localhost:3001/createEmailConfirmation", {
                                    UUID: ReactSession.get("UUID"),
                                    email: ReactSession.get("email"),
                                    VKey: emailUUID,
                                }).then(()=>{
                                    console.log("success and redirect");
                                    this.setState({ redirectTime: "true" }, function(){
                                        console.log(this.state.redirectTime)});
                                    sendEmailKey(emailUUID);

                                });
                            };
                            createEmailConfirmation2();
                        }
                        //  SEND VERIFICATION EMAIL REQUIRED --------
                        const sendEmailKey = async (VKeyUUID) => {
                            console.log("Email Send starting");
                            console.log("passing values 2: "+ ReactSession.get("email") + " & "+ VKeyUUID)
                            let res = await Axios.post("http://localhost:3001/emailTestA", 
                            {
                                email: ReactSession.get("email"), 
                                Key: VKeyUUID,
                            }).then(res => {
                                console.log("Email Sent 2");
                                this.setState({redirectTime: true });                    
                            });
                        }
                    });
                }); 
               
            }
            getEmailKey2();
        }
    };

    backButtonClickCheck=(event)=>{
        console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            console.log(this.state.redirectBack)});
    }
    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectBackVar = this.state.redirectBack;

        return (

            <div className="centered">
                {/* {this.getCourseList()} */}
                {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State HDYHAU " + this.state.HDYHAU)}
                {console.log("State TUAY " + this.state.TUAY)}        

                {console.log("Session UUID " + ReactSession.get("UUID"))}
                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session HDYHAU " + ReactSession.get("HDYHAU"))}
                {console.log("Session TUAY " + ReactSession.get("TUAY"))}
                
                {redirectTimeVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/StudentSignUpAS5" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/StudentSignUpAS3" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}

                <Card>

                    <Card.Body 
                    style = {{width:800}}
                    >
                    <h2>
                        Student Parent Guardian Registration       
                        </h2>
                        <br></br>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formFirstNameStudent">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorFirstNameStudent}}
                            >
                                How did you hear about us?
                            </Form.Label>    
                            <Form.Label 
                            Style="font-style: italic; color:#4B5563"
                            > (Optional)</Form.Label>
                            <br></br>
                            <textarea 
                            className='form-control'
                            style={{ height:"160px"}} 
                            type="" placeholder="" 
                            defaultValue={ReactSession.get("HDYHAU")!== undefined ? (ReactSession.get("HDYHAU")):(this.state.value)}
                            onChange={(event) => this.setState({HDYHAU: event.target.value}
                                ,function(){
                                ReactSession.set("HDYHAU", this.state.HDYHAU);
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorLastNameStudent}}
                            >
                            Tell us more information about the student such as their favorite subjects or what they want to be when they grow up. This will help us match your student with the right tutor. If you have any additional academic needs or requirements, please let us know here.
                            <span
                            Style="font-style: italic; color:#4B5563"
                            > (Optional)</span> 
                            </Form.Label>    
                            
                            <br></br>
                            <textarea 
                            className='form-control'
                            style={{ height:"160px"}} 
                            type="text" placeholder="" 
                            defaultValue={ReactSession.get("TUAY")!== undefined ? (ReactSession.get("TUAY")):(this.state.value)}
                             onChange={(event) => this.setState({TUAY: event.target.value},function(){
                                ReactSession.set("TUAY", this.state.TUAY);
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row>
                        
                        <Row>
                            <Col>

                            <Button variant="primary" value="Next" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col style={{ textAlign: 'center'}}>
                            4/4
                            </Col>
                            <Col style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
    
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
