import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

// import StudentSignUpAS3 from "./StudentSignUpAS3";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

function TheHooks(){
    const [dark, setDark] = React.useState(localStorage.getItem('dark-mode') === 'true');

    React.useEffect(() => {
      localStorage.setItem('dark-mode', dark);
    }, [dark]);
  
    const toggleDarkMode = () => {
      setDark(!dark);
    };
  
    return (
      <div className={`app-wrapper ${dark ? 'dark' : ''}`}>
       <button className="dark-mode-toggle" onClick={toggleDarkMode}>
         <img src="https://felixgerschau.com/logos/lightbulb.svg" height="100px" width="100px"/>
         <h2>Click me!</h2>
       </button>
      </div>
    );
}


class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            TutorFirstName: "" ,
            TutorLastName:"",
            PrimaryPhoneNumber:"",
            checkPrimaryPhoneNumber:"false",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"#ced4da",
            textMatchColorFirstName:"#ced4da",
            textMatchColorLastName:"#ced4da",
            textMatchColorPP:"#ced4da",
            textMatchColorProf:"#ced4da",
            emailValid:"",
            tcChecked:"",
            ppChecked:"",
            profChecked:"",
            redirectTime:"",
            Residency:"",
            UUID:"",
            TutorCollege:"",
            TutorFos:"",
            TutorCollegeCheck:"false",
            ProfessionField:"",
            TutorProfession:"",
            TutorProfessionCheck:"false",
            redirectToBeginning:"",
            redirectBack:"",

            professionCssLanguage:"",
            professionWarning:"",
        };
    };

    async componentDidMount() {
        // window.addEventListener('load', this.getCourseList);
        // this.getCourseList();
        console.log("Component Mount occured");
        if(ReactSession.get("email") == "" || ReactSession.get("email")==undefined)
        {
            console.log("Email Check Failed");
            this.setState({ redirectToBeginning: "true" })
        }
       if (this.state.TutorFirstName != undefined && this.state.TutorFirstName != null && this.state.TutorFirstName != ""){
            console.log("tutor first name passed");
        }
        else{
            this.setState({ TutorFirstName:  ReactSession.get("TutorFirstName") })
        }
    //-----
        if (this.state.TutorLastName != undefined && this.state.TutorLastName != null && this.state.TutorLastName != ""){
        console.log("tutor last name passed");
        }
        else{
            this.setState({ TutorLastName:  ReactSession.get("TutorLastName") })
        }
    //-----
        if  (this.state.PrimaryPhoneNumber !== undefined && this.state.PrimaryPhoneNumber !== null && this.state.PrimaryPhoneNumber !== "") {
            console.log("PrimaryPhoneNumber passed");
        }
        else{
            this.setState({ PrimaryPhoneNumber:  ReactSession.get("PrimaryPhoneNumber") })
        }
    //-----
        if  (this.state.checkPrimaryPhoneNumber !=  "false" ){
            console.log("checkPrimaryPhoneNumber passed");
        }
        else{
            this.setState({ checkPrimaryPhoneNumber:  ReactSession.get("checkPrimaryPhoneNumber") })

        }
    //-----
        if  (this.state.SecondaryPhoneNumber !== undefined && this.state.SecondaryPhoneNumber !== null && this.state.SecondaryPhoneNumber !== "") {
            console.log("SecondaryPhoneNumber passed");
        }
        else{
            this.setState({ SecondaryPhoneNumber:  ReactSession.get("SecondaryPhoneNumber") })
        }
    //-----
        if (this.state.Residency !== undefined && this.state.Residency !== null && this.state.Residency !== ""){
            console.log("Residency passed");
        }
        else{
            this.setState({ Residency:  ReactSession.get("Residency") })
        }
    //-----
        if ((this.state.TutorCollegeCheck != "false") &&  (this.state.TutorProfessionCheck != "false" )){
            console.log("TutorCollegeCheck passed");
        }
        else{
            this.setState({ TutorCollegeCheck:  ReactSession.get("TutorCollegeCheck") })
        }
    //-----
        if (this.state.TutorCollege !== undefined && this.state.TutorCollege !== null && this.state.TutorCollege !== ""){
            console.log("TutorCollege passed");
        }
        else{
            this.setState({ TutorCollege:  ReactSession.get("TutorCollege") })
        }
    //-----
        if (this.state.TutorFos != undefined && this.state.TutorFos != null && this.state.TutorFos != ""){
            console.log("TutorFos passed");
        }
        else{
            this.setState({ TutorFos:  ReactSession.get("TutorFos") })
        }
    //-----
        if (this.state.ProfessionField !== undefined && this.state.ProfessionField !== null && this.state.ProfessionField !== ""){
            console.log("ProfessionField passed");
        }
        else{
            this.setState({ ProfessionField:  ReactSession.get("ProfessionField") })
        }
    //-----
        if (this.state.TutorProfession !== undefined && this.state.TutorProfession !== null && this.state.TutorProfession !== ""){
            console.log("TutorFos passed");
        }
        else{
            this.setState({ TutorProfession:  ReactSession.get("TutorProfession") })
        }
    //-----
        if ((this.state.TutorProfessionCheck !== "false") &&  (this.state.TutorProfessionCheck !== "false" )){
            console.log("TutorProfessionCheck passed");
        }
        else{
            this.setState({ TutorProfessionCheck:  ReactSession.get("TutorProfessionCheck") })
        }
        // this.checkCollegeVsProfession();
        // this.nextButtonClickCheckPre();
        setTimeout(function() { //Start the timer
            this.checkCollegeVsProfession(); //After 1 second, do this
        }.bind(this), 1000)
    }

    checkValidation=(event)=>{

        console.log("CheckValidation Invoked");

        const confirmPasswordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        const passwordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        console.log('3rd regex '+ confirmPasswordRegex +' & 4th regex '+ passwordRegex) ;
        this.setState({ textMatchColor: "black" })

        // if(passwordRegex.match(confirmPasswordRegex)){
            if( this.state.password === this.state.confirmPassword){
            console.log("Match");
            
            this.setState({ labelMatch: "true" })
            // this.setState({ textMatchColor: "black" })

        }
        else{
            console.log("Nope");

            this.setState({ labelMatch: "false" })
            // this.setState({ textMatchColor: "black" })

        }

    };

    checkPrimaryPhoneNumber=(event)=>{

        console.log("checkPrimaryPhoneNumber Invoked");

        const confirmPasswordRegex = new RegExp("^([0-9]{3})-([0-9]{3})-([0-9]{4})$");
        if(confirmPasswordRegex.test(this.state.PrimaryPhoneNumber)){
            console.log("regexpass");
            this.setState({ checkPrimaryPhoneNumber: "true" })
            ReactSession.set("checkPrimaryPhoneNumber", "true");

        }
        else{
            console.log("regexfail");
            this.setState({ checkPrimaryPhoneNumber: "false" })
            ReactSession.set("checkPrimaryPhoneNumber", "false");

        }

    };
    checkSecondaryPhoneNumber=(event)=>{

        console.log("checkSecondaryPhoneNumber Invoked");

        const confirmPasswordRegex = new RegExp("^([0-9]{3})-([0-9]{3})-([0-9]{4})$");
        if(confirmPasswordRegex.test(this.state.SecondaryPhoneNumber)){
            console.log("regexpass");
            this.setState({ checkSecondaryPhoneNumber: "true" })
        }
        else{
            console.log("regexfail");
            this.setState({ checkSecondaryPhoneNumber: "false" })
        }

    };

    checkCollegeVsProfession=(event)=>{
        //Check if one of the 2x2 options are both filled before being allowed to continue & set this true / false as var 

        //College Check
        if( 
            (this.state.TutorCollege != undefined && this.state.TutorCollege != null && this.state.TutorCollege != "")
            &&
            (this.state.TutorFos != undefined && this.state.TutorFos != null && this.state.TutorFos != "")
        )
        {
            console.log("college info check passed");
            this.setState({ TutorCollegeCheck: "true" })
            ReactSession.set("TutorCollegeCheck", this.state.TutorCollegeCheck);
            this.setState({ profChecked: "true" })

        }
        else if( 
            (this.state.ProfessionField != undefined && this.state.ProfessionField != null && this.state.ProfessionField != "")
            &&
            (this.state.TutorProfession != undefined && this.state.TutorProfession !== null && this.state.TutorProfession != "")
        )
        {
            console.log("profession info check passed");
            this.setState({ TutorProfessionCheck: "true" })
            ReactSession.set("TutorProfessionCheck", this.state.TutorProfessionCheck);
            this.setState({ profChecked: "true" })

        }
        else{
            this.setState({ TutorCollegeCheck: "false" })
            this.setState({ TutorProfessionCheck: "false" })
            ReactSession.set("TutorProfessionCheck", this.state.TutorProfessionCheck);
            this.setState({ profChecked: "N/A" })

        }
    }

    checkFirstName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })

    }
    checkLastName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "black" })
    }

    backButtonClickCheck=(event)=>{
        //console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            console.log(this.state.redirectBack)
        });
    }

    nextButtonClickCheckPre=(event)=>{
        if (this.state.TutorFirstName != undefined && this.state.TutorFirstName != null && this.state.TutorFirstName != ""){
            console.log("tutor first name passed");
        }
        else{
            // this.setState({ TutorFirstName:  ReactSession.get("TutorFirstName") })
        }
    //-----
        if (this.state.TutorLastName != undefined && this.state.TutorLastName != null && this.state.TutorLastName != ""){
        console.log("tutor last name passed");
        }
        else{
            // this.setState({ TutorLastName:  ReactSession.get("TutorLastName") })
        }
    //-----
        if  (this.state.PrimaryPhoneNumber !== undefined || this.state.PrimaryPhoneNumber !== null || this.state.PrimaryPhoneNumber !== "") {
            console.log("PrimaryPhoneNumber passed");
        }
        else{
            // this.setState({ PrimaryPhoneNumber:  ReactSession.get("PrimaryPhoneNumber") })
        }
    //-----
        if  (this.state.checkPrimaryPhoneNumber !==  "false" ){
            console.log("checkPrimaryPhoneNumber passed");
        }
    //-----
        if  (this.state.SecondaryPhoneNumber !== undefined || this.state.SecondaryPhoneNumber !== null || this.state.SecondaryPhoneNumber !== "") {
            console.log("SecondaryPhoneNumber passed");
        }
        else{
            // this.setState({ SecondaryPhoneNumber:  ReactSession.get("SecondaryPhoneNumber") })
        }
    //-----
        if (this.state.Residency !== undefined || this.state.Residency !== null || this.state.Residency !== ""){
            console.log("Residency passed");
        }
        else{
            // this.setState({ Residency:  ReactSession.get("Residency") })
        }
    //-----
        console.log("College Check:"+this.state.TutorCollegeCheck)
        if ((this.state.TutorCollegeCheck != "false") ||  (this.state.TutorProfessionCheck != "false" )){
            console.log("TutorCollegeCheck passed check");
            this.setState({ profChecked: "true" })
        }
        else{
            // this.setState({ TutorCollegeCheck:  ReactSession.get("TutorCollegeCheck") })
            console.log("TutorCollegeCheck failed check");
            this.setState({ profChecked: "N/A" })

        }
    //-----
        if (this.state.TutorCollege !== undefined || this.state.TutorCollege !== null || this.state.TutorCollege !== ""){
            console.log("TutorCollege passed");
        }
        else{
            // this.setState({ TutorCollege:  ReactSession.get("TutorCollege") })
        }
    //-----
        if (this.state.TutorFos != undefined || this.state.TutorFos != null || this.state.TutorFos != ""){
            console.log("TutorFos passed");
            // this.setState({ profChecked: "true" })
        }
        else{
            // this.setState({ TutorFos:  ReactSession.get("TutorFos") })
        }
    //-----
        if (this.state.ProfessionField !== undefined || this.state.ProfessionField !== null || this.state.ProfessionField !== ""){
            console.log("ProfessionField passed");
        }
        else{
            // this.setState({ ProfessionField:  ReactSession.get("ProfessionField") })
        }
    //-----
        if (this.state.TutorProfession !== undefined || this.state.TutorProfession !== null || this.state.TutorProfession !== ""){
            console.log("TutorProfession passed");
        }
        else{
            // this.setState({ TutorProfession:  ReactSession.get("TutorProfession") })
        }
    //-----
        if ((this.state.TutorProfessionCheck !== "false") ||  (this.state.TutorProfessionCheck !== "false" )){
            console.log("TutorProfessionCheck passed");
        }
        else{
            // this.setState({ TutorProfessionCheck:  ReactSession.get("TutorProfessionCheck") })
        }
        this.checkPrimaryPhoneNumber();
        console.log("Before Button Check");
        this.nextButtonClickCheck();
    }

    nextButtonClickCheck=(event)=>{

        //First Name Check Color
        if (this.state.TutorFirstName === undefined || this.state.TutorFirstName === null || this.state.TutorFirstName === ""){
            this.changeTextColorRedFirstName();
        }
        if (this.state.TutorFirstName !== undefined && this.state.TutorFirstName !== null && this.state.TutorFirstName !== ""){
            this.changeTextColorGreenFirstName();
        }
        //Last Name Check Color
        if (this.state.TutorLastName === undefined || this.state.TutorLastName === null || this.state.TutorLastName === ""){
            this.changeTextColorRedLastName();
        }
        if (this.state.TutorLastName !== undefined && this.state.TutorLastName !== null && this.state.TutorLastName !== ""){
            this.changeTextColorGreenLastName();    
        }
        //Primary Phone Number Check Color
        if (this.state.checkPrimaryPhoneNumber == "false"){
            this.changeTextColorRedPPN();
        }
        if ( this.state.checkPrimaryPhoneNumber == "true"){
            this.changeTextColorGreenPPN();    
        }
        //Territory or Residency Check Color
        if (this.state.ppChecked === "N/A"){
            this.changeTextColorRedTR();
        }
        if ( this.state.ppChecked !== "N/A"){
            this.changeTextColorGreenTR();    
        }
        //College or Profession Check Color
        if (this.state.profChecked == "N/A"){
            this.changeTextColorRedProf();
        }
        if ( this.state.profChecked != "N/A"){
            this.changeTextColorGreenProf();    
        }


        if( 
            (this.state.TutorFirstName !== undefined && this.state.TutorFirstName !== null && this.state.TutorFirstName !== "")
            && 
            (this.state.TutorLastName !== undefined && this.state.TutorLastName !== null && this.state.TutorLastName !== "")
            && 
            (this.state.PrimaryPhoneNumber !== undefined || this.state.PrimaryPhoneNumber !== null || this.state.PrimaryPhoneNumber !== "")
            &&
            (this.state.checkPrimaryPhoneNumber !==  "false" )
            && 
            (this.state.Residency !== undefined || this.state.Residency !== null || this.state.Residency !== "")
            &&
            ((this.state.TutorCollegeCheck !== "false") ||  (this.state.TutorProfessionCheck !== "false" ))

            // ((this.state.TutorCollegeCheck.trim() !== undefined || this.state.TutorCollegeCheck.trim() !== null || this.state.TutorCollegeCheck.trim() !== " ") ||  (this.state.TutorProfessionCheck.trim() !== undefined || this.state.TutorProfessionCheck.trim() !== null || this.state.TutorProfessionCheck.trim() !== " "))
            ){
            console.log("passed nextButtonCheck");

            this.setState({ email: ReactSession.get("email") })
            // this.changeTextColorGreenEmail();    
            // this.changeTextColorGreenPassword();    
            // this.changeTextColorGreenTC();    
            // this.changeTextColorGreenPP();    

            var SecondaryPhoneNumberMiddle="";
            if(this.state.checkSecondaryPhoneNumber !==  "false" ){
                SecondaryPhoneNumberMiddle=this.state.SecondaryPhoneNumber;
            }
    
            

            const getCourseDataImproved = async () => {
 
                let res = await Axios.post("http://localhost:3001/createBcheck", {
                            email: ReactSession.get("email")
                }).then(res => {
                    console.log(res);
                    console.log(res.data);

                    this.setState({ emailCheck: res.data }, () => {
                        console.log("Email Check: "+this.state.emailCheck);
                        //this.displayQueryData1(); 
    
                        if(this.state.emailCheck > 0){
                            console.log("updated needed")
                            const updateUser = () => {
                                Axios.post("http://localhost:3001/updateTutorB", {
                                    email: this.state.email, 
                                    firstname: this.state.TutorFirstName,
                                    lastname: this.state.TutorLastName,
                                    territory: this.state.Residency,
                                    primaryphone: this.state.PrimaryPhoneNumber,
                                    secondaryphone: SecondaryPhoneNumberMiddle,
                                    UUID: ReactSession.get("UUID"),
                                }).then(()=>{
                                    console.log("success and redirect");
                                    this.setState({ redirectTime: "true" }, function(){
                                        console.log(this.state.redirectTime)});
                                });
                            };
                            updateUser();
                        }
                        else{
                            console.log("create needed")
                            //Perform intended data parse here / redirect
                            const addUser = () => {
                                Axios.post("http://localhost:3001/createTutorB", {
                                    email: this.state.email, 
                                    firstname: this.state.TutorFirstName,
                                    lastname: this.state.TutorLastName,
                                    territory: this.state.Residency,
                                    primaryphone: this.state.PrimaryPhoneNumber,
                                    secondaryphone: SecondaryPhoneNumberMiddle,
                                    UUID: ReactSession.get("UUID"),
                                }).then(()=>{
                                    console.log("success create 1 G");
                                    Axios.post("http://localhost:3001/createTutorB2", {
                                        TutorSchool: this.state.TutorCollege,
                                        TutorFieldOfStudy: this.state.TutorFos,
                                        UUID: ReactSession.get("UUID"),
                                    }).then(()=>{
                                        console.log("success create 2 S");
                                        Axios.post("http://localhost:3001/createTutorB3", {
                                            TutorProfession: this.state.TutorProfession,
                                            UUID: ReactSession.get("UUID"),
                                        }).then(()=>{
                                            console.log("success create 3 J");
                                            Axios.post("http://localhost:3001/createTutorB4", {
                                                Career: this.state.ProfessionField,
                                                HDYHAU: "",
                                                UUID: ReactSession.get("UUID"),
                                            }).then(()=>{
                                                console.log("success create 4 E");
                                                console.log("success and redirect");
                                                this.setState({ redirectTime: "true" }, function(){
                                                    console.log(this.state.redirectTime)});
                                            });
                                        });
                                    });
                                });
                            };
                            addUser();
                            //End data parse here / redirect
                        }
                        console.log("waited for data value")
                    }); 
                });
                // console.log("res"+res)
                // console.log(this.stateArray)
            }
            
            getCourseDataImproved();
        }
        else{
            console.log("Failed Next Button Check")
        }
    };

    changeTextColorRedFirstName=(event)=>{
        this.setState({ textMatchColorFirstName: "red" })
    }
    changeTextColorRedLastName=(event)=>{
        this.setState({ textMatchColorLastName: "red" })
    }
    changeTextColorRedPPN=(event)=>{
        this.setState({ textMatchColorPPN: "red" })
    }
    changeTextColorRedTR=(event)=>{
        this.setState({ textMatchColorTR: "red" })
    }
    changeTextColorRedProf=(event)=>{
        this.setState({ textMatchColorProf: "red" })
        this.setState({ professionCssLanguage: "collegeProfessionBorder" })
        this.setState({ professionWarning: "Please have both College or Profession & Field of Study Filled ...OR... Field & Title of Profession Filled" })
    }
    changeTextColorGreenFirstName=(event)=>{
        this.setState({ textMatchColorFirstName: "green" })
    }
    changeTextColorGreenLastName=(event)=>{
        this.setState({ textMatchColorLastName: "green" })
    }
    changeTextColorGreenPPN=(event)=>{
        this.setState({ textMatchColorPPN: "green" })
    }
    changeTextColorGreenTR=(event)=>{
        this.setState({ textMatchColorTR: "green" })
    }
    changeTextColorGreenProf=(event)=>{
        this.setState({ textMatchColorProf: "green" })
        this.setState({ professionCssLanguage: "collegeProfessionBorder" })
        this.setState({ professionWarning: "" })

    }
    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectToBeginning = this.state.redirectToBeginning;
        const redirectBackVar = this.state.redirectBack;

        return (
            
            
           

            
            <div className="centered">
                {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State TutorFirstName " + this.state.TutorFirstName)}
                {console.log("State TutorLastName " + this.state.TutorLastName)}
                {console.log("State PrimaryPhoneNumber " + this.state.PrimaryPhoneNumber)}
                {console.log("State SecondaryPhoneNumber " + this.state.SecondaryPhoneNumber)}
                {console.log("State Residency " + this.state.Residency)}
                {console.log("State TutorCollege " + this.state.TutorCollege)}
                {console.log("State TutorFos " + this.state.TutorFos)}
                {console.log("State TutorCollegeCheck " + this.state.TutorCollegeCheck)}
                {console.log("State ProfessionField " + this.state.ProfessionField)}
                {console.log("State TutorProfession " + this.state.TutorProfession)}
                {console.log("State TutorProfessionCheck " + this.state.TutorProfessionCheck)}
                {console.log("State profChecked " + this.state.profChecked)}

                


                {/* {console.log("Session UUID " + ReactSession.get("UUID"))}
                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session FirstName " + ReactSession.get("FirstName"))}
                {console.log("Session LastName " + ReactSession.get("LastName"))}
                {console.log("Session PrimaryPhoneNumber " + ReactSession.get("PrimaryPhoneNumber"))}
                {console.log("Session SecondaryPhoneNumber " + ReactSession.get("SecondaryPhoneNumber"))}
                {console.log("Session Residency " + ReactSession.get("Residency"))} */}


                {console.log("email " + ReactSession.get("email"))}
                {redirectTimeVar ? (
                    // console.log("Redirect Time is 1 " + this.state.redirectTime),
                    // <Router>
                    <Routes>
             
                        {/* <Route path="/" element={<StudentSignUpB />} /> */}
                        <Route path="/" element={<Navigate to="/TutorSignUpAS3" replace={true} />} />

                   
                    </Routes>
                    // console.log("Redirect Time is 1 " + this.state.redirectTime)

                ) : (
                    // console.log("Redirect Time is 2 " + this.state.redirectTime)
                    console.log("")

                )}
                {redirectToBeginning ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpA?Status=1" replace={true} />} />
                    </Routes>
                ) : (
                    console.log("")
                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpA" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    //console.log("")
                    <div></div>
                )}

                {/* <span> test </span> */}
                <Card>

                    <Card.Body className="width800">
                    <h2>
                        Tutor Information       
                        </h2>
                        <br></br>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label >First Name</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="" 
                            style={{ borderColor:this.state.textMatchColorFirstName}}
                            defaultValue={ReactSession.get("TutorFirstName")!== undefined ? (ReactSession.get("TutorFirstName")):(this.state.value)}
                            onChange={(event) => this.setState({TutorFirstName: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkFirstName();
                                ReactSession.set("TutorFirstName", this.state.TutorFirstName);

                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label >Last Name</Form.Label>
                            <Form.Control 
                            style={{ borderColor:this.state.textMatchColorLastName}} 
                            type="email" 
                            placeholder="" 
                            defaultValue={ReactSession.get("TutorLastName")!== undefined ? (ReactSession.get("TutorLastName")):(this.state.value)}
                            onChange={(event) => this.setState({TutorLastName: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkLastName();
                                ReactSession.set("TutorLastName", this.state.TutorLastName);
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            // style={{ color:this.state.textMatchColorEmail}}
                            >
                                Primary Phone Number</Form.Label>
                            <Form.Control type="phone" pattern="([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})" maxLength={12} 
                            style={{ borderColor:this.state.textMatchColorPPN}} 
                            defaultValue={ReactSession.get("PrimaryPhoneNumber")!== undefined ? (ReactSession.get("PrimaryPhoneNumber")):(this.state.value)}
                            onKeyPress={(event => {
                                if(!/([0-9]||[-])/.test(event.key)){
                                    event.preventDefault();
                                    this.setState({PrimaryPhoneNumber: event.target.value});
                                    ReactSession.set("PrimaryPhoneNumber", this.state.PrimaryPhoneNumber);

                                }
                            })}
                            
                            onChange={(event) => this.setState({PrimaryPhoneNumber: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkPrimaryPhoneNumber();
                                ReactSession.set("PrimaryPhoneNumber", this.state.PrimaryPhoneNumber);
                            }
                            )}
                            />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            // style={{ color:this.state.textMatchColorEmail}}
                            >
                                Secondary Phone Number</Form.Label>
                            <Form.Control type="phone" pattern="([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})" maxLength={12} 
                            defaultValue={ReactSession.get("SecondaryPhoneNumber")!== undefined ? (ReactSession.get("SecondaryPhoneNumber")):(this.state.value)}
                            onKeyPress={(event => {
                                if(!/([0-9]||[-])/.test(event.key)){
                                    event.preventDefault();
                                    this.setState({SecondaryPhoneNumber: event.target.value});
                                    ReactSession.set("SecondaryPhoneNumber", this.state.SecondaryPhoneNumber);

                                }
                            })}
                            
                            onChange={(event) => this.setState({SecondaryPhoneNumber: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkSecondaryPhoneNumber();
                                ReactSession.set("SecondaryPhoneNumber", this.state.SecondaryPhoneNumber);
                            }
                            )}
                            />
                        </Form.Group>
                        </Col>
                        </Row>
                        {/* <hr>
                        </hr> */}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color:this.state.textMatchColorTR}}>Select your State or Territory of Residence</Form.Label>
                            <Form.Select type="text" placeholder="Enter Residence" 
                            defaultValue={ReactSession.get("Residency")!== undefined ? (ReactSession.get("Residency")):(this.state.value)}
                            onChange={(event) => this.setState({Residency: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                // this.checkResidency()
                                ReactSession.set("Residency", this.state.Residency);
                            }
                            )}>
                                <option disabled value="N/A" >Choose a location of residency</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NY">New York</option>
                                <option value="CT">Connecticut</option>
                                

                            </Form.Select>
                        </Form.Group>
                        <div 
                        // className="collegeProfessionBorder"
                        className={this.state.professionCssLanguage}
                        style={{borderColor: this.state.textMatchColorProf}}>
                            <Row>
                            <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label 
                                // style={{ color:this.state.textMatchColorFirstName}}
                                >
                                    College or University
                                </Form.Label>
                                <Form.Control type="text" placeholder="" 
                                defaultValue={ReactSession.get("TutorCollege")!== undefined ? (ReactSession.get("TutorCollege")):(this.state.value)}
                                onChange={(event) => this.setState({TutorCollege: event.target.value},function(){
                                    ReactSession.set("TutorCollege", this.state.TutorCollege);
                                    // console.log(this.state.email + this.state.password);
                                    this.checkCollegeVsProfession();
                                }
                                )}/>
                            </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label 
                                // style={{ color:this.state.textMatchColorLastName}}
                                >
                                    Field of Study
                                </Form.Label>
                                <Form.Control 
                                // style={{ borderColor:this.state.textMatchColorLastName}} 
                                type="text" placeholder="" 
                                defaultValue={ReactSession.get("TutorFos")!== undefined ? (ReactSession.get("TutorFos")):(this.state.value)}
                                onChange={(event) => this.setState({TutorFos: event.target.value},function(){
                                    // console.log(this.state.email + this.state.password);
                                    this.checkCollegeVsProfession();
                                    ReactSession.set("TutorFos", this.state.TutorFos);
                                }
                                )}/>
                            </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                            <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label 
                                // style={{ color:this.state.textMatchColorTR}}
                                >
                                    Field of profession (select most relevant)
                                </Form.Label>
                                <Form.Select type="text" placeholder="Enter Profession" 
                                defaultValue={ReactSession.get("ProfessionField")!== undefined ? (ReactSession.get("ProfessionField")):(this.state.value)}
                                onChange={(event) => this.setState({ProfessionField: event.target.value},function(){
                                    // console.log(this.state.email + this.state.password);
                                    this.checkCollegeVsProfession();
                                    ReactSession.set("ProfessionField", this.state.ProfessionField);
                                }
                                )}>
                                    <option value="N/A" >N/A (No Profession)</option>
                                    <option value="Architecture and Engineering">Architecture and Engineering</option>
                                    <option value="Arts, culture and entertainment">Arts, culture and entertainment</option>
                                    <option value="Business, management and administration">Business, management and administration</option>
                                    

                                </Form.Select>
                            </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label 
                                // style={{ color:this.state.textMatchColorLastName}}
                                >
                                    Title of profession
                                    </Form.Label>
                                <Form.Control 
                                // style={{ borderColor:this.state.textMatchColorLastName}} 
                                type="text" placeholder="" 
                                defaultValue={ReactSession.get("TutorProfession")!== undefined ? (ReactSession.get("TutorProfession")):(this.state.value)}
                                onChange={(event) => this.setState({TutorProfession: event.target.value},function(){
                                    // console.log(this.state.email + this.state.password);
                                    this.checkCollegeVsProfession();
                                    ReactSession.set("TutorProfession", this.state.TutorProfession);
                                }
                                )}/>
                            </Form.Group>
                            </Col>
                            </Row>
                        </div>
                        <span Style="font-style:italic; color:gray">
                            {this.state.professionWarning}
                        </span>
                        <Row>
                            <Col>

                            <Button variant="primary" value="Next" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col style={{ textAlign: 'center'}}>
                            2/4
                            </Col>
                            <Col style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheckPre()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
    
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
