import React from "react";
import { useState } from "react";

import Axios from 'axios'
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import './components/Login.css';
// import LoggedInTutor from './components/LandingPage_TutorComponent.js';
// import Footer from './components/Footer.jsx';
import PortalNavBar from './components/PortalNavBar';
import SessionComponent from './SessionComponent';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>
ReactSession.setStoreType("localStorage");




// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');

}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

export const sessionTest = () => {
    console.log(ReactSession.get("username"));
    console.log(ReactSession.get("roles"));
    console.log(userRolesForPageItems);

}

function Login() {

    ReactSession.setStoreType("localStorage");

    const [currentPassword, setcurrentPassword] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [confirmNewPassword, setconfirmNewPassword] = useState('');
    const [passwordChangeStatus, setpasswordChangeStatus] = useState('');
    const [deleteStatus, setdeleteStatus] = useState('');
    const [deleteStatusMessage, setdeleteStatusMessage] = useState('');
    const [deleteStatusMessageColor, setdeleteStatusMessageColor] = useState('');
    const [deleteStatusMessageButton, setdeleteStatusMessageButton] = useState(false);


    const resetFunc = () => {

        const passwordCheck = async () => {
            console.log("Sending: "+ReactSession.get("username")+" and "+ currentPassword)
            let res = await Axios.post("http://localhost:3001/loginA", {
                        email: ReactSession.get("username"),
                        password: currentPassword
                    }).then(res => {
                        console.log("password check result:")
                        console.log(res);

                        if(res.data[0].UUID.length > 0){
                            if(newPassword == confirmNewPassword)
                            {
                                console.log("passwords match")
                                if(newPassword.length >= 10){
                                    console.log("password longer than 9 chars")
                                    //Minimum ten characters, at least one uppercase letter, one lowercase letter, one number and one special character:
                                    // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$"
                                    // double backslash due to char escape below:
                                    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{10,}$");
                                    console.log(newPassword);
                                    if(passwordRegex.test(newPassword))
                                    {
                                        console.log("Regex True");
                                        
                                        const passwordUpdate = async () => {
                                            console.log("Sending: "+ReactSession.get("username")+" and "+ currentPassword)
                                            let res = await Axios.post("http://localhost:3001/updatePassword", {
                                                    email: ReactSession.get("username"),
                                                    password: newPassword
                                                }).then(res => {
                                                    console.log("password update result:")
                                                    console.log(res);
                                                });
                                            }
                                        passwordUpdate()
                                    }
                                    else{
                                        console.log("Regex False");
                                        setpasswordChangeStatus("Passwords match, are long enough, BUT complexity is not reached.")

                                    }
                                   


                                }
                                else{
                                    console.log("password not long enough")
                                    setpasswordChangeStatus("Passwords match but aren't long enough.")
                                }
                            }
                            else{
                                console.log("passwords do not match")
                                setpasswordChangeStatus("Passwords do not match.")
                            }
                        }
                        else{
                            setpasswordChangeStatus("Incorrect current password.")
                        }
                    });
        }
        passwordCheck();        
        
    };

    const deleteFunc = () => {
        if(deleteStatus){
            console.log("delete true")
            const deleteEmailSend = async () => {
                console.log("Sending: "+ReactSession.get("username")+" and "+ currentPassword)
                let res = await Axios.post("http://localhost:3001/emailRequestDeletion", {
                        email: ReactSession.get("username"),
                    }).then(res => {
                        console.log("Delete Email Request Complete")
                        setdeleteStatusMessage("Delete Email Request Complete.");
                        setdeleteStatusMessageColor("Green");
                        setdeleteStatusMessageButton(true);
                });
            }
            deleteEmailSend();
        }
        else{
            console.log("delete false")
            setdeleteStatusMessage("Please check off the checkbox to confirm account deletion.");
            setdeleteStatusMessageColor("Red");
            setdeleteStatusMessageButton(false);

        }
    }

// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');
}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

    // const navigate = useNavigate();

   return(

        <div className="d-flex blueishBackground">
               <PortalNavBar/>
               <div className="flex-row flex-wrap fullWidth">
                    <div className="headerPadding indentStandardPadding">
                        <h3>
                            Account Settings
                        </h3>
                    </div>
                    <div className="indentStandardPadding">
                    <Card className="fullWidth">
                        <Card.Body>
                            <h4>
                                Reset Password
                            </h4>
                            <br></br>
                            <span>
                                <Form.Group className="mb-3" controlId="formCurrentPassword">
                                    <Form.Label>Enter Current Password</Form.Label>
                                    <Form.Control type="password" placeholder="" onChange={(e)=>{setcurrentPassword(e.target.value)}}/>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group className="mb-3" controlId="formNewPassword">
                                    <Form.Label>Enter New Password</Form.Label>
                                    <Form.Control type="password" placeholder="" onChange={(e)=>{setnewPassword(e.target.value)}}/>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group className="mb-3" controlId="formConfirmNewPassword">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control type="password" placeholder="" onChange={(e)=>{setconfirmNewPassword(e.target.value)}}/>
                                </Form.Group>
                            </span>
                            <span Style="color:red">
                            {passwordChangeStatus}
                                </span>
                            <div className="right">
                                <Button variant="primary" onClick={resetFunc}>Reset</Button>
                            </div>   
                        </Card.Body>
                    </Card>
                    </div>
                    <br></br>
                    <div className="indentStandardPadding">
                    <Card className="fullWidth">
                        <Card.Body>
                            <h4>
                                Request Account & Data Deletion
                            </h4>
                            <br></br>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <br></br>
                                <input type="checkbox" value="Delete" name="DeleteRequest" 
                                onChange={(e)=>{setdeleteStatus(e.target.checked)}}

                                /> I confirm I am requesting to have all my data, including my uploaded files, account settings, and account itself to be deleted. &nbsp;
                            </Form.Group>
                            <span Style={"color: "+deleteStatusMessageColor}>
                            {deleteStatusMessage}
                            </span>
                            <div className="right">
                                <Button variant="primary" onClick={deleteFunc}
                                disabled={deleteStatusMessageButton}
                                >Delete</Button>
                            </div>   
                        </Card.Body>
                    </Card>
                    </div>
                    

                   
            {(() => {
                if (userRolesForPageItems.includes('Parent') || userRolesForPageItems.includes('Student') ) {
                return (
                    // <div>someCase</div>
                    // <LoggedInAdmin/>
                    <LoggedInParent/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('Tutor')) {
                return (
                    // <div>someCase</div>
                    // <LoggedInAdmin/>
                    // <LoggedInTutor/>
                    <div>
                         Tutor Specific Item Goes Here
                        </div>
                )
                }  
            })()}
             {(() => {
                if (userRolesForPageItems.includes('Manager')) {
                return (
                    // <div>someCase</div>
                    <LoggedInManager/>
                    // <LoggedInTutor/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('SuperAdmin')) {
                return (
                    // <div>someCase</div>
                    <LoggedInAdmin/>
                    // <LoggedInTutor/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('SuperDuperAdmin')) {
                return (
                    // <div>otherCase</div>
                    <LoggedInSuperDuperAdmin/>
                )
                } 
                // else {
                // return (
                //     <div>catch all
                //         <Button variant="primary" onClick={sessionTest}>SessionTest</Button>
                //     </div>
                                        

                // )
                // }
            })()}
            </div>  
        </div>
        
   )
}
function LoggedInParent() {
    return ( <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for a Parent                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

function LoggedInManager() {
    return ( <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for a Manager                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

function LoggedInAdmin() {
    return ( <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for an Admin                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

function LoggedInSuperDuperAdmin() {
    return (
        <React.Fragment>
        {/* <div className="centered"> */}
            <Card>
                <Card.Body>
                    This is the landing page for an Super Duper Admin                
                    <br></br>
                    
                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}
                </Card.Body>
            </Card>
        {/* </div> */}
        </React.Fragment>
    )
}

export default Login; 
