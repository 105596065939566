import React from "react";
import './PublicNavBar.css'
import LogoutComponent from "./LogoutComponent";

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';


import { ReactSession } from 'react-client-session';

import { Icon } from '@iconify/react';
import logo from "../MediaAssets/LogoFull.png"

import './PortalNavBar.css';


ReactSession.setStoreType("localStorage");
// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');
}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 


export const sessionTest = () => {
    console.log(ReactSession.get("username"));
    console.log(userRolesForPageItems);

}


const PortalNavBar = () => {
    ReactSession.setStoreType("localStorage");
// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');
}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 
    return (
        <Nav className="flex-column" Style="height:100vh; background:white">
            <img src={logo} Style="width:250px;"/>
            <div Style="padding-left:2rem">

            <Nav.Link href="/LandingPage" Style=" padding: 0;  color: #353740;">
                <div Style="font-size:30px;color: #353740;display:flex">
                    <Icon icon="ant-design:home-outlined" />    
                    <span Style="display:flex;font-size: 20px;align-items: center;/* text-align: center; */"> 
                    &nbsp; Home
                    </span>
                </div>
            </Nav.Link>

            <div Style="font-size:30px;color: #353740;display:flex">
            <Dropdown Style=" padding: 0;  color: #353740;">
                
            <Dropdown.Toggle id="CustomDropDownButtonProfile" variant="btn-link" drop="end" key="end" Style="padding:0px; font-size:30px; display:flex">
            {/* <DropdownButton  id="CustomDropDownButtonProfile" variant="btn-link" drop="end" key="end" Style="padding:0px; font-size:30px; display:flex"> */}
            <Icon icon="akar-icons:bell" />
            <span Style="display:flex;font-size: 20px;align-items: center;/* text-align: center; */"> 
                    &nbsp; Notifications
            </span>
            </Dropdown.Toggle>
            {/* </DropdownButton> */}
            <Dropdown.Menu>
                {/* <Dropdown.Item href="/EditProfile">Edit Profile</Dropdown.Item> */}
                {/* <Dropdown.Item href="/AccountSettings">Account Settings</Dropdown.Item> */}
                {/* <Dropdown.Item href="/">Sign Out</Dropdown.Item> */}
                {/* <Dropdown.Item><LogoutComponent/></Dropdown.Item> */}
                <Dropdown.Item> No Notifications </Dropdown.Item>
                
            </Dropdown.Menu>
            </Dropdown>
            </div>

            <div Style="font-size:30px;color: #353740;display:flex">
            <Dropdown Style=" padding: 0;  color: #353740;">
                
            <Dropdown.Toggle id="CustomDropDownButtonProfile" variant="btn-link" drop="start"Style="padding:0px; font-size:30px; display:flex">
            <Icon icon="gg:profile" /> 
            <span Style="display:flex;font-size: 20px;align-items: center;/* text-align: center; */"> 
                    &nbsp; Settings
            </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="/EditProfile">Edit Profile</Dropdown.Item>
                <Dropdown.Item href="/AccountSettings">Account Settings</Dropdown.Item>
                {/* <Dropdown.Item href="/">Sign Out</Dropdown.Item> */}
                <Dropdown.Item><LogoutComponent/></Dropdown.Item>

                
            </Dropdown.Menu>
            </Dropdown>
            <br></br>
            </div>


            {(() => {
                if (userRolesForPageItems.includes('Tutor')) {
                return (
                    <TutorLinks/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('SuperAdmin')) {
                return (
                    <SuperAdminLinks/>
                )
                }  
            })()}
            {(() => {
                if (userRolesForPageItems.includes('SuperDuperAdmin')) {
                return (
                    <SuperDuperAdminLinks/>
                )
                }  
            })()}
            {/* <LogoutComponent/> */}
            </div>
        </Nav>
    )
}
function TutorLinks() {
    return ( 
    <React.Fragment>
                    {/* <div>Tutor Links</div> */}

                    <Nav.Link href="/SessionLog" Style=" padding: 0;  color: #353740;">
                        <div Style="font-size:30px;color: #353740;display:flex">
                        <Icon icon="icon-park-outline:log" />
                            <span Style="display:flex;font-size: 20px;align-items: center;/* text-align: center; */"> 
                            &nbsp; Session Log
                            </span>
                        </div>
                    </Nav.Link>
                    {/* <Nav.Link eventKey="link-2">Session Log</Nav.Link> */}
                    {/* <Nav.Link eventKey="link-2">Test Tutor Link 2</Nav.Link> */}
        </React.Fragment>
    )
}
function SuperAdminLinks() {
    return ( 
    <React.Fragment>
                    <div>SuperAdmin Links</div>
                    <Nav.Link eventKey="link-2">Test SuperAdmin Link 1</Nav.Link>
                    <Nav.Link eventKey="link-2">Test SuperAdmin Link 2</Nav.Link>
        </React.Fragment>
    )
}
function SuperDuperAdminLinks() {
    return ( 
    <React.Fragment>
                    <div>SuperDuperAdmin Links</div>
                    <Nav.Link eventKey="link-2">Test SuperDuperAdmin Link 1</Nav.Link>
                    <Nav.Link eventKey="link-2">Test SuperDuperAdmin Link 2</Nav.Link>
        </React.Fragment>
    )
}

export default PortalNavBar
