import React, { useSyncExternalStore } from "react";
import { useState } from "react";

import Axios from 'axios'

import './components/StudentSignUpB.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';
import DndEx from "./DndEx";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>
    // window.addEventListener("beforeunload", (ev) => 
    // {  
    // ev.preventDefault();
    // return ev.returnValue = 'Are you sure you want to close???????';
    // });
function StudentSignUpB() {

    const [guardianFirstName, setGuardianFirstName] = useState('');
    const [guardianLastName, setGuardianLastName] = useState('');
    const [studentGradeLevel, setStudentGradeLevel] = useState('');

    console.log(studentGradeLevel);

    const [dragAndDropFormData, setdragAndDropFormData] = useState({
        email:"",
        password:"",
        entrycolumn:"entryTest",
        entryColumnPossible:"",
        entryColumnRequested:"",
    })

    console.log(dragAndDropFormData);

    const logformData = () => {
        console.log(dragAndDropFormData);
    }

    // MultiPage Form Data Start
    const [page, setPage] = useState(0);
    const FormTitles = ["Sign Up", "Personal Info", "Other"];

    const PageDisplay = () => {
        if (page === 0){
            return <h4>Hello World A</h4>;
        }
        else if (page === 1){
            return <h4>Hello World B </h4>;
        }
        else if (page === 2){
            return <h4>Hello World C</h4>;
        }
    }
    // MultiPage Form Data End


    const [userList, setUserList] = useState([]);

    const addUser = () => {
        Axios.post("http://localhost:3001/create2", {
            guardianFirstName: guardianFirstName, 
            guardianLastName: guardianLastName,
            studentGradeLevel: studentGradeLevel,
        }).then(()=>{
            console.log("success");
        });
    };

    return (        
        <div className="centeredSomeContent">
            
            <Card>
               
                <Card.Body>
                    <h2>
                    Student / Parent Sign Up                    
                    </h2>
                    <br></br>
                    <span>
                        Signing up with email: questionmarkicon
                    </span>


                    {/* * Student Last Name:

                    * Parent/Guardian First Name:

                    * Parent/Guardian Last Name:

                    * Parent/Guardian Email:

                    * Parent/Guardian Phone Number (Format: 123-456-7890):
                    012-345-6789

                    * Student Grade Level:

                    Kindergarten

                    * Student Age: */}
                    <Form.Group className="mb-3" controlId="formBasicParentGuardianFirstName">
                        <Form.Label>* Parent / Guardian First Name:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Parent / Guardian First Name" onChange={(event) => setGuardianFirstName(event.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicParentGuardianLastName">
                        <Form.Label>* Parent / Guardian Last Name:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Parent / Guardian Last Name" onChange={(event) => setGuardianLastName(event.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicParentGuardianPhoneNumber">
                        <Form.Label>* Parent / Guardian Phone Number:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Parent / Guardian Phone Number"/>
                    </Form.Group>
                    {['radio'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                            
                        <Form.Check 
                            label = {<label>Mobile / Cellular Phone Number</label>}
                            name="Agreements"
                            type={type}
                            id={`default-${type}`}/>
                        
                        <Form.Check
                            label = {<label>Landline Phone Number</label>}
                            name="Agreements"
                            type={type}
                            id={`default-${type}`}
                        />

                        </div>
                    ))}
                    <Form.Group className="mb-3" controlId="formBasicStudentFirstName">
                        <Form.Label>* Student First Name:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Student First Name"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicStudentLastName">
                        <Form.Label>* Student Last Name:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Student Last Name"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicStudentGradeLevel">
                        <Form.Label>* Student Grade Level:</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(event) => setStudentGradeLevel(event.target.value)}>
                            <option defaultValue="null" disabled>Select a grade level</option>
                            <option value="0">Kindergarten</option>
                            <option value="1">1st Grade</option>
                            <option value="2">2nd Grade</option>
                            <option value="3">3rd Grade</option>
                            <option value="4">4th Grade</option>
                            <option value="5">5th Grade</option>
                            <option value="6">6th Grade</option>
                            <option value="7">7th Grade</option>
                            <option value="8">8th Grade</option>
                            <option value="9">Freshman (9th Grade)</option>
                            <option value="10">Sophomore (10th Grade)</option>
                            <option value="11">Junior (11th Grade)</option>
                            <option value="12">Senior (12th Grade)</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicStudentAge">
                        <Form.Label>* Student Age:</Form.Label>
                        <Form.Control type="number" placeholder="Enter Student Age" min="3" max="21"/>
                    </Form.Group>
                    
                    <div>
                        {/* <DndEx onSalaryChange={changeEmployeeInfo} salary={employee.Salary} sendUp={sendUpity} /> */}
                        <DndEx dragAndDropFormData={dragAndDropFormData} setdragAndDropFormData={setdragAndDropFormData} />

                        
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicStudentLanguages">
                        <Form.Label>* Student Language:</Form.Label>
                        {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                                
                            <Form.Check 
                                label = {<label>Enlgish</label>}
                                name="Languages"
                                type={type}
                                // id={`default-${type}`}
                                id="English"
                                defaultChecked
                                />
                            
                            <Form.Check
                                label = {<label>Spanish</label>}
                                name="Languages"
                                type={type}
                                id="Spanish"
                            />
                            <Form.Check
                                label = {<label>Mandarin</label>}
                                name="Languages"
                                type={type}
                                id="Mandarin"
                            />

                            </div>
                        ))}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicStudentGradeLevel">
                        <Form.Label>* Select your State or Teritory of Residence:</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option value="New Jersey">New Jersey</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="New York">New York</option>
                            {/* <option selected value="New York">New York</option> */}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCareerInterests">
                        <Form.Label>* Student Career Interests:</Form.Label>
                        {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                                
                            <Form.Check 
                                label = {<label>a</label>}
                                name="Interests"
                                type={type}
                                // id={`default-${type}`}
                                id="a"
                                defaultChecked
                                />
                            
                            <Form.Check
                                label = {<label>b</label>}
                                name="Interests"
                                type={type}
                                id="b"
                            />
                            <Form.Check
                                label = {<label>c</label>}
                                name="Interests"
                                type={type}
                                id="c"
                            />

                            </div>
                        ))}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicHDYHAU">
                        <Form.Label>* HDYHAU:</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="HDYHAU"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicTuay">
                        <Form.Label>* TUAY:</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="TUAY"/>
                    </Form.Group>

                    {/* {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                            
                        <Form.Check 
                            label = {<label>I have read and agree to the <a href='https://theeduproject.net/wp-content/uploads/2020/06/HF-TEP-Terms-and-Conditions-as-of-06-10-20.pdf'> Terms & Conditions</a></label>}
                            name="Agreements"
                            type={type}
                            id={`default-${type}`}/>
                        
                        <Form.Check
                            label = {<label>I have read and agree to the <a href='https://theeduproject.net/wp-content/uploads/2020/06/HF-TEP-Privacy-Policy-as-of-06-10-20.pdf'> Privacy Policy</a></label>}
                            name="Agreements"
                            type={type}
                            id={`default-${type}`}
                        />

                        </div>
                    ))} */}

                    <div className="center">
                        <Button variant="primary" onClick={logformData}>logFormDataPlz</Button>
                    </div>                    

                    <div>
                        <h1>Multi Component in single page test</h1>
                        <h2>{FormTitles[page]}</h2>
                        <div className="multiBodyA"> 
                        {PageDisplay()}
                        </div>
                        <Button variant="primary" 
                        disabled={page == 0} 
                        onClick={()=> {setPage((currPage)=> currPage-1);}}>
                            Previous
                        </Button>
                        <Button variant="primary" 
                        disabled={page == FormTitles.length-1} 
                        onClick={()=> { setPage((currPage)=> currPage+1);}}>
                            Next
                        </Button>

                    </div>
                   
                   <div className="center">
                        <Button variant="primary" onClick={addUser}>Next</Button>
                    </div>                    

                   
                    <div className="center">
                        <br></br>
                        <br></br>
                        <br></br>
                        <span>
                        Back to <a href="Logins">Login</a> / <a href="/">Home</a>
                        </span>
                        <br></br>
                        <br></br>
                    </div>

                </Card.Body>
            </Card>
        </div>

    );
    
}

export default StudentSignUpB; 

