import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import {v4 as uuid} from "uuid";

import S3 from 'react-aws-s3';
import {Buffer} from 'buffer';

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");
Buffer.from('anything','base64');
window.Buffer = window.Buffer || require("buffer").Buffer;


function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            FirstName: "" ,
            LastName:"",
            PrimaryPhoneNumber:"",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"black",
            textMatchColorFirstNameStudent:"black",
            textMatchColorLastNameStudent:"black",
            textMatchColorPP:"black",
            emailValid:"",
            tcChecked:"",
            ppChecked:"",
            redirectTime:"",
            Residency:"",
            FirstNameStudent: "" ,
            LastNameStudent: "" ,
            GradeLevel:0,
            StudentAge:0,
            StudentLanguage:"",
            EnglishCheckbox:false,
            SpanishCheckbox:false,
            HindiCheckbox:false,
            OtherCheckbox:false,
            CurrentlySelectedCourse:"",
            CurrentlySelectedCourseID:0,
            CoursesListA:[{id:1, title:'Ford'}, {id:2, title:'BMW'}, {id:3, title:'Audi'},{id:4, title:'Toyota'}],
            CoursesListB:[],
            HDYHAU:"",
            TUAY:"",
            redirectBack:"",
            emailVerificationCheck:"",


            TranscriptDocIDState:"",
            EmploymentDocIDState:"",
            ResumeDocIDState:"",

            fileoneupload:"",

            documentTextColor:"black",
        };
    };

    async componentDidMount() {
        // if((ReactSession.get("HDYHAU") !== undefined) && (ReactSession.get("HDYHAU") !== null) && (ReactSession.get("HDYHAU") !== "")){
        //     this.setState({HDYHAU: ReactSession.get("HDYHAU") });
        // } 
        // if((ReactSession.get("TUAY") !== undefined) && (ReactSession.get("TUAY") !== null) && (ReactSession.get("TUAY") !== "")){
        //     this.setState({TUAY: ReactSession.get("TUAY") });
        // }
        const createTutorDcheck1 = async () => {
            let res = await Axios.post("http://localhost:3001/createTutorDcheck",
            {
                TutorID: ReactSession.get("UUID"),
                //TranscriptDocID: UUID_temp
            }).then(res => {
                console.log(res);
                // console.log(res.data);
                if(res.data.length > 0){
                    console.log("onload check")
                    if(res.data.TranscriptDocID != "" || res.data.TranscriptDocID != undefined)
                    {
                        this.setState({TranscriptDocIDState: res.data[0].TranscriptDocID })        
                    }
                    if(res.data.EmploymentDocID != "" || res.data.EmploymentDocID != undefined)
                    {
                        this.setState({EmploymentDocIDState: res.data[0].EmploymentDocID})        

                    }
                    if(res.data.ResumeDocID != "" || res.data.ResumeDocID != undefined)
                    {
                        this.setState({ResumeDocIDState: res.data[0].ResumeDocID})        

                    }
                }
            }
            );
        }
        createTutorDcheck1();
    }

    nextButtonClickCheck=(event)=>{

        //Passing Values
        console.log("Passing Values:")
        console.log("TranscriptDocIDState" + this.state.TranscriptDocIDState)
        console.log("EmploymentDocIDState" + this.state.EmploymentDocIDState)          

        if(
            (this.state.TranscriptDocIDState !== undefined && this.state.TranscriptDocIDState !== null && this.state.TranscriptDocIDState !== "")
            ||
            (this.state.EmploymentDocIDState !== undefined && this.state.EmploymentDocIDState !== null && this.state.EmploymentDocIDState !== "")
        ){
            //Allowed to redirect
            this.setState({redirectTime: true })                    
        }
        else{
            //Errors for each
            console.log("Missing either TranscriptDocIDState or EmploymentDocIDState")
            this.setState({documentTextColor: "red"});
        }
    };

    backButtonClickCheck=(event)=>{
        console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            console.log(this.state.redirectBack)});
    }


    testingUpload= async (file,UploadNum) =>{
        if(file != undefined){
            this.setState({documentTextColor: "black"});
            const config = {
                bucketName: 'theeducationproject-testbucket01',
                dirName: 'TutorDocuments', /* optional */
                region: 'us-east-1',
                accessKeyId: 'AKIATCJGNGWSWCM42NEV',
                secretAccessKey: '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',
                s3Url: 'https://theeducationproject-testbucket01.s3.amazonaws.com/', /* optional */
            }

            const config2 = {
                bucketName: 'theeducationproject-testbucket01',
                dirName: 'TutorDocuments', /* optional */
                // region: '',
                accessKeyId: 'AKIATCJGNGWSWCM42NEV',
                secretAccessKey: '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',
                s3Url: 'https://theeducationproject-testbucket01.s3.amazonaws.com/', /* optional */
                Headers:"Access-Control-Allow-Origin",
            }
            Buffer.from('anything','base64');
    
            var UUID_temp= uuid();
    
            const ReactS3Client = new S3(config);
            const ReactS3Client2 = new S3(config2);

            /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */
    
            /* This is optional */
            const newFileName = "JS_"+UUID_temp+"_"+file.name;
            ReactS3Client
                .uploadFile(file, newFileName)
                .then(data => console.log(data))
                .catch(err => console.error(err))
            
              /**
               * {
               *   Response: {
               *     bucket: "myBucket",
               *     key: "image/test-image.jpg",
               *     location: "https://myBucket.s3.amazonaws.com/media/test-file.jpg"
               *   }
               * }
               */
            // });
            console.log("test upload end");
            
            const createTutorDcheck = async () => {
                let res = await Axios.post("http://localhost:3001/createTutorDcheck",
                {
                    TutorID: ReactSession.get("UUID"),
                    //TranscriptDocID: UUID_temp
                }).then(res => {
                    console.log(res);
                    // console.log(res.data);
                    if(res.data.length > 0){
                        //Perform Update
                        console.log("Update action needed");
                        if(UploadNum==0){
                            console.log("Update 0");
                            const updateTutorD1 = async () => {
                                console.log("updateTutorD1 starting");
                                let res = await Axios.post("http://localhost:3001/updateTutorD1",
                                {
                                    TutorID: ReactSession.get("UUID"),
                                    TranscriptDocID: newFileName,
                                }).then(res => {
                                    console.log(res);
                                });
                            }

                            if(res.data[0].TranscriptDocID.length > 0)
                            {
                                
                                console.log("Delete old data")
                                ReactS3Client2
                                .deleteFile(res.data[0].TranscriptDocID)
                                .then(data => console.log(data))
                                .catch(err => console.error(err))

                                updateTutorD1();
                            }
                            else{
                                updateTutorD1();
                            }
                        }else if(UploadNum==1){
                            console.log("Update 1");
                            const updateTutorD2 = async () => {
                                console.log("updateTutorD2 starting");
                                let res = await Axios.post("http://localhost:3001/updateTutorD2",
                                {
                                    TutorID: ReactSession.get("UUID"),
                                    EmploymentDocID: newFileName,
                                }).then(res => {
                                    console.log(res);
                                });
                            }
                            if(res.data[0].EmploymentDocID.length > 0)
                            {
                                
                                console.log("Delete old data")
                                ReactS3Client2
                                .deleteFile(res.data[0].EmploymentDocID)
                                .then(data => console.log(data))
                                .catch(err => console.error(err))

                                updateTutorD2();
                            }
                            else{
                                updateTutorD2();
                            }
                        }else if(UploadNum==2){
                            console.log("Update 2");
                            const updateTutorD3 = async () => {
                                console.log("updateTutorD3 starting");
                                let res = await Axios.post("http://localhost:3001/updateTutorD3",
                                {
                                    TutorID: ReactSession.get("UUID"),
                                    ResumeDocID: newFileName,
                                }).then(res => {
                                    console.log(res);
                                });
                            }
                            if(res.data[0].ResumeDocID.length > 0)
                            {
                                
                                console.log("Delete old data")
                                ReactS3Client2
                                .deleteFile(res.data[0].ResumeDocID)
                                .then(data => console.log(data))
                                .catch(err => console.error(err))

                                updateTutorD3();
                            }
                            else{
                                updateTutorD3();
                            }
                        }
                    }
                    if(res.data.length < 1){
                        //Perform Create
                        console.log("Create action needed");
                        if(UploadNum==0){
                            console.log("Create 0");
                            const createTutorD1 = async () => {
                                console.log("createTutorD1 starting");
                                let res = await Axios.post("http://localhost:3001/createTutorD1",
                                {
                                    TutorID: ReactSession.get("UUID"),
                                    TranscriptDocID: newFileName,
                                }).then(res => {
                                    console.log(res);
                                });
                            }
                        createTutorD1();
                        }else if(UploadNum==1){
                            console.log("Create 1");
                            const createTutorD2 = async () => {
                                console.log("createTutorD2 starting");
                                let res = await Axios.post("http://localhost:3001/createTutorD2",
                                {
                                    TutorID: ReactSession.get("UUID"),
                                    EmploymentDocID: newFileName,
                                }).then(res => {
                                    console.log(res);
                                });
                            }
                        createTutorD2();
                        }else if(UploadNum==2){
                            console.log("Create 2");
                            const createTutorD3 = async () => {
                                console.log("createTutorD3 starting");
                                let res = await Axios.post("http://localhost:3001/createTutorD3",
                                {
                                    TutorID: ReactSession.get("UUID"),
                                    ResumeDocID: newFileName,
                                }).then(res => {
                                    console.log(res);
                                });
                            }
                        createTutorD3();
                        }
                    }
                });
            }
            createTutorDcheck();
        }
        else{
            console.log("no or invalid file selected");
            this.setState({documentTextColor: "red"});
        }
        
    }
    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectBackVar = this.state.redirectBack;

        return (

            <div className="centered">
                {/* {this.getCourseList()} */}
                {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State HDYHAU " + this.state.HDYHAU)}
                {console.log("State TUAY " + this.state.TUAY)}        

                {console.log("State TranscriptDocIDState " + this.state.TranscriptDocIDState)}        


                {console.log("Session UUID " + ReactSession.get("UUID"))}
                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session HDYHAU " + ReactSession.get("HDYHAU"))}
                {console.log("Session TUAY " + ReactSession.get("TUAY"))}
                
                {redirectTimeVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpAS5" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/TutorSignUpAS3" replace={true} />} />
                    </Routes>
                ) : (
                    //Something needs to be done or else error occurs so dummy div
                    <div></div>
                )}

                <Card>

                    <Card.Body 
                    style = {{width:800}}
                    >
                    <h2>
                        Tutor Information       
                        </h2>
                        <br></br>
                        <h5 style={{color: this.state.documentTextColor}}>
                            Please Provide either an Unofficial Transcript or Proof of Employment
                        </h5>
                        
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formFirstNameStudent">

                        <label for="fileToUpload">Upload Unofficial Transcript:</label><br></br>
                        <input type="file" name="fileToUpload" id="fileToUpload" accept=".pdf,.docx,.rtf,.txt,.doc,application/vnd.oasis.opendocument.text,application/pdf,	application/vnd.ms-xpsdocument,application/vnd.ms-word.document.macroEnabled.12" onChange={(event) => this.testingUpload(event.target.files[0], "0")} >
                        </input>
                        <br></br>
                        {this.state.TranscriptDocIDState}
                        <br></br>

                        <label for="fileToUpload1">Upload Proof of Employment:</label><br></br>
                        <input type="file" name="fileToUpload1" id="fileToUpload1" accept=".pdf,.docx,.rtf,.txt,.doc,application/vnd.oasis.opendocument.text,application/pdf,	application/vnd.ms-xpsdocument,application/vnd.ms-word.document.macroEnabled.12" onChange={(event) => this.testingUpload(event.target.files[0], "1")} >
                        </input>
                        <br></br>
                        {this.state.EmploymentDocIDState}
                        <br></br>

                        <label for="fileToUpload2">Upload Resume (Recommended):</label><br></br>
                        <input type="file" name="fileToUpload2" id="fileToUpload2" accept=".pdf,.docx,.rtf,.txt,.doc,application/vnd.oasis.opendocument.text,application/pdf,	application/vnd.ms-xpsdocument,application/vnd.ms-word.document.macroEnabled.12" onChange={(event) => this.testingUpload(event.target.files[0], "2")} >
                        </input>
                        <br></br>
                        {this.state.ResumeDocIDState}
                        <br></br>
                        
                        </Form.Group> 
                        </Col>
                        </Row>

                        {/* <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            //style={{ color:this.state.textMatchColorLastNameStudent}}
                            >
                            Tell us about youself.
                            </Form.Label>    
                            <Form.Label 
                            // style={{ color:this.state.textMatchColorFirstNameStudent}}
                            >
                                (Optional)
                            </Form.Label>   
                            <br></br>
                            <textarea 
                            //style={{ borderColor:this.state.textMatchColorLastNameStudent}} 
                            type="text" placeholder="" 
                                value={ReactSession.get("TUAY")!== undefined ? (ReactSession.get("TUAY")):(this.state.value)}
                             onChange={(event) => this.setState({TUAY: event.target.value},function(){
                                ReactSession.set("TUAY", this.state.TUAY);
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row> */}
                        
                        <Row>
                            <Col>

                            <Button variant="primary" value="Next" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col style={{ textAlign: 'center'}}>
                            4/4
                            </Col>
                            <Col style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
                        {/* <iframe src="https://docs.google.com/gview?url=https://theeducationproject-testbucket01.s3.amazonaws.com/TutorDocuments/JS_00cf842d-aaa5-463c-a174-c73c83cffaf5_7a7e9c1de84a4_BGSUUnofficialTranscript.pdf&embedded=true"
                        width="100%"
                        // height="100%"
                        >
                        </iframe> */}
                        {/* <object data="http://www.africau.edu/images/default/sample.pdf" type="application/pdf" width="100%" height="100%">
                            <p>Alternative text - include a link <a href="http://www.africau.edu/images/default/sample.pdf">to the PDF!</a></p>
                        </object> */}
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
