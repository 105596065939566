import { useState } from 'react'
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// export default 
function Carousel() {
  const [sliderRef, setSliderRef] = useState(null);

  const sliderSettings = {
    speed: 500, // ms
    autoplaySpeed: 5000,
    autoplay: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const hotelCards = [
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/07/texas-icon-150x150.png',
      title: 'Texas',
    },
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/07/ucf-icon-150x150.png',
      title: 'Deluxe Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 80/Day',
      features: [""],
    },
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/06/cornell_icon-3-150x150.png',
      title: 'Cornell',
    },
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/07/umd-icon-150x150.png',
      title: 'UMD',
    },
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/07/uchicago-icon-150x150.png',
      title: 'U Chicago',
    },
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/07/uconn-icon-150x150.png',
      title: 'UCONN',
    },
    {
      imageSrc:
        'https://theeduproject.net/wp-content/uploads/2020/07/toledo-icon-150x150.png',
      title: 'Toledo',
    },
    {
      imageSrc:
      'https://theeduproject.net/wp-content/uploads/2020/07/ucla-icon-150x150.png',
      title: 'UCLA',
    },
    {
      imageSrc:
      'https://theeduproject.net/wp-content/uploads/2020/07/umass-icon-150x150.png',
      title: 'UMASS',
    },
    {
      imageSrc:
      'https://theeduproject.net/wp-content/uploads/2020/07/jhu-icon-150x150.png',
      title: 'John Hopkins',
    },
    {
      imageSrc:
      'https://theeduproject.net/wp-content/uploads/2020/07/colby-icon-150x150.png',
      title: 'Colby',
    },
    {
      imageSrc:
      'https://theeduproject.net/wp-content/uploads/2020/07/usc_icon-150x150.png',
      title: 'USC',
    },
    {
      imageSrc:
      'https://theeduproject.net/wp-content/uploads/2020/07/uhouston-icon-150x150.png',
      title: 'U Houston',
    },
  ]

  return (
    <div className='content'>
      {/* <div className='controls'>
        <button onClick={sliderRef?.slickPrev}>
          <FaChevronLeft />
        </button>
        <button onClick={sliderRef?.slickNext}>
          <FaChevronRight />
        </button>
      </div> */}
      <Slider ref={setSliderRef} {...sliderSettings} >
        {hotelCards.map((card, index) => (
          <div key={index} id='card' >
            <img src={card.imageSrc} alt={card.title} id='card-image' />
            {/* <div className='text-info'> */}
              {/* <div className='card-header'> */}
                {/* <h2>{card.title}</h2> */}
                {/* <span>{card.pricingText}</span> */}
              {/* </div> */}
              {/* <p>{card.description}</p> */}
              {/* <ul>
                {card.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul> */}
            {/* </div> */}
            {/* <button>Buy Now</button> */}
          </div>
        ))}
      </Slider>
    </div>
  )
}

function SimpleSlider() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // padding: 10
    };
    return (
      <div className="greenbackground">
        <h2> TESTIMONIALS</h2>
        <br></br>
        <br></br>
        <Slider {...settings} >
          <div >
            {/* <h3>TESTIMONIALS</h3> */}
            <h5>The Education Project has give my son a chance to continue his school assignments with the help of his tutor. I have a little relief knowing his education is being enhanced while traditional school is on break. I hope to continue this once school resumes as educational reinforcement. The gift that the Education Project is providing is priceless.</h5>
            <h5 id="testimonialRevierStyling">- A Mother</h5>
          </div>
          <div >
            {/* <h3>TESTIMONIALS</h3> */}
            <h5>Through The Education Project I have been able to connect with students across the nation to tutor them and to provide any additional help with subjects like English, Math, and Science during the onset of the new system of remote learning. Being able to volunteer a service to others during this trying time, to facilitate students’ understanding of concepts, and to further their learning even when it seems like their world has turned upside down is truly a rewarding experience.</h5>
            <h5 id="testimonialRevierStyling">- Tutor, UCF</h5>
          </div>
          
        </Slider>
        <br></br>

      </div>
    );
}

export { Carousel, SimpleSlider };
