import React from "react";
import './PublicNavBar.css'
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const PublicNavBar = () => {
    return (
        // <div> Sidebar </div>
        <div className="PublicNavBar ">
            {/* <div className="logo">  */}
            {/* </div> */}
            <Container fluid>
            <Row>
            <Col md="auto">
            <Navbar bg="white" expand="md" className=''>
            {/* <Container> */}
                {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                <img className="imageLogo" src="https://theeduproject.net/wp-content/uploads/2020/06/cropped-edu_logo1-01-e1592194490623-150x150.png" />

                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav"> */}
                <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    
                    <NavDropdown title="About" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Legal</NavDropdown.Item>
                    </NavDropdown>

                    {/* <Nav.Link href="Login">Get Involved</Nav.Link> */}

                    {/* <Nav.Link href="#home">Students</Nav.Link>

                    <NavDropdown title="Tutors" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Volunter" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}

                    <NavDropdown title="Feedback" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link href="#home">FAQ</Nav.Link>

                    <Nav.Link href="#home">Donate</Nav.Link>


                </Nav>
                {/* </Navbar.Collapse> */}

            {/* </Container> */}
            </Navbar>
            </Col>
            <Col >
            <Navbar bg="white" expand="md" className='justify-content-end'>
            <Nav>
                <Nav.Link href="RegisterWhoAreYou" className="halfRemMargin">Get Involved</Nav.Link>
                <Nav.Link href="Login" className="halfRemMargin">Login</Nav.Link>
            </Nav>
                
            </Navbar>
            </Col>
            </Row>
            </Container>

        </div>
    )
}

export default PublicNavBar
