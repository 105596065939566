import React , { Component } from 'react'; 
import { useState } from "react";
import {v4 as uuid} from "uuid";

import { ReactSession } from 'react-client-session';

import Axios from 'axios'

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import StudentSignUpB from "./StudentSignUpB";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, createSearchParams } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>
    ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}
    ReactSession.setStoreType("localStorage");


class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            tutoremail: "",
            tutorpassword:"",
            tutorConfirmPassword:"",
            labelMatch:"",
            textMatchColor:"#ced4da",
            textMatchColorEmail:"#ced4da",
            textMatchColorTC:"black",
            textMatchColorPP:"black",
            tutoremailValid:"",
            tcChecked:"",
            ppChecked:"",
            tgChecked:"",
            redirectTime:"",
            UUID:"",

            labelMatch: "",
            textMatchColor: "",
            passwordCheckStatus: "",

            showPasswordA:"False",
            showPasswordB:"False",
        };
    };
    checkValidation=(event)=>{

        // console.log("CheckValidation Invoked");

        const confirmPasswordRegex = new RegExp("^("+this.state.tutorConfirmPassword+")$");
        const passwordRegex = new RegExp("^("+this.state.tutorConfirmPassword+")$");
        // console.log('3rd regex '+ confirmPasswordRegex +' & 4th regex '+ passwordRegex) ;
        // this.setState({ textMatchColor: "black" })

        // if(passwordRegex.match(confirmPasswordRegex)){
        if( this.state.tutorpassword === this.state.tutorConfirmPassword){

            const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{10,}$");
            if(passwordRegex.test(this.state.tutorpassword))
            {
                // console.log("Regex True");
            
            // console.log("Match");
            
            this.setState({ labelMatch: "true" })
            this.setState({ textMatchColor: "green" })
            this.setState({ passwordCheckStatus: "" })

            }
            else{
                this.setState({ labelMatch: "false" })
                this.setState({ textMatchColor: "red" })
                this.setState({ passwordCheckStatus: "Passwords do not meet criteria." })

            }

        }
        else{
            // console.log("Nope");

            this.setState({ labelMatch: "false" })
            this.setState({ textMatchColor: "red" })
            this.setState({ passwordCheckStatus: "Passwords do not match." })

            // this.setState({ textMatchColor: "black" })

        }

    };
    checkEmailValidation=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "#ced4da" })
        function isValidEmail(tutoremail) {
            return /\S+@\S+\.\S+/.test(tutoremail);
          }
          if (!isValidEmail(this.state.tutoremail)) {
            this.setState({ tutoremailValid: "false" })
            // this.setState({ textMatchColorEmail: "red" })
          } else {
            this.setState({ tutoremailValid: "true" })
            this.setState({ textMatchColorEmail: "green" })
          }

        //   console.log("CheckEmailValidation Invoked "+this.state.tutoremailValid);

    }

    nextButtonClickCheck=(event)=>{

        //Password Check Color
        if (this.state.labelMatch == "false" || this.state.labelMatch ==""){
            this.changeTextColorRedPassword();
        }
        if (this.state.labelMatch == "true"){
            this.changeTextColorGreenPassword();
        }
        //Email Check Color
        if (this.state.tutoremailValid == "false"){
            this.changeTextColorRedEmail();
        }
        if ( this.state.tutoremailValid == "true"){
            this.changeTextColorGreenEmail();    
        }
        //TC Check Color
        if (this.state.tcChecked == false){
            this.changeTextColorRedTC();
        }
        if ( this.state.tcChecked == true){
            this.changeTextColorGreenTC();    
        }
        //PP Check Color
        if (this.state.ppChecked == false){
            this.changeTextColorRedPP();
        }
        if ( this.state.ppChecked == true){
            this.changeTextColorGreenPP();    
        }
        //TG Check Color
        if (this.state.tgChecked == false){
            this.changeTextColorRedTG();
        }
        if ( this.state.tgChecked == true){
            this.changeTextColorGreenTG();    
        }







        if(this.state.labelMatch === "true" && this.state.tutoremailValid === "true"
        && this.state.tcChecked === true && this.state.ppChecked === true && this.state.tgChecked){
            console.log("passed nextButtonCheck");
            this.changeTextColorGreenEmail();    
            this.changeTextColorGreenPassword();    
            this.changeTextColorGreenTC();    
            this.changeTextColorGreenPP();    

            ReactSession.set("tutoremail", this.state.tutoremail);

            var UUID_temp= uuid();

            this.setState({UUID: UUID_temp});
            ReactSession.set("UUID", UUID_temp);
                // ReactSession.set("password", this.state.password);
                

            const checkLoginExists = async () => {
                let res = await Axios.post("http://localhost:3001/getExistingUserLoginCheck", {
                    email: this.state.tutoremail, 
                }).then(res => {
                    // console.log(res);
                    // console.log(res.data);
                    if(res.data < 1){
                        // console.log("create needed");
                        addUser();
                    }
                    else{
                        // console.log("update needed");
                        updateUser();
                    }
                    });
            };
            checkLoginExists();
            

            //Perform intended insert data parse here / redirect var set
                const addUser = () => {
                            Axios.post("http://localhost:3001/createA", {

                                // var emailUUID = uuid();
                                UUID: UUID_temp,
                                email: this.state.tutoremail, 
                                password: this.state.tutorpassword,
                                step:"1-4",
                            }).then(()=>{
                                Axios.post("http://localhost:3001/createAagreementstutor", {

                                // var emailUUID = uuid();
                                UUID: UUID_temp,
                                email: this.state.tutoremail, 
                                }).then(()=>{
                                    ReactSession.set("UUID", UUID_temp);
                                    // console.log("success create and redirect");
                                    this.setState({ redirectTime: "true" }, function(){
                                        // console.log(this.state.redirectTime)
                                    });
                                    });
                                });
                        };
                        // addUser();
            //End insert data parse here / redirect var set
            //Perform intended insert data parse here / redirect var set
            const updateUser = () => {
                // console.log(" update triggered");
                Axios.post("http://localhost:3001/updateA", {
                    UUID: UUID_temp,
                    email: this.state.tutoremail, 
                    password: this.state.tutorpassword,
                }).then(()=>{
                    // console.log("first update success");
                    Axios.post("http://localhost:3001/updateAgreementstutor", {
                    UUID: UUID_temp,
                    email: this.state.tutoremail, 
                    }).then(()=>{
                        ReactSession.set("UUID", UUID_temp);
                        // console.log("second update success and redirect");
                        this.setState({ redirectTime: "true" }, function(){
                            // console.log(this.state.redirectTime)
                        });
                        });
                    });
            };
            // addUser();
//End insert data parse here / redirect var set
        }
    };

    changeTextColorRedPassword=(event)=>{
        this.setState({ textMatchColor: "red" })
    }
    changeTextColorRedEmail=(event)=>{
        this.setState({ textMatchColorEmail: "red" })
    }
    changeTextColorRedTC=(event)=>{
        this.setState({ textMatchColorTC: "red" })
    }
    changeTextColorRedPP=(event)=>{
        this.setState({ textMatchColorPP: "red" })
    }
    changeTextColorRedTG=(event)=>{
        this.setState({ textMatchColorTG: "red" })
    }
    changeTextColorGreenPassword=(event)=>{
        this.setState({ textMatchColor: "green" })
    }
    changeTextColorGreenEmail=(event)=>{
        this.setState({ textMatchColorEmail: "green" })
    }
    changeTextColorGreenTC=(event)=>{
        this.setState({ textMatchColorTC: "green" })
    }
    changeTextColorGreenPP=(event)=>{
        this.setState({ textMatchColorPP: "green" })
    }
    changeTextColorGreenTG=(event)=>{
        this.setState({ textMatchColorTG: "green" })
    }

    toggleVisibilityA=(event)=>{
        this.setState({ showPasswordA: !this.state.showPasswordA})
    }
    toggleVisibilityB=(event)=>{
        this.setState({ showPasswordB: !this.state.showPasswordB})
    }
    
    render(){
        if (this.state.redirectTime === "true") {
        //     // redirect to home if signed up
        //     // return 
        //     console.log("redirected in render");
        //     <BrowserRouter>
        //         <Routes>
        //             {/* <Route path="/" element={<Navigate to="/StudentSignUpB.js" replace={true} />} /> */}
        //             {/* <Route path="/" render={() => <Navigate to="/StudentSignUpB.js" />} /> */}
        //             <Route path="StudentSignUpB" element={<StudentSignUpB />} />
        //         </Routes>
        //     </BrowserRouter>
        // this.setState({redirectTime: 'true'});

      }
      const redirectTimeVar = this.state.redirectTime;
      const styleIB = "display: inline-block";
      const styleColorIB = "color:"+this.state.textMatchColor;

      const showHideVarA = this.state.showPasswordA;
      const showHideVarB = this.state.showPasswordB;

        //   console.log("Redirect Time Var is "+console.log(this.state.redirectTime))

        return (
            
           

            
            <div className="centered">
                {/* {console.log("Redirect Time is " + this.state.redirectTime)} */}
                {redirectTimeVar ? (
                    // console.log("Redirect Time is 1 " + this.state.redirectTime),
                    // <Router>
                    <Routes>
             
                        {/* <Route path="/" element={<StudentSignUpB />} /> */}
                        {/* <Route path="/" element={<Navigate to="/StudentSignUpAS2" replace={true} />} /> */}
                        <Route path="/" element={<Navigate to="/TutorSignUpAS2" state={{ infoId: "testPass" }} replace={true} />} />

                   
                    </Routes>
                    // console.log("Redirect Time is 1 " + this.state.redirectTime)

                ) : (
                    // console.log("Redirect Time is 2 " + this.state.redirectTime)
                    <div> </div>
                )}

                {/* <span> test </span> */}
                <Card>

                    <Card.Body style = {{width:576}}>
                        <h2>
                        Tutor Sign Up                    
                        </h2>
                        <br></br>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label >Email address</Form.Label>
                            <Form.Control 
                            style={{ borderColor:this.state.textMatchColorEmail}}
                            type="email" 
                            placeholder="Enter email"
                            value={this.state.value} 
                            onChange={(event) => this.setState({tutoremail: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkEmailValidation();
                                ReactSession.set("email", this.state.tutoremail);
                            }
                            )}/>
                        </Form.Group>
                        {/* <Form.Group>
                        {this.state.tutoremail}
                        </Form.Group> */}
{/* ------- */}
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label //style={{ color:this.state.textMatchColor}}
                            >Password</Form.Label>
                            <Form.Control 
                            className="inlineBlocker"
                            style={{ borderColor:this.state.textMatchColor}}
                            // Style={{...styleColorIB, ...styleIB}}
                            //,{color:this.state.textMatchColor}
                            // Style={{color:this.state.textMatchColor}}
                            //, color:this.state.textMatchColor}}
                             type={this.state.showPasswordA ? "password" : "text"} placeholder="" value={this.state.value} onChange={(event) => this.setState({tutorpassword: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkValidation()
                            }
                            )}/>
                            <button 
                        Style="background-color: transparent;border-color: transparent;width: 30px;margin-left: -40px;display: inline;position: absolute;padding-top: 10px;"
                        onClick={(event)=>this.toggleVisibilityA()}
                        >
                            {showHideVarA ? (
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx8szPOqFXETWdxhxSD_Bm8QFZIWNwHK82Wg&usqp=CAU"></img>
                            ):(
                            <img Style="max-width: 115%;/* margin-bottom: 100px; */position: relative;top: -7px;left: -2.5px" src="https://cdn-icons-png.flaticon.com/512/4674/4674377.png"></img>
                            )}
                        </button>
                        {/* </Form.Control> */}
                        </Form.Group>
{/* ------- */}
                        {/* <Form.Group>
                        {this.state.tutorpassword}
                        </Form.Group> */}
                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label //style={{ color:this.state.textMatchColor}}
                            >Confirm Password</Form.Label>
                            <Form.Control 
                            className="inlineBlocker"
                            style={{ borderColor:this.state.textMatchColor}}
                             type={this.state.showPasswordB ? "password" : "text"} placeholder="" value={this.state.value} onChange={(event) => this.setState({tutorConfirmPassword: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkValidation()
                            }
                            )}/>
                            <button 
                        Style="background-color: transparent;border-color: transparent;width: 30px;margin-left: -40px;display: inline;position: absolute;padding-top: 10px;"
                        onClick={(event)=>this.toggleVisibilityB()}
                        >
                            {showHideVarB ? (
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx8szPOqFXETWdxhxSD_Bm8QFZIWNwHK82Wg&usqp=CAU"></img>
                            ):(
                            <img Style="max-width: 115%;/* margin-bottom: 100px; */position: relative;top: -7px;left: -2.5px" src="https://cdn-icons-png.flaticon.com/512/4674/4674377.png"></img>
                            )}

                            
                        </button>
                        </Form.Group>
                        <Form.Group>
                        <span Style="font-style:italic; color:gray">
                            {this.state.passwordCheckStatus}
                        </span>
                        </Form.Group>
                        {/* <Form.Group>
                        {this.state.tutorConfirmPassword}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>labelMatch</Form.Label>
                            {this.state.labelMatch}
                        </Form.Group> */}
                        {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                                
                            <Form.Check 
                                label = {<label>I have read and agree to the <a href='https://theeduproject.net/wp-content/uploads/2020/06/HF-TEP-Terms-and-Conditions-as-of-06-10-20.pdf'> Terms & Conditions</a></label>}
                                name="Agreements"
                                type={type}
                                id={`default-${type}`}
                                style={{ color:this.state.textMatchColorTC}}
                                value={this.state.value} onChange={(event) => this.setState({tcChecked: event.target.checked},function(){
                                    // console.log(this.state.tcChecked);
                                    this.setState({ textMatchColorTC: "black" })

                                    // this.checkValidation()
                                }
                                )}
                                />
                            
                            <Form.Check
                                label = {<label>I have read and agree to the <a href='https://theeduproject.net/wp-content/uploads/2020/06/HF-TEP-Privacy-Policy-as-of-06-10-20.pdf'> Privacy Policy</a></label>}
                                name="Agreements"
                                type={type}
                                id={`default-${type}`}
                                style={{ color:this.state.textMatchColorPP}}
                                value={this.state.value} onChange={(event) => this.setState({ppChecked: event.target.checked},function(){
                                    // console.log(this.state.ppChecked);
                                    this.setState({ textMatchColorPP: "black" })

                                    // this.checkValidation()
                                }
                                )}
                            />

                            <Form.Check
                                label = {<label>I have read and agree to the <a href='https://theeduproject.net/wp-content/uploads/2020/06/HF-TEP-Tutor-Responsibilities-Guidelines-as-of-06-10-2020.pdf'> Tutor Guidelines</a></label>}
                                name="Agreements"
                                type={type}
                                id={`default-${type}`}
                                style={{ color:this.state.textMatchColorTG}}
                                value={this.state.value} onChange={(event) => this.setState({tgChecked: event.target.checked},function(){
                                    // console.log(this.state.tgChecked);
                                    this.setState({ textMatchColorTG: "black" })

                                    // this.checkValidation()
                                }
                                )}
                            />  
    
                            </div>
                        ))}
                        
                        <Row>
                            <Col>

                            Back to <a href="Login">Login</a> / <a href="/">Home</a>
                            
                            </Col>
          
                          

                            <Col>
                            1/4
                            </Col>
                            <Col>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
                                 
    
                        
                        
    
                    </Card.Body>
                </Card>
            </div>
    
        );
    } 
        
};


export default StudentSignUpA; 
