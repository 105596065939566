import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {v4 as uuid} from "uuid";

import "./components/DndEx.css";

const itemsFromBackend = [
  { id: uuid(), content: "Biology" },
  { id: uuid(), content: "Chemistry" },
  { id: uuid(), content: "Computer Science" },
  { id: uuid(), content: "English" },
  { id: uuid(), content: "History" },
  { id: uuid(), content: "Geography" },
  { id: uuid(), content: "General Education" },
  { id: uuid(), content: "Mathematics" },
  { id: uuid(), content: "Other Science" },
  { id: uuid(), content: "Physics" },
  { id: "other", content: "Other" }
];

const columnsFromBackend = {
  ["PossibleColumn"]: {
    name: "Possible Courses",
    items: itemsFromBackend
  },
  ["RequestedColumn"]: {
    name: "Requested Courses",
    items: []
  
  }
};


const setColumnsForPass = (columns, dragAndDropFormData) => {
  console.log("columnbuttonclicked");
  dragAndDropFormData.entryColumn = columns;
  dragAndDropFormData.entryColumnPossible = columns.PossibleColumn;
  dragAndDropFormData.entryColumnRequested = columns.RequestedColumn;

}

const onDragEnd = (result, columns, setColumns,dragAndDropFormData) => {

    if (!result.destination) return;
    const { source, destination } = result;
  
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
      dragAndDropFormData.entryColumn = columns;
      dragAndDropFormData.entryColumnPossible = columns.PossibleColumn;
      dragAndDropFormData.entryColumnRequested = columns.RequestedColumn;  
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
      dragAndDropFormData.entryColumn = columns;
      dragAndDropFormData.entryColumnPossible = columns.PossibleColumn;
      dragAndDropFormData.entryColumnRequested = columns.RequestedColumn;  
    }
    console.log(columns); // THIS VALUE NEEDS TO BE PASSED UP
    dragAndDropFormData.entryColumn = columns;
    dragAndDropFormData.entryColumnPossible = columns.PossibleColumn;
    dragAndDropFormData.entryColumnRequested = columns.RequestedColumn;

};


function DndEx( {dragAndDropFormData, setDragAndDropFormData} ) {

  const [toggle,setToggle] = useState( columnsFromBackend );
  const [columns, setColumns] = useState(columnsFromBackend);

  

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns, dragAndDropFormData)} 
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
              key={columnId}
            >
              <h2>{column.name}</h2>
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                          padding: 4,
                          width: 250,
                          minHeight: 500
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      padding: 16,
                                      margin: "0 0 8px 0",
                                      minHeight: "50px",
                                      backgroundColor: snapshot.isDragging
                                        ? "#263B4A"
                                        : "#456C86",
                                      color: "white",
                                      ...provided.draggableProps.style
                                    }}
                                  >
                                    {item.content}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
            
          );

        })}
      </DragDropContext >
      <div onDragEnd={result => onDragEnd(result, columns, setColumns, dragAndDropFormData)} >      </div >
      {/* <button setColumnsForPass={result => setColumnsForPass(columns, dragAndDropFormData)}> */}
            <button  onClick={() => setColumnsForPass(columns, dragAndDropFormData)} >

        UpdateInfo
      </button>
    </div>
        
  );
}

export default DndEx;
