import {Carousel,SimpleSlider} from "./components/Carousel";
// import SimpleSlider from "./components/Carousel";



// import React, { useState } from 'react';
// import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './App.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

// import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';

import Footer from './components/Footer.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointer } from '@fortawesome/free-regular-svg-icons'

import PublicNavBar from './components/PublicNavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

const elementPointerHand = <FontAwesomeIcon icon={faHandPointer} />


const App = () => (
  <span className="main">
    <PublicNavBar/>  
    <span className="doubleImageShowOff" >
      {/* < className="doubleImageShowOffInner"> */}
      <span className="doubleImageShowOffInner">
        <img className="logo1" src="https://theeduproject.net/wp-content/uploads/2020/08/tep-primary.png"  alt="https://theeduproject.net/wp-content/uploads/2020/08/tep-primary.png"/>
        <img className="logo2" src="https://theeduproject.net/wp-content/uploads/2020/06/laptop_vector-1024x1024.png" alt="https://theeduproject.net/wp-content/uploads/2020/06/laptop_vector-1024x1024.png"></img>
      </span>
      {/* </Container> */}
    </span>

    <span className="freeTutoringSpan" >
      <span className="freeTutoringSpanInner">
        <span  className="freeTutoringSpanText">
          <Container fluid className="freeTutoringSpanText2 whiteTexty lineHeight1-6">
            <span className="boldme ProvidingFreeTutoringText"> Providing Free Tutoring </span>
            <br></br>
            <br></br>
            <span> Due to COVID-19, many teachers and students no longer have access to the classroom environment. Students especially are missing the human aspect of learning, and are falling behind at unprecedented rates. </span>
            <br></br>
            <br></br>
            <span className="boldme"> Enter The Education Project. </span>
            <br></br>
            <br></br>
            <span> We’re an entirely volunteer-run organization providing a platform that connects professionals and college students with students in grades K-12. Through different online mediums (e.g. Zoom, WebEx, Google Meets, etc.), students receive a one-on-one education coupled with an irreplaceable mentorship experience. </span>
            <br></br>
            <br></br>
            <span className="boldme"> And yes – 100% free, no strings attached. </span>
            <br></br>
            <br></br>
            <br></br>
            <img src="https://theeduproject.net/wp-content/uploads/2020/06/subjects-e1592268776482.png"  alt=""/>
            <br></br>
            <br></br>

          </Container>
        </span>

      </span>
    </span>

    <Container>
      <Row className="justify-content-md-center boldme ProvidingFreeTutoringText darkBlueText01">
          HOW IT WORKS
      </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={5}>
    <span className="boldme darkBlueText01"> PARENTS AND STUDENTS* </span>
    </Col>
    <Col  sm={2}> </Col>
    <Col sm={4} className="boldme darkBlueText01">  
    270 
    </Col>
  </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={5}>
    <span className="HIWgrayTEXT01"> After you fill out the form, we’ll match you with a suitable teacher. We will reach out via email with your teacher information. From there the tutor will set up the first appointment where they will discuss your requirements, any school curriculum provided, and educational goals. </span>
    </Col>
    <Col  sm={2}> </Col>
    <Col sm={4} className="boldme darkBlueText01">  
     STUDENTS
    </Col>
  </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={5}>
    </Col>
    <Col sm={6} className="HIWgrayTEXT01" >  
    *this form is to be completed by a parent or guardian for students under 18

  </Col>
  </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={6}>
    <Button variant="primary"> 
      <span>Student Sign Up </span>                 
      <FontAwesomeIcon icon={faHandPointer} className="whiteText" />
    </Button>{' '}

    </Col>
    <Col sm={5} > 
    </Col>
  </Row>
  
</Container>

<hr/>
<Container>
    
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={5}>
    <span className="boldme darkBlueText01"> </span>
    </Col>
    <Col  sm={2}> </Col>
    <Col sm={4} className="boldme darkBlueText01">  
    COLLEGE STUDENTS AND PROFESSIONALS 
    </Col>
  </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={5}>
    <span className="HIWgrayTEXT01"> 270 </span>
    </Col>
    <Col  sm={2}> </Col>
    <Col sm={4} className="boldme darkBlueText01">  
    </Col>
  </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={5}>TUTORS
    </Col>
    <Col sm={6} className="HIWgrayTEXT01" >  
    Fill out the tutor form with your information and the areas that you are comfortable tutoring. We require proof regarding your background (unofficial transcript or proof of employment). Once your application gets processed, we’ll set up a virtual orientation to get you up to speed with the do’s and don’ts of this project.
  </Col>
  </Row>
  <Row>
    <Col sm={1}> </Col>
    <Col  sm={8}>
    </Col>
    <Col sm={3} > 
      <Button variant="primary"> 
      <span>Tutor Sign Up </span>                 
      <FontAwesomeIcon icon={faHandPointer} className="whiteText" />
      </Button>{' '}
    </Col>
  </Row>
  
</Container>

<br></br>

<span className="" >
      <span className="">
        <span  className="freeTutoringSpanText">
          <Container fluid className=" lineHeight1-6">
            <span className="boldme ProvidingFreeTutoringText"> Where Our Tutors Come From </span>
            <br></br>
            <Container id="carouselHolder">
                <Carousel />
            </Container>
          </Container>
        </span>
      </span>
    </span>

    <br></br>


    <div id="carouselTextHolder" className="greenBackground ">
      <Container id="carouselText" className="whiteText">
        <br></br>
        <br></br>
        <SimpleSlider/>
      </Container>
    </div>


    <div>
      <br></br>
      <br></br>
      <Container>
      <h1 id="carouselText">
        FAQ
      </h1>
      <br></br>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>So this is really 100% free?</Accordion.Header>
          <Accordion.Body>
          Yes, there is no charge to receive tutoring. This service is free now and will remain free in the future.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>How long will it take to get assigned to a tutor?</Accordion.Header>
          <Accordion.Body>
          Due to the high demand during this time, it takes about a week to assign a tutor. We will reach out to the email you provided. We ask that you respond as soon as you can when the tutor reaches out. If there is no response for 3 days, we will reassign the tutor to another student and you will have to reapply. 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>What are the grade levels of students that you tutor?</Accordion.Header>
          <Accordion.Body>
          We tutor students who are currently enrolled from kindergarten to 12th grade.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>How do you ensure the safety of your students?</Accordion.Header>
          <Accordion.Body>
          <p className="font_8">Our number one commitment is towards the safety and privacy of our students. All of our tutors go through a 2-step verification process. The first step is to verify their background and experience. We review the transcripts for all tutors and employment details for professionals. The second step is the onboarding process, where interview tutors about their background and confirm their experiences.</p><p className="font_8">All data entered into our online form gets stored in a secure online database. We do not ask for the student’s physical address or anything hinting at their location. The student’s application is only shared with their assigned tutor.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>I'm currently in 12th grade and I'm going to college next year. Can I tutor?</Accordion.Header>
          <Accordion.Body>
          Yes, you can tutor. Just provide us with proof of your acceptance into a post-high school program. 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>When was The Education Project started? </Accordion.Header>
          <Accordion.Body>
            This was started on March 23, 2020.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>How can I help? </Accordion.Header>
          <Accordion.Body>
          <ul className="font_2"><li><p className="font_2">Spread the word by letting your community know about this or by posting on social media</p></li><li><p className="font_2"><a href="https://fundraise.humanityfirst.org/campaign/the-education-project/c298161">Donate</a></p></li><li><p className="font_2">Sign up as a tutor</p></li><li><p className="font_2">Like and follow us on Facebook, Instagram, and Twitter</p></li></ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      </Container>
      <br></br>
      <br></br>

    </div>

    <Footer/>


    {/* <Container className="p-3 body">
      <Container className="p-5 mb-4 bg-light rounded-3">
        <h1 className="header">Welcome To React-Bootstrap</h1>
        
        <ExampleToast>
          We now have Toasts
          <span role="img" aria-label="tada" alt="">
            🎉
          </span>
        </ExampleToast>
      </Container>
    </Container> */}
</span>
);


export default App;
