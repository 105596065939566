import React from "react";
import { useState } from "react";

import './components/Login.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

function RegisterWhoAreYou() {


    return (
        
        <div className="centered grayishBackground">
             {/* Style="background:#F1F2F4"> */}
            {/* <span> test </span> */}
            <Card>

                
                {/* <Card.Img variant="top" src="https://img.freepik.com/free-photo/asian-woman-hand-writing-pencil-notebook-coffee-shop-with-vintage-toned_1627-1653.jpg" /> */}

                <Card.Body className="cardWidth2"> 
                     {/* style = {{width:576}}> */}
                    {/* <div Style="{padding:2rem}"> */}
                    <div className="TwoRemPadding">

                        <h2>                    Page Not Found                    </h2>
                        <span> The page you are looking for does not exist...</span>
                        <br></br>
                        <span> This could be caused by the following reasons:</span>
                        <ol >
                            <li>
                                Mispelled the page name.
                            </li>
                            <li>
                                The page has moved or no longer exists.
                            </li>
                            <li>
                                An issue outside your control has occured.
                            </li>
                        </ol>
                        
                        <br></br>
                        
                        {/* <div className="center">
                            <br></br>

                                <Button variant="primary" href="Login" className="fullWidth" >Login</Button>

                            <br></br>
                            <br></br>
                        </div> */}

                        <div className="center">
                            <br></br>
                            <br></br>
                            <span>
                                <a href="/">Return to Homepage</a>
                            </span>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    



                </Card.Body>
            </Card>
        </div>

    );
}

export default RegisterWhoAreYou; 