import React from "react";
import { useState,useEffect,useMemo,setState,getState } from "react";

import Axios from 'axios'
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import './components/Login.css';
import LoggedInTutor from './components/LandingPage_TutorComponent.js';
// import Footer from './components/Footer.jsx';
import PortalNavBar from './components/PortalNavBar';
import SessionComponent from './SessionComponent';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';



import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>
ReactSession.setStoreType("localStorage");
const cachedusers = ReactSession.get("username");
console.log("CachedUser: "+cachedusers);
console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');

}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

export const sessionTest = () => {
    console.log(ReactSession.get("username"));
    console.log(userRolesForPageItems);

}

function TutorOnboardingMain() {

    ReactSession.setStoreType("localStorage");
    // console.log(ReactSession.get("roles"));
    var userRolesForPageItems = '';
    if(ReactSession.get("roles") != null ){
        userRolesForPageItems = ReactSession.get("roles").split(',');
    }
    else{
        userRolesForPageItems = '';
    }

    ReactSession.setStoreType("localStorage");

    // const [userList, setUserList] = useState([]);

    // const getUsers = () => {
    //     Axios.get("http://localhost:3001/users").then((response) => {
    //         // console.log(response);
    //         setUserList(response.data);
    //     })
    // };

   return(

        <div className="d-flex">
               <PortalNavBar/>
               <div className="flex-row flex-wrap">
            
            {(() => {
                if (userRolesForPageItems.includes('Manager')) {
                return (
                    // <div>someCase</div>
                    // <LoggedInAdmin/>
                    <ShowTutorOnboardingData/>
                )
                }
                else{
                    return (
                        <div>Session User: {ReactSession.get("username")} ... Roles: {ReactSession.get("roles")} ...  You are not allowed to view this page... if you believe this is a mistake please reach out to Robert.Townsend@us.humanityfirst.org</div>
                    )
                }  
            })()}
            
            </div>  
        </div>
        
   )
}

// const apitest = Axios.get('http://localhost:3001/users');

class Welcome extends React.Component {

    // state = {
    //     courses:[]
    // }

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       count: 0
    //     };
    //     this.getCourseDataImproved();
    //   }


    constructor() {
        super();
        this.getCourseDataImproved();
        
      }

    //   getCourseData = async () => {
    //       let res = await Axios.get('http://localhost:3001/users') ;
    //       console.log(res)
    //   }

    
      getCourseDataImproved = async () => {
        let res = await Axios.get('http://localhost:3001/TutorOnboardQuery/ids'+cachedusers) ;
        console.log(res)
        console.log(res.data[0])

        this.state = { courses: res.data };
        // this.setState({courses: res.data[0]})
        this.setState({ courses: res.data }, () => {
            console.log(this.state.courses);
            this.displayQueryData1(); 
          }); 
        // console.log(this.stateArray)


    }

    displayQueryData1= () => {
        console.log("query follow up");
        console.log(this.state.courses);

        // console.log(this.state.courses[0].TO_ID);
                return(
            <React.Fragment>
                <div> test fragment</div>
            {this.state.courses.map((val,key) =>{
                
                return <div key={val.TO_ID}> {val.TO_TutorID}</div>;
                {console.log(val.TO_TutorID)}
            })}
        </React.Fragment>
        )

    }

      
    
      render() {
        return (
          <div>
            {/* <p>You clicked {this.state.count} times</p>
            <button onClick={() => this.setState({ count: this.state.count + 1 })}>
              Click me
            </button> */}
            {/* <p> next </p> */}
            <button onClick={this.getCourseDataImproved}>
              Click me
            </button>
            <div>
                {/* {this.getState.courses[0].TO_ID} */}
                                {/* {this.state.courses.map(course => <h2 key={course.data.TO_ID}>{course.data.TO_ID}</h2>)} */}
            </div>
          </div>
          
          
        );
      }
    }



function ShowTutorOnboardingData() {

    
 

    const [email, setEmail] = useState('');
    const [userList, setUserList] = useState([]);

    const getTutors = (id) => {
        Axios.get("http://localhost:3001/TutorOnboardQuery/ids"+id, {
            id:id,
        }).then((response)=>{
        // Axios.get("http://localhost:3001/TutorOnboardQuery?ids="+ id).then((response) => {
            // console.log(response);
            // setUserList(response.data);
        // })
        if (response.data.message){
            console.log("Failed"+response.data.message);        }
        else{
            console.log(response);
             setUserList(response.data);
        }
        // console.log(response);
         });
    };
    
    
    // useEffect(() => {
    //     // This triggers on load effect
    //     console.log("On Load CachedUser: "+cachedusers);
    //     {getTutors(cachedusers)}
    //   });
    

    return (
        
        <React.Fragment>
            <Welcome/>

            <div>
                ------------
                Welcome End
                ------------
            </div>
            {/* <Button variant="primary" type="submit" onClick={getTutors(cachedusers)}>
                    Show Users
                </Button>  */}

                
        <div>A Manager... {ReactSession.get("email")} </div>
                    <div className="employees">
                    


                <table>
                    <tbody>
                        <tr>
                            <th>
                                <div>1st</div>
                            </th>
                            <th>
                                <div>2nd</div>
                            </th>
                            <th>
                                <div>Tutor First</div>
                            </th>
                            <th>
                                <div>Tutor Last</div>
                            </th>
                            <th>
                                <div>Tutor Grade</div>
                            </th>                            
                            <th>
                                <div>Tutor Subject</div>
                            </th>                  
                            <th>
                                <div>Tutor Location</div>
                            </th>          
                            <th>
                                <div>Tutor Lang</div>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TO_AdminID}</div>;
                                })}
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TO_TutorID}</div>;
                                })}
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TutorFirstName}</div>;
                                })}
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TutorLastName}</div>;
                                })}
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TutorGradeLevel}</div>;
                                })}
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TutorSubjects}</div>;
                                })}
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TutorTimeZone}</div>;
                                })} 
                            </td>
                            <td>
                                {userList.map((val,key) =>{
                                    
                                    return <div key={val.TO_ID}> {val.TutorLanguage}</div>;
                                })}
                            </td>

                            {/* TutorID, TutorFirstName, TutorLastName, TutorSubjects, TutorGradeLevel, TutorTimeZone, TutorLanguage, TutorRegistrationDate */}
                        </tr>
                    </tbody>
                </table>
                {/* <div> Userlist Item: {useEffect} </div> */}
            </div>
        </React.Fragment>
    )
}

export default TutorOnboardingMain; 
