import React , { Component } from 'react'; 
import { useState } from "react";
import { ReactSession } from 'react-client-session';


import Axios from 'axios'

import './components/Signup.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

// import StudentSignUpB from "./StudentSignUpB";
import StudentSignUpAS4 from "./StudentSignUpAS4";

// import bcrypt from 'bcryptjs'

// import {
//     Navigate,Route,BrowserRouter,Routes, useNavigate
//   } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useSearchParams, withRouter,useNavigate, } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

ReactSession.setStoreType("localStorage");

function RedirectToNextPage(){
    <Navigate to="/StudentSignUpB.js" replace={true} />
    // const navigate = useNavigate();
    // navigate('/StudentSignUpB.js')
}

class StudentSignUpA extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            FirstName: "" ,
            LastName:"",
            PrimaryPhoneNumber:"",
            SecondaryPhoneNumber:"",
            labelMatch:"",
            textMatchColor:"#ced4da",
            textMatchColorFirstNameStudent:"#ced4da",
            textMatchColorLastNameStudent:"#ced4da",
            textMatchColorPP:"#ced4da",
            emailValid:"",
            tcChecked:"",
            ppChecked:"",
            redirectTime:"",
            Residency:"",
            FirstNameStudent: "" ,
            LastNameStudent: "" ,
            GradeLevel:0,
            GradeLevelDropdown:"0",
            StudentAge:0,
            SchoolCode:"",
            StudentLanguage:"",
            EnglishCheckbox:false,
            SpanishCheckbox:false,
            HindiCheckbox:false,
            FrenchCheckbox:false,
            OtherCheckbox:false,
            CurrentlySelectedCourse:"",
            CurrentlySelectedCourseID:0,
            CoursesListA:[{id:1, title:'Loading'}],
            // CoursesListA:[{id:1, title:'Ford'}, {id:2, title:'BMW'}, {id:3, title:'Audi'},{id:4, title:'Toyota'}],
            CoursesListB:[],
            redirectBack:"",
            AddButtonStatus:true,
            RemoveButtonStatus:true,
            boarderCssLanguage:"",
            textMatchColorLanguage:"",
        };
    };

    async componentDidMount() {
        // window.addEventListener('load', this.getCourseList);
        this.getCourseList();

        if(this.state.EnglishCheckbox==false && this.state.SpanishCheckbox==false  && this.state.HindiCheckbox==false && this.state.FrenchCheckbox==false && this.state.OtherCheckbox==false)
        {
            // console.log("no languages make it red")
            // this.changeTextColorRedLanguage();

            if(
                (ReactSession.get("EnglishCheckbox") == undefined || ReactSession.get("EnglishCheckbox") == null || ReactSession.get("EnglishCheckbox") =="" || ReactSession.get("EnglishCheckbox") ==" " )&&
                (ReactSession.get("SpanishCheckbox") == undefined || ReactSession.get("SpanishCheckbox") == null || ReactSession.get("SpanishCheckbox") =="" || ReactSession.get("SpanishCheckbox") ==" " )&&
                (ReactSession.get("HindiCheckbox") == undefined || ReactSession.get("HindiCheckbox") == null || ReactSession.get("HindiCheckbox") =="" || ReactSession.get("HindiCheckbox") ==" " )&&
                (ReactSession.get("FrenchCheckbox") == undefined || ReactSession.get("FrenchCheckbox") == null || ReactSession.get("FrenchCheckbox") =="" || ReactSession.get("FrenchCheckbox") ==" " )&&
                (ReactSession.get("OtherCheckbox") == undefined || ReactSession.get("OtherCheckbox") == null || ReactSession.get("OtherCheckbox") =="" || ReactSession.get("OtherCheckbox") ==" ")
            ){
                // console.log("student age check triggered - red");
                // this.changeTextColorRedLanguage();
            }
            else{
                // console.log("student age check triggered - green1");
                this.setState({ EnglishCheckbox: ReactSession.get("EnglishCheckbox") })
                this.setState({ SpanishCheckbox: ReactSession.get("SpanishCheckbox") })
                this.setState({ HindiCheckbox: ReactSession.get("HindiCheckbox") })
                this.setState({ FrenchCheckbox: ReactSession.get("FrenchCheckbox") })
                this.setState({ OtherCheckbox: ReactSession.get("OtherCheckbox") })

                var langarraytemp2 = ["English","Spanish","Hindi","French","Other"]
                var langarraytemp = [ReactSession.get("EnglishCheckbox"), ReactSession.get("SpanishCheckbox"), ReactSession.get("HindiCheckbox") , ReactSession.get("FrenchCheckbox"), ReactSession.get("OtherCheckbox")];

                const asyncLangFunc = async (passedValue, passedCheck) => {
                    //console.log("Async checkLanguageFunc Intiated with value:"+passedValue);
                        if(passedCheck === true){
                            //English is selected to be part of langauges
                            //If languages include selected language do nothing
                            if(this.state.StudentLanguage.includes(passedValue)){
            
                            }
                            //If languages doen't include selected language add selected language
                            else{
                                //If languages already have other languages add to the end with comma
                                if(this.state.StudentLanguage.length > 0){
                                var newStateTemp = this.state.StudentLanguage+","+passedValue ;
                                this.setState({ StudentLanguage: newStateTemp })
                                }
                                //If no other languages set as only language
                                else{
                                this.setState({ StudentLanguage: passedValue })
                                }
                            }
                            return("Passed");
            
                        }
                        else{
                            //
                            //console.log("resetting language state to nothing")
                            if(this.state.StudentLanguage.includes(","+passedValue)){
                                var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
                                this.setState({ StudentLanguage: middlemanStudentLang })
                            }
                            else{
                                var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
                                this.setState({ StudentLanguage: middlemanStudentLang })
                            }
                            return("Failed");
                        }
                    // }
                }
                

                const fetchData = async () => {
                    for (let i = 0; i < langarraytemp2.length; i++) {
                        // console.log("passing:"+ langarraytemp2[i] + " and " + langarraytemp[i])
                        if(langarraytemp[i] == true){
                            let channel = await asyncLangFunc(langarraytemp2[i],langarraytemp[i]);
                            // console.log("channel required: " + channel);
                        }
                    }
                }
                fetchData();
            }

        }

        if (this.state.FirstNameStudent == undefined || this.state.FirstNameStudent == null || this.state.FirstNameStudent == ""){
            if(ReactSession.get("FirstNameStudent") !== undefined && ReactSession.get("FirstNameStudent") !== null && ReactSession.get("FirstNameStudent") !==""){
                this.setState({ FirstNameStudent: ReactSession.get("FirstNameStudent") })
            }
        }
        if (this.state.LastNameStudent == undefined || this.state.LastNameStudent == null || this.state.LastNameStudent == ""){
            if(ReactSession.get("LastNameStudent") !== undefined && ReactSession.get("LastNameStudent") !== null && ReactSession.get("LastNameStudent") !==""){
                this.setState({ LastNameStudent: ReactSession.get("LastNameStudent") })
            }
        }
    }

    getCourseList = async () => {
        let res = await  Axios.get('http://localhost:3001/CoursesList') ;
        //console.log(res.data)
        this.setState({ CoursesListA: res.data });
    }
    // getCourseList () {
    //     let res = Axios.get('http://localhost:3001/CoursesList') ;
    //     console.log(res.data[0])
    //     // this.setState({ CoursesListA: res.data[0] });
    // }


    checkValidation=(event)=>{

        //console.log("CheckValidation Invoked");

        const confirmPasswordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        const passwordRegex = new RegExp("^("+this.state.confirmPassword+")$");
        //console.log('3rd regex '+ confirmPasswordRegex +' & 4th regex '+ passwordRegex) ;
        this.setState({ textMatchColor: "#ced4da" })

        // if(passwordRegex.match(confirmPasswordRegex)){
            if( this.state.password === this.state.confirmPassword){
            //console.log("Match");
            
            this.setState({ labelMatch: "true" })
            // this.setState({ textMatchColor: "black" })

        }
        else{
            //console.log("Nope");

            this.setState({ labelMatch: "false" })
            // this.setState({ textMatchColor: "black" })

        }

    };
    checkEmailValidation=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "#ced4da" })
        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
          }
          if (!isValidEmail(this.state.email)) {
            this.setState({ emailValid: "false" })
          } else {
            this.setState({ emailValid: "true" })
          }

          console.log("CheckEmailValidation Invoked "+this.state.emailValid);

    }

    checkFirstName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "#ced4da" })

    }
    checkLastName=(event)=>{
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        this.setState({ textMatchColorEmail: "#ced4da" })
    }

    checkLanguage(languageCheck){
        // console.log("CheckEmailValidation Invoked "+this.state.emailValid);
        // this.setState({ textMatchColorEmail: "black" })
        //console.log("Langauge Check is"+languageCheck)
    }

    // backButtonClickCheck=(event)=>{

    // }

    getListOfCoursesA=(event)=>{
        //const cars = ['Ford', 'BMW', 'Audi']; 
        const cars = this.state.CoursesListA;
        return cars
    }

    getListOfCoursesB=(event)=>{
        //const cars = ['Ford', 'BMW', 'Audi']; 
        const cars = this.state.CoursesListB;
        return cars
    }

    checkLanguageFunc(passedValue, passedCheck){

        //console.log("checkLanguageFunc Intiated with value:"+passedValue);
        //console.log("EnglishCheckbox State: " + this.state.EnglishCheckbox)
        //console.log("SpanishCheckbox State: " + this.state.SpanishCheckbox)
        //console.log("HindiCheckbox State: " + this.state.HindiCheckbox)

        // if(passedValue === "English"){
            if(passedCheck === true){
                //English is selected to be part of langauges
                //If languages include selected language do nothing
                if(this.state.StudentLanguage.includes(passedValue)){

                }
                //If languages doen't include selected language add selected language
                else{
                    //If languages already have other languages add to the end with comma
                    if(this.state.StudentLanguage.length > 0){
                    var newStateTemp = this.state.StudentLanguage+","+passedValue ;
                    this.setState({ StudentLanguage: newStateTemp })
                    }
                    //If no other languages set as only language
                    else{
                    this.setState({ StudentLanguage: passedValue })
                    }
                }

            }
            else{
                //
                //console.log("resetting language state to nothing")
                if(this.state.StudentLanguage.includes(","+passedValue)){
                    var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
                    this.setState({ StudentLanguage: middlemanStudentLang })
                }
                else{
                    var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
                    this.setState({ StudentLanguage: middlemanStudentLang })
                }
                
            }
        // }
    }

    addButtonClickCheck=(event)=>{
  
        //Log the currently selected item for addition
        //console.log("Add button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse);    

        // {this.state.CoursesListA.map(item => {
        //     return console.log("Add button clicked. New CouresListA: " + item.title);
        //   }) } 
          
        let isKeyPresent = this.state.CoursesListA.some(el => {
            // console.log(el.title);
            if(el.title.includes(this.state.CurrentlySelectedCourse))
              return true;
          })

        //console.log("Add button clicked. Is item in CouresListA?"+isKeyPresent);    

        if(isKeyPresent){
            //Can add

            //Filter out the currently Selected item and put it in new array
            const newList = this.state.CoursesListA.filter((item) => item.title !== this.state.CurrentlySelectedCourse);
            //Console Log new filter list
            const newList2 = newList.sort((a, b) => a.id > b.id ? 1 : -1)
            // const newList2 = newList.sort((a, b) => a.id > b.id )

            // {newList2.map(item => {
            //     return console.log("Add button clicked. Filtered Sorted list is: " + item.title);
            // }) } 
            //Update CourseListA
            this.setState({CoursesListA:newList2})
            //Console log course list A
            // {this.state.CoursesListA.map(item => {
            //     return console.log("Add button clicked. New CouresListA: " + item.title);
            // }) } 

            //Update Course List B
            var newArray = this.state.CoursesListB.slice();    
            newArray.push({id: this.state.CurrentlySelectedCourseID, title: this.state.CurrentlySelectedCourse});   
            // var newArray2 = newArray.sort((a, b) => a.id > b.id ? 1 : -1)
            // {newArray2.map(item => {
            //     return console.log("Add button clicked. Sorted NewArray2 " + item.title);
            // }) }  
            this.setState({CoursesListB:newArray})
            //Console Log Course List B
            // {this.state.CoursesListB.map(item => {
            //     return console.log("Add button clicked. New CouresListB: " + item.title);
            // }) }  
        }
        else{
            //Cannot Add
        }


        


    }
    removeButtonClickCheck=(event)=>{
  
        //Log the currently selected item for addition
        //console.log("Add button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse+" with id "+ this.state.CurrentlySelectedCourseID);    

        let isKeyPresent = this.state.CoursesListB.some(el => {
            // console.log(el.title);
            if(el.title.includes(this.state.CurrentlySelectedCourse))
              return true;
          })

        //console.log("Remove button clicked. Is item in CouresListB?"+isKeyPresent);    

        if(isKeyPresent){
            //Can add

            //Filter out the currently Selected item and put it in new array
            const newList = this.state.CoursesListB.filter((item) => item.title !== this.state.CurrentlySelectedCourse);
            //Console Log new filter list
            // {newList.map(item => {
            //     return console.log("Remove button clicked. Filtered list is: " + item.title);
            // }) } 

            //Update CourseListB
            this.setState({CoursesListB:newList})
            //Console log course list B
            // {this.state.CoursesListB.map(item => {
            //     return console.log("Remove button clicked. New CouresListB: " + item.title);
            // }) } 

            //Update Course List B
            var newArray = this.state.CoursesListA.slice();    
            newArray.push({id: this.state.CurrentlySelectedCourseID, title: this.state.CurrentlySelectedCourse});   
            var newArray2 = newArray.sort((a, b) => a.id > b.id ? 1 : -1)
            // var newArray2 = newArray.sort((a, b) => a.id - b.id )

            // {newArray2.map(item => {
            //     return console.log("Remove button clicked. Sorted NewArray2 " + item.title + " id " + item.id);
            // }) }  
            this.setState({CoursesListA:newArray2})
            //Console Log Course List B
            // {this.state.CoursesListA.map(item => {
            //     return console.log("Remove button clicked. New CouresListA: " + item.title);
            // }) }  
        }
        else{
            //Cannot Remove
        }


        


    }

    upArrowButtonClickCheck=(event)=>{
        const IndexOfSelectedObject = this.state.CoursesListB.findIndex(obj => obj.title === this.state.CurrentlySelectedCourse);

        //Log the currently selected item for addition
        //console.log("Up button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse+" has index "+IndexOfSelectedObject);   
        
        const arr = this.state.CoursesListB;

        const fromIndex = IndexOfSelectedObject; // 👉️ 0
        var toIndex = 0;
        if (fromIndex > 0){
            toIndex = IndexOfSelectedObject - 1;
        }

        const element = arr.splice(fromIndex, 1)[0];
        //console.log(element); // ['css']

        arr.splice(toIndex, 0, element);

        //console.log(arr); // 👉️ ['js', 'ts', 'css']
        this.setState({CoursesListB:arr})

    }

    downArrowButtonClickCheck=(event)=>{
        const IndexOfSelectedObject = this.state.CoursesListB.findIndex(obj => obj.title === this.state.CurrentlySelectedCourse);

        //Log the currently selected item for addition
        //console.log("Down button clicked. Current item selected was:"+this.state.CurrentlySelectedCourse+" has index "+IndexOfSelectedObject);   
        
        const arr = this.state.CoursesListB;

        const fromIndex = IndexOfSelectedObject; // 👉️ 0
        var toIndex = 0;
        if (fromIndex < arr.length){
            toIndex = IndexOfSelectedObject + 1;
        }

        const element = arr.splice(fromIndex, 1)[0];
        //console.log(element); // ['css']

        arr.splice(toIndex, 0, element);

        //console.log(arr); // 👉️ ['js', 'ts', 'css']
        this.setState({CoursesListB:arr})

    }


    nextButtonClickCheck=(event)=>{

        //Passing Values
        //console.log("Passing Values:")
        //console.log("FirstNameStudent" + this.state.FirstNameStudent)
        //console.log("LastNameStudent" + this.state.LastNameStudent)
        //console.log("CoursesListB" + this.state.CoursesListB)


        //FirstName Check Color
        if (this.state.FirstNameStudent === undefined || this.state.FirstNameStudent === null || this.state.FirstNameStudent === ""){
            if(ReactSession.get("FirstNameStudent") === undefined || ReactSession.get("FirstNameStudent") === null || ReactSession.get("FirstNameStudent") ===""){
                this.changeTextColorRedFirstNameStudent();
            }
            else{
                this.setState({ FirstNameStudent: ReactSession.get("FirstNameStudent") })
                this.changeTextColorGreenFirstNameStudent();
            }
        }
        if (this.state.FirstNameStudent !== undefined && this.state.FirstNameStudent !== null && this.state.FirstNameStudent !== ""){
            if(ReactSession.get("FirstNameStudent") !== undefined || ReactSession.get("FirstNameStudent") !== null || ReactSession.get("FirstNameStudent") !==""){
                this.changeTextColorGreenFirstNameStudent();
            }
            else{
                this.setState({ FirstNameStudent: ReactSession.get("FirstNameStudent") })
                this.changeTextColorGreenFirstNameStudent();

            }
        }
        //LastName Check Color
        if (this.state.LastNameStudent === undefined || this.state.LastNameStudent === null || this.state.LastNameStudent === ""){
                if(ReactSession.get("LastNameStudent") === undefined || ReactSession.get("LastNameStudent") === null || ReactSession.get("LastNameStudent") ===""){
                    this.changeTextColorRedLastNameStudent();
                }
                else{
                    this.setState({ LastNameStudent: ReactSession.get("LastNameStudent") })
                    this.changeTextColorGreenLastNameStudent();
                }
        }
        
        if (this.state.LastNameStudent !== undefined && this.state.LastNameStudent !== null && this.state.LastNameStudent !== ""){
            if(ReactSession.get("LastNameStudent") !== undefined || ReactSession.get("LastNameStudent") !== null || ReactSession.get("LastNameStudent") !==""){
                this.changeTextColorGreenLastNameStudent();
            }
            else{
                this.setState({ LastNameStudent: ReactSession.get("LastNameStudent") })
                this.changeTextColorGreenLastNameStudent();

            }
        }

        //Student Age

        if (this.state.StudentAge == undefined || this.state.StudentAge == null || this.state.StudentAge == "" || this.state.StudentAge == " " || this.state.StudentAge == "0" || this.state.StudentAge == 0){
            // console.log("student age check triggered");
            if(ReactSession.get("StudentAge") == undefined || ReactSession.get("StudentAge") == null || ReactSession.get("StudentAge") =="" || ReactSession.get("StudentAge") ==" " || ReactSession.get("StudentAge") == "0" || ReactSession.get("StudentAge") == 0){
                // console.log("student age check triggered - red");
                this.changeTextColorRedAge();
            }
            else{
                // console.log("student age check triggered - green1");
                this.setState({ StudentAge: ReactSession.get("StudentAge") })
                this.changeTextColorGreenAge();
            }
        }
        
        if (this.state.StudentAge !== undefined && this.state.StudentAge !== null && this.state.StudentAge !== "" && this.state.StudentAge !== "0" && this.state.StudentAge !== 0){
            if(ReactSession.get("StudentAge") !== undefined || ReactSession.get("StudentAge") !== null || ReactSession.get("StudentAge") !==""){
                // console.log("student age check triggered - green2");
                this.changeTextColorGreenAge();
            }
            else{
                // console.log("student age check triggered - green3");
                this.setState({ StudentAge: ReactSession.get("StudentAge") })
                this.changeTextColorGreenAge();

            }
        }

        //Student Grade Level
        if (this.state.GradeLevelDropdown == undefined || this.state.GradeLevelDropdown == null || this.state.GradeLevelDropdown == "" || this.state.GradeLevelDropdown == " "){
            // console.log("student age check triggered");
            if(ReactSession.get("GradeLevelDropdown") == undefined || ReactSession.get("GradeLevelDropdown") == null || ReactSession.get("GradeLevelDropdown") =="" || ReactSession.get("GradeLevelDropdown") ==" "){
                // console.log("student age check triggered - red");
                this.changeTextColorRedGradeLevel();
            }
            else{
                // console.log("student age check triggered - green1");
                this.setState({ GradeLevelDropdown: ReactSession.get("GradeLevelDropdown") })
                this.changeTextColorGreenGradeLevel();
            }
        }
        
        if (this.state.GradeLevelDropdown !== undefined && this.state.GradeLevelDropdown !== null && this.state.GradeLevelDropdown !== "" ){
            if(ReactSession.get("GradeLevelDropdown") !== undefined || ReactSession.get("GradeLevelDropdown") !== null || ReactSession.get("GradeLevelDropdown") !==""){
                // console.log("student age check triggered - green2");
                this.changeTextColorGreenGradeLevel();
            }
            else{
                // console.log("student age check triggered - green3");
                this.setState({ GradeLevelDropdown: ReactSession.get("GradeLevelDropdown") })
                this.changeTextColorGreenGradeLevel();

            }
        }

        //Student Language
            //Check if Language length isn't 0 
        if(this.state.EnglishCheckbox==false && this.state.SpanishCheckbox==false  && this.state.HindiCheckbox==false && this.state.FrenchCheckbox==false && this.state.OtherCheckbox==false)
        {
            // console.log("no languages make it red")
            // this.changeTextColorRedLanguage();

            if(
                (ReactSession.get("EnglishCheckbox") == undefined || ReactSession.get("EnglishCheckbox") == null || ReactSession.get("EnglishCheckbox") =="" || ReactSession.get("EnglishCheckbox") ==" " )&&
                (ReactSession.get("SpanishCheckbox") == undefined || ReactSession.get("SpanishCheckbox") == null || ReactSession.get("SpanishCheckbox") =="" || ReactSession.get("SpanishCheckbox") ==" " )&&
                (ReactSession.get("HindiCheckbox") == undefined || ReactSession.get("HindiCheckbox") == null || ReactSession.get("HindiCheckbox") =="" || ReactSession.get("HindiCheckbox") ==" " )&&
                (ReactSession.get("FrenchCheckbox") == undefined || ReactSession.get("FrenchCheckbox") == null || ReactSession.get("FrenchCheckbox") =="" || ReactSession.get("FrenchCheckbox") ==" " )&&
                (ReactSession.get("OtherCheckbox") == undefined || ReactSession.get("OtherCheckbox") == null || ReactSession.get("OtherCheckbox") =="" || ReactSession.get("OtherCheckbox") ==" ")
            ){
                // console.log("student age check triggered - red");
                this.changeTextColorRedLanguage();
            }
            else{
                // console.log("student age check triggered - green1");
                this.setState({ EnglishCheckbox: ReactSession.get("EnglishCheckbox") })
                this.setState({ SpanishCheckbox: ReactSession.get("SpanishCheckbox") })
                this.setState({ HindiCheckbox: ReactSession.get("HindiCheckbox") })
                this.setState({ FrenchCheckbox: ReactSession.get("FrenchCheckbox") })
                this.setState({ OtherCheckbox: ReactSession.get("OtherCheckbox") })

                var langarraytemp2 = ["English","Spanish","Hindi","French","Other"]
                var langarraytemp = [ReactSession.get("EnglishCheckbox"), ReactSession.get("SpanishCheckbox"), ReactSession.get("HindiCheckbox") , ReactSession.get("FrenchCheckbox"), ReactSession.get("OtherCheckbox")];

                const asyncLangFunc = async (passedValue, passedCheck) => {
                    //console.log("Async checkLanguageFunc Intiated with value:"+passedValue);
                        if(passedCheck === true){
                            //English is selected to be part of langauges
                            //If languages include selected language do nothing
                            if(this.state.StudentLanguage.includes(passedValue)){
            
                            }
                            //If languages doen't include selected language add selected language
                            else{
                                //If languages already have other languages add to the end with comma
                                if(this.state.StudentLanguage.length > 0){
                                var newStateTemp = this.state.StudentLanguage+","+passedValue ;
                                this.setState({ StudentLanguage: newStateTemp })
                                }
                                //If no other languages set as only language
                                else{
                                this.setState({ StudentLanguage: passedValue })
                                }
                            }
                            return("Passed");
            
                        }
                        else{
                            //
                            //console.log("resetting language state to nothing")
                            if(this.state.StudentLanguage.includes(","+passedValue)){
                                var middlemanStudentLang = this.state.StudentLanguage.replace(','+passedValue, '')
                                this.setState({ StudentLanguage: middlemanStudentLang })
                            }
                            else{
                                var middlemanStudentLang = this.state.StudentLanguage.replace(passedValue, '')
                                this.setState({ StudentLanguage: middlemanStudentLang })
                            }
                            return("Failed");
                        }
                    // }
                }
                

                const fetchData = async () => {
                    for (let i = 0; i < langarraytemp2.length; i++) {
                        // console.log("passing:"+ langarraytemp2[i] + " and " + langarraytemp[i])
                        if(langarraytemp[i] == true){
                            let channel = await asyncLangFunc(langarraytemp2[i],langarraytemp[i]);
                            // console.log("channel required: " + channel);
                        }
                    }
                }
                fetchData();

                this.changeTextColorGreenLanguage();
            }

        }
        else{
            //console.log("no languages make it green")
            this.changeTextColorGreenLanguage();
        }

        //Student Selected Courses (B)
        if (this.state.CoursesListB.length < 1){
            //If no courses selected 
            //Make Red
            this.changeTextColorRedCourses();
        }
        else{
            //If at least 1 course selected then 
            //Make Green
            this.changeTextColorGreenCourses();

        }


        // //PPN Check Color
        // if (this.state.tcChecked === false){
        //     this.changeTextColorRedPPN();
        // }
        // if ( this.state.tcChecked === true){
        //     this.changeTextColorGreenPPN();    
        // }
        // //TR Check Color
        // if (this.state.ppChecked === "N/A"){
        //     this.changeTextColorRedTR();
        // }
        // if ( this.state.ppChecked !== "N/A"){
        //     this.changeTextColorGreenTR();    
        // }

        if( 
            (this.state.FirstNameStudent !== undefined && this.state.FirstNameStudent !== null && this.state.FirstNameStudent !== "")
            && 
            (this.state.LastNameStudent !== undefined && this.state.LastNameStudent !== null && this.state.LastNameStudent !== "")
            && 
            (this.state.GradeLevelDropdown !== undefined || this.state.GradeLevelDropdown !== null || this.state.GradeLevelDropdown !== " ")
            && 
            (this.state.StudentAge !== undefined || this.state.StudentAge !== null || this.state.StudentAge !== " ")
            && 
            (this.state.StudentLanguage !== undefined || this.state.StudentLanguage !== null || this.state.StudentLanguage !== " ")
            && 
            (this.state.CoursesListB.length > 0)
            ){
            //console.log("passed nextButtonCheck");

            const stringData = this.state.CoursesListB.reduce((result, item) => {
                return `${result}${item.title},`
              }, "")
            //   console.log("CourseArrayToString: "+stringData.slice(0, -1));
            const courseData2 = stringData.slice(0, -1); 

            this.setState({ email: ReactSession.get("email") })
            // // this.changeTextColorGreenEmail();    
            // // this.changeTextColorGreenPassword();    
            // // this.changeTextColorGreenTC();    
            // // this.changeTextColorGreenPP();    

            const getCourseDataImproved = async () => {
                    let res = await Axios.post("http://localhost:3001/createBcheck", {
                                email: ReactSession.get("email")
                            }).then(res => {
                                //console.log(res);
                                //console.log(res.data);

                                this.setState({ emailCheck: res.data }, () => {
                                    //console.log("Email Check: "+this.state.emailCheck);
                                    //this.displayQueryData1(); 
                
                                    if(this.state.emailCheck > 0){
                                        //console.log("updated needed")
                                        const updateUser = () => {
                                            //console.log("post attempted")
                                            //console.log("email"+ this.state.email)
                                            //console.log("FirstNameStudent"+ this.state.FirstNameStudent)
                                            //console.log("LastNameStudent"+ this.state.LastNameStudent)
                                            //console.log("StudentAge"+ this.state.StudentAge)
                                            //console.log(" GradeLevel"+ this.state.GradeLevelDropdown)
                                            //console.log("StudentLanguage"+ this.state.StudentLanguage)
                                            //console.log("CoursesListB"+ courseData2)
                                            Axios.post("http://localhost:3001/updateC", {
                                                email: this.state.email, 
                                                FirstNameStudent: this.state.FirstNameStudent,
                                                LastNameStudent: this.state.LastNameStudent,
                                                StudentAge: this.state.StudentAge,
                                                GradeLevel: this.state.GradeLevelDropdown,
                                                StudentLanguage: this.state.StudentLanguage,
                                                CoursesListB: courseData2,
                                            }).then(()=>{
                                                //console.log("success and redirect");
                                                // this.setState({ redirectTime: "true" }, function(){
                                                //     //console.log(this.state.redirectTime)
                                                // });
                                                const updateStep = () => {
                                                    Axios.post("http://localhost:3001/updateLoginStep", {
                                                        email: this.state.email, 
                                                        step:"3-4"
                                                    }).then(()=>{
                                                        //console.log("success and redirect");
                                                        this.setState({ redirectTime: "true" }, function(){
                                                            //console.log(this.state.redirectTime)
                                                        });
                                                    });
                                                };
                                                updateStep();
                                            });
                                        };
                                        updateUser();
                                    }
                                    else{
                                        //console.log("create needed but previous step needed")
                                        //Perform intended data parse here / redirect
                                        // const addUser = () => {
                                        //     Axios.post("http://localhost:3001/createB", {
                                        //         email: this.state.email, 
                                        //         Student: this.state.FirstNameStudent,
                                        //         LastNameStudent: this.state.LastNameStudent,
                                        //         territory: this.state.Residency,
                                        //         primaryphone: this.state.PrimaryPhoneNumber,
                                        //         StudentAge: this.state.StudentAge,
                                        //     }).then(()=>{
                                        //         console.log("success and redirect");
                                        //         this.setState({ redirectTime: "true" }, function(){
                                        //             console.log(this.state.redirectTime)});
                                        //     });
                                        // };
                                        // addUser();
                                        //End data parse here / redirect
                                    }
                                    //console.log("waited for data value")
                                  }); 
                              });
                    // console.log("res"+res)
                
                // console.log(this.stateArray)
            }
                
            
            getCourseDataImproved();

        }
    };

    backButtonClickCheck=(event)=>{
        //console.log("Go back");
        this.setState({ redirectBack: "true" }, function(){
            //console.log(this.state.redirectBack)
        });
    }

    changeTextColorRedFirstNameStudent=(event)=>{
        this.setState({ textMatchColorFirstNameStudent: "red" })
    }
    changeTextColorRedLastNameStudent=(event)=>{
        this.setState({ textMatchColorLastNameStudent: "red" })
    }
    changeTextColorRedGradeLevel=(event)=>{
        this.setState({ textMatchColorGradeLevel: "red" })
    }
    changeTextColorRedAge=(event)=>{
        this.setState({ textMatchColorAge: "red" })
    }
    changeTextColorRedLanguage=(event)=>{
        this.setState({ textMatchColorLanguage: "red" })
        this.setState({ boarderCssLanguage: "LanguageBorder" })

    }
    changeTextColorRedCourses=(event)=>{
        this.setState({ textMatchColorCourses: "red" })
    }
    changeTextColorRedTR=(event)=>{
        this.setState({ textMatchColorTR: "red" })
    }
    changeTextColorGreenFirstNameStudent=(event)=>{
        this.setState({ textMatchColorFirstNameStudent: "green" })
    }
    changeTextColorGreenLastNameStudent=(event)=>{
        this.setState({ textMatchColorLastNameStudent: "green" })
    }
    changeTextColorGreenGradeLevel=(event)=>{
        this.setState({ textMatchColorGradeLevel: "green" })
    }
    changeTextColorGreenAge=(event)=>{
        this.setState({ textMatchColorAge: "green" })
    }
    changeTextColorGreenLanguage=(event)=>{
        this.setState({ textMatchColorLanguage: "green" })
        this.setState({ boarderCssLanguage: "LanguageBorder" })
        
    }
    changeTextColorGreenCourses=(event)=>{
        this.setState({ textMatchColorCourses: "green" })
    }

    

    ChangeActiveSelectedColor(passedValue){
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            
            return "activelySelectedItem"
            
        }
        else{
            return "notActivelySelectedItem"
        }
    }
    
    ChangeSelectedColor(passedValue){
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            return "blue"
        }
        else{
            return "black"
        }
    }
    ChangeButtonsSelectedA(passedValue){
        //console.log("button selected triggered");
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            //console.log("button selected triggered now changign");
            this.setState({AddButtonStatus: "true"});
        }
    }

    ChangeButtonsSelectedB(passedValue){
        //console.log("button selected triggered");
        if(passedValue === this.state.CurrentlySelectedCourse)
        {
            //console.log("button selected triggered now changign");
            this.setState({AddButtonStatus: "false"});
        }
    }

    
    render(){
    
        if (this.state.redirectTime === "true") {}
        const redirectTimeVar = this.state.redirectTime;
        const redirectBackVar = this.state.redirectBack;

        return (
            
            
            
           

            
            <div className="centered">
                {/* {this.getCourseList()} */}
                {/* {console.log("Redirect Time is " + this.state.redirectTime)}
                {console.log("State email " + this.state.email)}
                {console.log("State FirstNameStudent " + this.state.FirstNameStudent)}
                {console.log("State LastNameStudent " + this.state.LastNameStudent)}
                {console.log("State GradeLevel " + this.state.GradeLevel)}
                {console.log("State GradeLevelDropdown " + this.state.GradeLevelDropdown)}
                {console.log("State StudentAge " + this.state.StudentAge)}
                {console.log("State StudentLanguage " + this.state.StudentLanguage)}
                
                {console.log("English Checkbox: "+this.state.EnglishCheckbox)}
                {console.log("Spanish Checkbox: "+this.state.SpanishCheckbox)}
                {console.log("Hindi Checkbox: "+this.state.HindiCheckbox)}
                {console.log("Other Checkbox: "+this.state.OtherCheckbox)}
                {this.state.CoursesListA.map(item => {
          return console.log("State CoursesListA " + item.title);
        }) }
                {console.log("CoursesListB length: "+this.state.CoursesListB.length)}
                {console.log("Add Button Status: "+this.state.AddButtonStatus)}
                {console.log("Remove Button Status: "+this.state.RemoveButtonStatus)}


                {console.log("Session email " + ReactSession.get("email"))}
                {console.log("Session FirstNameStudent " + ReactSession.get("FirstNameStudent"))}
                {console.log("Session LastNameStudent " + ReactSession.get("LastNameStudent"))}
                {console.log("Session GradeLevel " + ReactSession.get("GradeLevel"))}
                {console.log("Session StudentAge " + ReactSession.get("StudentAge"))}
                {console.log("Session StudentLanguage " + ReactSession.get("StudentLanguage"))}
                {console.log("Session English Checkbox: "+ReactSession.get("EnglishCheckbox"))}
                {console.log("Session Spanish Checkbox: "+ReactSession.get("SpanishCheckbox"))}
                {console.log("Session Hindi Checkbox: "+ReactSession.get("HindiCheckbox"))}
                {console.log("Session Other Checkbox: "+ReactSession.get("OtherCheckbox"))}


                {console.log("email " + ReactSession.get("email"))} */}
                {redirectTimeVar ? (
                    // console.log("Redirect Time is 1 " + this.state.redirectTime),
                    // <Router>
                    <Routes>
             
                        {/* <Route path="/" element={<StudentSignUpB />} /> */}
                        <Route path="/" element={<Navigate to="/StudentSignUpAS4" replace={true} />} />

                   
                    </Routes>
                    // console.log("Redirect Time is 1 " + this.state.redirectTime)

                ) : (
                    // console.log("Redirect Time is 2 " + this.state.redirectTime)
                    //console.log("")
                    <div>
                    </div>

                )}
                {redirectBackVar ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/StudentSignUpAS2" replace={true} />} />
                    </Routes>
                ) : (
                    // console.log("Redirect Time is 2 " + this.state.redirectTime)
                    // console.log("")
                    <div>
                    </div>
                )}

                {/* <span> test </span> */}
                <Card>

                    <Card.Body Style = {{width:800}}>
                    <h2>
                        Student Information       
                        </h2>
                        <br></br>
                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formFirstNameStudent">
                            <Form.Label  
                            >First Name</Form.Label>
                            <Form.Control Style={{ borderColor:this.state.textMatchColorFirstNameStudent}} type="text" placeholder="" 
                            value={ReactSession.get("FirstNameStudent")!== undefined ? (ReactSession.get("FirstNameStudent")):(this.state.value)}
                            onChange={(event) => this.setState({FirstNameStudent: event.target.value}
                                ,function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkFirstName();
                                ReactSession.set("FirstNameStudent", this.state.FirstNameStudent);
                                this.setState({textMatchColorFirstNameStudent:"#ced4da"})
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                            >Last Name</Form.Label>
                            <Form.Control Style={{ borderColor:this.state.textMatchColorLastNameStudent}} type="text" placeholder="" 
                                value={ReactSession.get("LastNameStudent")!== undefined ? (ReactSession.get("LastNameStudent")):(this.state.value)}
                             onChange={(event) => this.setState({LastNameStudent: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                this.checkLastName();
                                ReactSession.set("LastNameStudent", this.state.LastNameStudent);
                                this.setState({textMatchColorLastNameStudent:"#ced4da"})
                            }
                            )}/>
                        </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                        
                        <Form.Group>
                            <Form.Label 
                            >Grade Level</Form.Label>
                            <select className="form-select form-select-md" 
                            Style={{ borderColor:this.state.textMatchColorGradeLevel}}
                            defaultValue={ReactSession.get("GradeLevelDropdown")!== undefined ? (ReactSession.get("GradeLevelDropdown")):"0"}
                            value={this.state.value} onChange={(event) => this.setState({GradeLevelDropdown: event.target.value},function(){
                                ReactSession.set("GradeLevelDropdown", this.state.GradeLevelDropdown);})
                            }> 
                                <option value="0">Kindergarten</option>
                                <option value="1">1st Grade</option>
                                <option value="2">2nd Grade</option>
                                <option value="3">3rd Grade</option>
                                <option value="4">4th Grade</option>
                                <option value="5">5th Grade</option>
                                <option value="6">6th Grade</option>
                                <option value="7">7th Grade</option>
                                <option value="8">8th Grade</option>
                                <option value="9">Freshman (9th Grade)</option>
                                <option value="10">Sophomore (10th Grade)</option>
                                <option value="11">Junior (11th Grade)</option>
                                <option value="12">Senior (12th Grade)</option>
                            </select>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formStudentAge">
                            <Form.Label 
                            >Age</Form.Label>
                            <Form.Control Style={{ borderColor:this.state.textMatchColorAge}}
                            type="number" pattern="[0-9]*" 
                            defaultValue={ReactSession.get("StudentAge")!== undefined ? (ReactSession.get("StudentAge")):(this.state.value)}
                            // onKeyPress={(event => {
                            //     if(!/[0-9]/.test(event.key)){
                            //         event.preventDefault();
                            //     }
                            // })}
                            
                            onChange={(event) => this.setState({StudentAge: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                // this.checkSecondaryPhoneNumber();
                                ReactSession.set("StudentAge", this.state.StudentAge);
                            }
                            )}
                            />
                        </Form.Group>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formStudentAge">
                            <Form.Label 
                            >School Code
                            </Form.Label>
                            <Form.Label 
                            Style="font-style: italic; color:#4B5563"
                            > (Optional)</Form.Label>
                            <Form.Control type="text" 
                            //defaultValue={ReactSession.get("StudentAge")!== undefined ? (ReactSession.get("StudentAge")):(this.state.value)}
                            
                            onChange={(event) => this.setState({SchoolCode: event.target.value},function(){
                                // console.log(this.state.email + this.state.password);
                                // this.checkSecondaryPhoneNumber();
                                ReactSession.set("SchoolCode", this.state.SchoolCode);
                            }
                            )}
                            />
                        </Form.Group>
                        </Col>
                        </Row>
                        <div 
                        // className="LanguageBorder"
                        className={this.state.boarderCssLanguage}
                        style={{ borderColor:  this.state.textMatchColorLanguage}}
                        
                        >
                        <Row >
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Spoken Language(s)</Form.Label>
                            <br></br>
                            <input type="checkbox" value="English" name="Language" 
                            defaultChecked={ReactSession.get("EnglishCheckbox")!== undefined ? (ReactSession.get("EnglishCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({EnglishCheckbox: event.target.checked}, ReactSession.set("EnglishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            // onChange={(event) => this.setState({EnglishCheckbox: event.target.checked}),function(){ , this.checkLanguageFunc()} )}

                            onChange={(event) => this.setState({EnglishCheckbox: event.target.checked},function(){
                                ReactSession.set("EnglishCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            }
                            )}

                            /> English &nbsp;

                            <input type="checkbox" value="Spanish" name="Language" 
                            defaultChecked={ReactSession.get("SpanishCheckbox")!== undefined ? (ReactSession.get("SpanishCheckbox")):(this.state.value)}
                           // onChange={(event) => this.setState({SpanishCheckbox: event.target.checked}, ReactSession.set("SpanishCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({SpanishCheckbox: event.target.checked},function(){
                                ReactSession.set("SpanishCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Spanish &nbsp; 

                            <input type="checkbox" value="Hindi" name="Language" 
                            defaultChecked={ReactSession.get("HindiCheckbox")!== undefined ? (ReactSession.get("HindiCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({HindiCheckbox: event.target.checked}, ReactSession.set("HindiCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({HindiCheckbox: event.target.checked},function(){
                                ReactSession.set("HindiCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Hindi &nbsp; 

                            <input type="checkbox" value="French" name="Language" 
                            defaultChecked={ReactSession.get("FrenchCheckbox")!== undefined ? (ReactSession.get("FrenchCheckbox")):(this.state.value)}
                            // onChange={(event) => this.setState({HindiCheckbox: event.target.checked}, ReactSession.set("HindiCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({FrenchCheckbox: event.target.checked},function(){
                                ReactSession.set("FrenchCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> French &nbsp; 

                
                            <input type="checkbox" value="Other" name="Language" 
                            // onChange={(event) => this.setState({OtherCheckbox: event.target.checked}, ReactSession.set("OtherCheckbox", event.target.checked),function(){this.checkLanguageFunc()} )}
                            onChange={(event) => this.setState({OtherCheckbox: event.target.checked},function(){
                                ReactSession.set("OtherCheckbox", event.target.checked);
                                this.checkLanguageFunc(event.target.value,event.target.checked);
                            })}
                            /> Other: &nbsp; 
                            <input type="text" defaultValue="Other" name="Language" disabled={!this.state.OtherCheckbox} className="formControlCustom"
                            onChange={(event) => this.setState({OtherTextEntry: event.target.value})}
     
                            />


                        </Form.Group>
                        
                        </Col>
                        </Row>
                        </div>
                         <hr>
                        </hr>
                        <span>Select and sort courses the student needs tutoring in from most required to least:</span>
                        
                        <Row>
                            <Col>
                            <span>Availble Courses: </span><br></br> 
                            <div className='courseBoxList' >
                                { this.getListOfCoursesA().map((car=> <button 
                                // className= {"activelySelectedItem"}
                                id={car.title}
                                key={car.title}
                                className={this.ChangeActiveSelectedColor(car.title)}
                                Style={{display:"block" }}
                                onLoad={() => this.getCourseList()}
                                onClick={(event) => this.setState({CurrentlySelectedCourse: car.title}, 
                                    this.setState({CurrentlySelectedCourseID: car.id}),
                                    this.setState({AddButtonStatus: false}),
                                    this.setState({RemoveButtonStatus: true}),
                                    this.ChangeSelectedColor(car.title),
 
                                )}> {car.title} </button>))} 
                            </div>
                                <br></br>
                                <Button variant="primary" 
                                id="addbutton"
                                value="Aff" 
                                // disabled={this.state.AddButtonStatus ? true : false}
                                disabled={this.state.AddButtonStatus}
                                // onClick={(event) => this.addButtonClickCheck() } 
                                onClick={(event) => this.setState({AddButtonStatus: true},
                                this.setState({RemoveButtonStatus: false}),
                                    this.addButtonClickCheck()
                                )} 
                                > 
                                Add → 
                                {/* {this.state.AddButtonStatus ? "true" : "false"} */}
                                </Button>
                            </Col>
                            <Col>
                            <span>Selected Courses: </span><br></br>
                            <div className='courseBoxList'
                                Style={{borderColor:this.state.textMatchColorCourses}}
                            >

                                { this.getListOfCoursesB().map((car=> (<button 
                                className={this.ChangeActiveSelectedColor(car.title)}
                                Style={{display:"block" }}
                                id={car.title}
                                key={car.title}
                                onClick={(event) => this.setState({CurrentlySelectedCourse: car.title},
                                this.setState({CurrentlySelectedCourseID: car.id}),
                                this.setState({AddButtonStatus: true}),
                                this.setState({RemoveButtonStatus: false}),
                                this.ChangeSelectedColor(car.title),
                                
                                )} > {car.title} </button> )))} 
                            </div>

                                <br></br>
                                

                                <Button variant="primary" value="Aff" 
                                // disabled={!this.state.RemoveButtonStatus ? true : false}
                                // disabled={this.state.RemoveButtonStatus ? true : false}
                                disabled={this.state.RemoveButtonStatus}

                                // onClick={(event) => this.removeButtonClickCheck()} 
                                onClick={(event) => this.setState({AddButtonStatus: false},
                                this.setState({RemoveButtonStatus: true}),
                                    this.removeButtonClickCheck()
                                )} 

                                > 
                                ← Remove 
                                {/* {this.state.RemoveButtonStatus ? "true" : "false"} */}
                                </Button>
                                &nbsp;
                                <Button variant="primary" value="Aff"                                
                                disabled={this.state.RemoveButtonStatus}
                                onClick={(event) => this.upArrowButtonClickCheck()
                                } > 
                                ↑
                                </Button>
                                &nbsp;
                                <Button variant="primary" value="Aff" disabled={this.state.RemoveButtonStatus} onClick={(event) => this.downArrowButtonClickCheck()
                                } > 
                                ↓
                                </Button>
                            </Col>
                        </Row>

                        
                        
                       

                        {/* <hr></hr> */}
                        {/* {this.state.CurrentlySelectedCourse}
                        <hr></hr> */}
                        {/* {this.getCourseDataImproved()} */}
                        <br></br>
                        <br></br>
                        
                        <Row>
                            <Col>

                            <Button variant="primary" value="Next" onClick={(event) => this.backButtonClickCheck()
                            } > 
                            Back
                            </Button>
                            
                            </Col>
          
                          

                            <Col Style={{ textAlign: 'center'}}>
                            3/4
                            </Col>
                            <Col Style={{ textAlign: 'right'}}>
                            <Button variant="primary" value="Next" onClick={(event) => this.nextButtonClickCheck()
                            } > 
                            Next
                            </Button>
                            {/* <Button variant="primary" onClick={addUser}>Next</Button> */}
    
                            </Col>
                        </Row>
    
                    </Card.Body>
                </Card>
                
            </div>
            
    
        );
    } 
        
};


export default StudentSignUpA; 
