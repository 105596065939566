import React from "react";
import { useState } from "react";

import Axios from 'axios'
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");
// console.log(ReactSession.get("roles"));
var userRolesForPageItems = '';
if(ReactSession.get("roles") != null ){
    userRolesForPageItems = ReactSession.get("roles").split(',');

}
else{
    userRolesForPageItems = '';
}
// console.log(userRolesForPageItems); 

export const sessionTest = () => {
    console.log(ReactSession.get("username"));
    console.log(userRolesForPageItems);

}

function SessionComponent() {

    ReactSession.setStoreType("localStorage");
    // console.log(ReactSession.get("roles"));
    var userRolesForPageItems = '';
    if(ReactSession.get("roles") != null ){
        userRolesForPageItems = ReactSession.get("roles").split(',');
    }
    else{
        userRolesForPageItems = '';
    }

    return (
        userRolesForPageItems
    )

}

export default SessionComponent; 
