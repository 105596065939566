import React from "react";
import { useState } from "react";

import Axios from 'axios'

import './components/About.css';
import Footer from './components/Footer.jsx';
import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

function About() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const displayInfo = () => {
        console.log(email + password);
    }

    const [userList, setUserList] = useState([]);

    const addUser = () => {
        Axios.post("http://localhost:3001/create", {
            email: email, 
            password: password,
        }).then(()=>{
            console.log("success");
        });
    };

    const getUsers = () => {
        Axios.get("http://localhost:3001/users").then((response) => {
            // console.log(response);
            setUserList(response.data);
        })
    };

    return (
        
        <div>
            <div>
                <PublicNavBar/>  
                </div>
            <div>
                <h1> Welcome to the about page </h1>
                <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    {/* <Form.Control type="email" placeholder="Enter email" onChange={this.handleChange.bind(this)}/> */}
                    <Form.Control type="email" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)}/>
                    <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={addUser}>
                    Submit
                </Button>
                </Form>
            </div>
            <br></br><br></br>
            <div className="employees">
                <Button variant="primary" type="submit" onClick={getUsers}>
                    Show Users
                </Button>
                {userList.map((val,key) =>{
                    return <div> {val.email} - {val.password} </div>
                })}
            </div>
            <div>
                
            </div>

            <div className="position-absolute top-100 start-50 translate-middle w-100">
            {/* <div className="">  */}

                <Footer/>
            </div>
        </div>

    );
}

export default About; 


// import {Carousel,SimpleSlider} from "./components/Carousel";
// // import SimpleSlider from "./components/Carousel";



// // import React, { useState } from 'react';
// // import Toast from 'react-bootstrap/Toast';
// import Container from 'react-bootstrap/Container';
// import Button from 'react-bootstrap/Button';


// const elementPointerHand = <FontAwesomeIcon icon={faHandPointer} />

