import React from "react";
import { useState } from "react";

import Axios from 'axios'
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import './components/Login.css';
// import Footer from './components/Footer.jsx';
// import PublicNavBar from './components/PublicNavBar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import loginGraphic from './MediaAssets/loginGraphic.png'


import 'bootstrap/dist/css/bootstrap.min.css';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { NavLink } from "react-router-dom";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>

function Login() {

    ReactSession.setStoreType("localStorage");


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loginStatus, setLoginStatus] = useState('');
    const [loginVerification, setloginVerification] = useState('');
    const [loginUUID, setLoginUUID] = useState('');

    const navigate = useNavigate();

    // ReactSession.set("username", "Bob");

    const sessionTest = () => {
        console.log(ReactSession.get("username"));
    }

    const loginFunc = () => {
        Axios.post("http://localhost:3001/loginA", {
            email: email, 
            password: password,
        }).then((response)=>{
            console.log("Login A response: ")
            console.log(response.data)

            if (response.data.message){
                setLoginStatus(response.data.message)
            }
            else{
                setLoginStatus(response.data[0].email);
                setLoginUUID(response.data[0].UUID);
                ReactSession.set("username", response.data[0].email);
                ReactSession.set("UUID", response.data[0].UUID);

                // navigate("/LandingPage");
                // console.log(response);
                console.log("LoginFunc2 Start");
                loginFunc2();
            }

        });
        // --------
        
        
    };

    const loginFunc2 = () => {
        console.log("loginfunc2 triggered");
        console.log("Sending email "+email);
        Axios.post("http://localhost:3001/loginVKey", {
            email: email, 
        }).then((response)=>{
            console.log("VKey Login Triggered")
            if (response.data[0]){
                // setLoginStatus(response.data.message)
                console.log("VKey Response")
                console.log(response.data[0].Student_VFormData_VStat)
                if (response.data[0].Student_VFormData_VStat == 0 ){
                    console.log("Account exists but not verified");
                    setloginVerification("Account exists but not verified");
    
                }
                else if(response.data[0].Student_VFormData_VStat == 1 || response.data[0].Student_VFormData_VStat == 2){
                    console.log("Account exists and is verified");
                    setloginVerification("Account exists and is verified");
                    loginFunc3();
    
                }
                else {
                    console.log("A problem was encountered...");
                    setloginVerification("A problem was encountered...");
    
                }
            }
            else{

                

                const loginFunc4 = () => {
                    console.log("loginfunc4 triggered");
                    console.log("Sending email "+email);
                    console.log("passing uuid:"+loginUUID+ " OR: "+ ReactSession.get("UUID") )
                    Axios.post("http://localhost:3001/loginVKeyTutor", {
                        loginUUID: ReactSession.get("UUID"), 
                    }).then((response)=>{
                        console.log("VKey Tutor Login Triggered")
                        console.log(response.data[0])
                        if (response.data.message){
                            // console.log("Invalid Vkey:" + response.data.message)
                            setloginVerification("VKey No Key At all");
                        }
                        else if (response.data[0].TutorVerificationStatus == 0 ){
                            console.log("Account exists but not verified");
                            setloginVerification("Account exists but not verified");
            
                        }
                        else if(response.data[0].TutorVerificationStatus == 1 || response.data[0].TutorVerificationStatus == 2){
                            console.log("Account exists and is verified");
                            setloginVerification("Account exists and is verified");
                            loginFunc3();
            
                        }
                        else{
                            // console.log("No Data Here")
                            // console.log("A problem was encountered...");
                            setloginVerification("A problem was encountered...");
                        }

                    });
                }
                loginFunc4();
                // setLoginStatus(response.data[0].email);
                // ReactSession.set("username", response.data[0].email);
                // navigate("/LandingPage");
                // console.log("VKey No Key At all");
                // setloginVerification("VKey No Key At all");

            }
            

            console.log(response);
        });
        // --------
    }

    const loginFunc3 = () => {
        Axios.get("http://localhost:3001/userRoles/emails"+email, {
            email:email,
        }).then((response)=>{
            console.log("userRoleResponseEmail"+email)
            if (response.data.message){
                setLoginStatus(response.data.message + "... Could not login...")
            }
            else{
                setLoginStatus(response.data[0].Roles);
                const stringData = response.data.reduce((result, item) => {
                    return `${result}${item.Roles},`
                    }, "")
                ReactSession.set("roles",stringData);
                console.log("Session Rolls Set: "+ReactSession.get("roles"));
                navigate("/LandingPage");
            }
            console.log(response);
        });
    }

    var banneralert = false;
    var banneralertmessage ="";
    if((loginVerification == "" || loginVerification == null || loginVerification == undefined) && (loginStatus == "" || loginStatus == null || loginStatus == undefined)){
        banneralert = false;
    }
    else{
        banneralert = true;
        if(loginStatus == "User doesn't exist"){
            banneralertmessage = "No account exists for this email. Please create a new account."
        }
        else if(loginStatus == "Wrong username/password combination!"){
            banneralertmessage = "Invalid username/password combination!"
        }
        else if(loginVerification == "Account exists but not verified"){
            banneralertmessage = "Account exists but not verified. Please verify your account via email link before proceeding."
        }
        else if(loginVerification == "VKey No Key At all"){
            banneralertmessage = "Account exists but is not complete. Please continue creating your account via our Registration."
        }
        else if(loginVerification == "A problem was encountered..."){
            banneralertmessage = "A problem was encountered... Please reach out to help@theeduproject.net for assistance logging in."
        }
        else if(loginVerification == "Wrong username/password combination!"){
            banneralertmessage = "Wrong username/password combination!"
        }
        
    }

    

    return (
        
        
        <div className="centered signupBackground">
            {banneralert ? (
                    <div Style="position:absolute;text-align: center;justify-content: left;align-items: normal;/* position: static; */top: 0px;/* left: 20px; */background: lightcoral;width: 100%;"> {banneralertmessage} </div>
                ) : (
                    console.log("")
                )}
            
                <div className="tw-bg-white">
                    <Row >
                    <Col>
                <div className="padding48vert tw-px-12 borderradius4">
                    <h2>Login</h2>
                    {/* {loginStatus}                 */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={(e)=>{setEmail(e.target.value)}}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="" onChange={(e)=>{setPassword(e.target.value)}}/>
                    </Form.Group>
                    Remember Me 
                    <br></br>
                    <br></br>

                    <div className="center">
                    <Button variant="primary" onClick={loginFunc}>Login</Button>
                    </div>                    
                    <div className="center">
                        <span>
                            <a href="https://www.google.com"> Forgot Password</a>
                        </span>
                    </div>
                    <div className="center">
                        <br></br>
                        <br></br>
                        <span>
                            Don't Have an Account? <a href="RegisterWhoAreYou">Click Here</a>
                        </span>
                        <br></br>
                        <br></br>
                    </div>

                    <div className="center">
                        <br></br>
                        <br></br>
                        <span>
                            <a href="/">Home</a>
                        </span>
                        <br></br>
                        <br></br>
                    </div>

                    {/* <Button variant="primary" onClick={sessionTest}>SessionTest</Button> */}

                    {/* <div>
                        <span>
                            {loginVerification}
                        </span>
                    </div> */}
                </div>
                </Col>
                <Col>
                <span className="">
                    <img class="rightradius4" src={loginGraphic} alt="SVG as an image"></img>
                        {/* <img variant="top" src="https://img.freepik.com/free-photo/asian-woman-hand-writing-pencil-notebook-coffee-shop-with-vintage-toned_1627-1653.jpg" /> */}
                </span>
                </Col>
                    </Row>
                </div>
               
                
                
                
            {/* </Card> */}
        </div>
        // </div>

    );
}

export default Login; 
