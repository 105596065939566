import React from "react";
import './Footer.css'
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF,faInstagram,faTwitter,faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const elementFacebook = <FontAwesomeIcon icon={faFacebookF} />
const elementInstagram = <FontAwesomeIcon icon={faInstagram} />
const elementTwitter = <FontAwesomeIcon icon={faTwitter} />
const elementLinkedIn = <FontAwesomeIcon icon={faLinkedinIn} />


// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


const Footer = () => {
    return (
        <div className="customFooter whiteText"> 
            <Container>
                <br></br>
            <span>
                Copyright © 2022 The Education Project 
            </span>
            <br></br>
            <span>
                <a href="https://www.facebook.com/EduProjectHF/" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebookF} className="iconPadding whiteText" />
                </a>
                <a href="https://twitter.com/EduProjectHF" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className="iconPadding whiteText"/>
                </a>
                <a href="https://www.instagram.com/EduProjectHF/" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="iconPadding whiteText"/>
                </a>
                <a href="https://www.linkedin.com/company/the-education-project1/" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="iconPadding whiteText"/>
                </a>
            </span>
            <br></br>
            <br></br>
	
            </Container>
        </div>
    )
}

export default Footer;
